import React, { useEffect } from "react";
import { Table, Layout, Empty, Card } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {getSelfDataAction} from '../../../redux/userDuck'
import moment from 'moment'
import styles from "./Summary.module.css";

import summaryImg from "../../../assets/summary.svg";
import RedeemRewardsTable from "../MyProfile/RedeemRewardsTable";
const { Content } = Layout;
const results = {
  data: [
  ],
  columns: [
    {
      title: "Mes",
      dataIndex: "month",
      key: "month",
      className: "table__light-data",
    },
    {
      title: "Encuestas completadas",
      dataIndex: "polls",
      key: "polls",
      align: "center",
      className: "table__light-data",
    }
  ],
};
const products = {
  data: [

  ],
};
const Summary = () => {
  const { user, fetching } = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const surveys = useSelector(state=>{
    if(state.user.user.answers && state.user.user.answers.length) {
      const months = {}
      state.user.user.answers.filter(ans=>ans.completed !== "N").map(answer=>{
        months[moment(answer.completed).format("MMM")] = months[moment(answer.completed).format("MMM")] ? months[moment(answer.completed).format("MMM")] + 1 : 1
      })
      return Object.keys(months).map(month=>({month, polls:months[month]})) // change this when 6 months passed
    }else{
      return []
    }
  })
  const {exchanges} = user

  useEffect(()=>{
    dispatch(getSelfDataAction())
  }, [dispatch])
  return (
    <Content
      style={{
        background: "#f9f9f9",
        padding: "0 3%",
      }}
    >
      <div className={styles.summary__title}>
        ¡Hola{" "}
        <strong>
          {user.displayName ? user.displayName.split(" ")[0] : "Panelista"}
        </strong>
        !
      </div>
      <div className={styles.summary}>
        <div className={styles["summary--left"]}>
          <div className={styles["summary--left__title"]}>
            Bienvenido a tu perfil
          </div>
          <div className={styles["summary--left__content"]}>
            ¿Aún te preguntas como ganar con Panel?
          </div>
          <div className={styles["summary--left__content"]}>
            Contesta las encuestas para obtener puntos y canjear los premios que
            más te gusten. Podrás acceder a las encuestas desde tu cuenta y
            también te recordaremos vía mail cuando exista otra encuesta
            disponible.
          </div>
          <div className={styles["summary--left__content"]}>¡Y recuerda!</div>
          <div className={styles["summary--left__content"]}>
            <div className={styles["left__content--list"]}>
              <span className={styles["list--bullet"]}>&bull;</span> Los puntos
              ganados pueden variar por encuesta
            </div>
            <div className={styles["left__content--list"]}>
              <span className={styles["list--bullet"]}>&bull;</span> Puedes
              canjear tus premios cuando quieras
            </div>
            <div className={styles["left__content--list"]}>
              <span className={styles["list--bullet"]}>&bull;</span> Comparte
              con tus amigos, para que participen también
            </div>
          </div>
          <img
            src={summaryImg}
            alt="summaryImg"
            className={styles["summary--left__image"]}
          />
        </div>
        <div className={styles["summary--right"]}>
          <div className={styles["summary--right__content"]}>
            Tus resultados de los últimos meses:
          </div>
          <Table
            dataSource={surveys}
            columns={results.columns}
            pagination={false}
            className={styles.table}
            loading={fetching}
          />
          <br/>
          <div className={styles["summary--right__content"]}>
            Mira los últimos productos que has canjeado:
          </div>
         <RedeemRewardsTable nocode />
        </div>
      </div>
    </Content>
  );
};

export default Summary;
