import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import user, { restoreSessionAction } from "./userDuck";
import surveys, { restoreSurveysAction } from "./surveysDuck";
import rewards, { getRewards } from "./rewardsDuck";
import admin from './adminDuck'

let rootReducer = combineReducers({
  user,
  users:user,
  rewards,
  surveys,
  admin
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default function generateStore() {
  let store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
  restoreSessionAction()(store.dispatch);
  restoreSurveysAction()(store.dispatch);
  getRewards()(store.dispatch);
  return store;
}
