import React from "react";
import styles from "../Sign/Sign.module.css";
import { Form, Input, Button, Checkbox, Spin, notification } from "antd";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google'
import { signUpUserAction } from "../../redux/userDuck";
import { Select } from 'antd';
import moment from 'moment'
import estados from '../../helpers/estados'


const { Option } = Select;

const HomeContainer = () => {
  const { fetching, error } = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const captchaDemo = React.useRef()
  const [form] = Form.useForm();
  React.useEffect(() => {
    console.log("recaptcha loading...")
    loadReCaptcha()
  }, [])

  const onGenderChange = (gender) => {
    form.setFieldsValue({ gender });
  };

  const onStateChange = (state) => {
    // console.log("estad? ", state)
    form.setFieldsValue({ state });
  }

  const doSignup = async (form) => {
    // console.log(form)
    let signup = await dispatch(signUpUserAction(form));
    if (signup) {
      notification.success({
        message: '¡Cuenta creada!',
        description:
          'Tu cuenta se ha creado con éxito.',
      });
      history.push("/perfil/mi-perfil");
    }
  };

  const style = {
    button: {
      background: "#95BF48",
      color: "#FFFFFF",
      fontSize: "16px",
      borderRadius: "4px",
      border: "none",
      width: "85%",
      maxWidth: "280px",
      height: "40px",
      fontWeight: "800",
      lineHeight: "initial",
    },
  };
  const confirmPass = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value) return Promise.reject("Por favor confirma tu contraseña");
      if (getFieldValue("password") !== value)
        return Promise.reject("Las contraseñas no coinciden");
      return Promise.resolve();
    },
  });
  const checkConditions = {
    validator: (_, value) =>
      value
        ? Promise.resolve()
        : Promise.reject("Por favor acepte los términos y condiciones"),
  };

  const checkDate = {
    validator: (_, value) => {
      const years = moment(value).diff(moment(), 'years') * -1
      return years > 17 ? Promise.resolve() : Promise.reject("Debes ser mayor de edad")
    }
  }

  const checkPassword = () => ({
    validator(_, value) {
      if (!value) return Promise.reject("Por favor ingresa tu contraseña");
      if (value.length < 8) {
        return Promise.reject("Su contraseña debe tener al menos 8 caracteres");
      } else if (!/[A-Z]/.test(value))
        return Promise.reject(
          "La contraseña debe contener al menos una letra Mayúscula"
        );
      else if (!/[0-9]/.test(value))
        return Promise.reject("La contraseña debe contener al menos un numero");
      else if (!/[a-z]/.test(value))
        return Promise.reject(
          "La contraseña debe contener al menos una letra Minúscula"
        );
      return Promise.resolve();
    },
  });

  const onLoadRecaptcha = () => {
    if (captchaDemo.current) {
      captchaDemo.current.reset();
      captchaDemo.current.execute();
    }
  }
  const verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    // console.log(recaptchaToken, "<= your recaptcha token")
  }


  return (
    <section className="home-welcome">
      <div className="velo">
        <div className="home-welcome-text">
          <h2 className="home-container__text1">COMPARTE</h2>
          <h2 className="home-container__text2">
            Y <strong style={{ color: "#7ea13d" }}>GANA</strong>
          </h2>
          <p className="home-container__p">
            Panel es la comunidad en donde puedes ganar premios contestando
            encuestas dando tu opinión y compartiendo tus experiencias.
          </p>
          <p className="home-container_desktop__p">
            Collectum Datos Panel es la comunidad en donde puedes ganar premios compartiendo
            tu experiencia con productos y servicios a través de encuestas.

          </p>
        </div>
        <div className="home-welcome-form">
          <div className="home-welcome-card">
            <h2 id="title2">Regístrate</h2>
            <Form
              form={form}
              name="signup"
              onFinish={doSignup}
              className="welcome-form"
              hideRequiredMark
              colon={false}
              layout="vertical"
            >
              <Form.Item
                rules={[
                  { required: true, message: "Por favor ingresa tu nombre" },
                ]}

                name="displayName"
                label={<label >Nombre</label>}
                {...(error &&
                  error.includes("name") && {
                  help: error,
                  validateStatus: "error",
                })}
              >
                <Input placeholder="Ingresa tu nombre completo" />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Por favor ingresa tu correo" },
                  { type: "email", message: "Ingresa un correo válido" },
                ]}

                name="email"
                label={<label >Email</label>}
                {...(error &&
                  error.includes("email") && {
                  help: error,
                  validateStatus: "error",
                })}
              >
                <Input placeholder="Ingresa tu correo" />
              </Form.Item>
              <div className="input-mid">
                <Form.Item
                  // rules={[
                  //   { required: true, message: "Por favor ingresa tu fecha de nacimiento" },

                  // ]}
                  rules={[checkDate, { required: true, message: "Por favor ingresa tu fecha de nacimiento" }]}
                  name="birthDate"

                  label={<label >Fecha de nacimiento</label>}
                  {...(error &&
                    error.includes("birth") && {
                    help: error,
                    validateStatus: "error",
                  })}
                >
                  <Input style={{ height: "32px" }} type="date" placeholder="dd/mm/aaaa" />
                </Form.Item>
                <Form.Item name="gender" label="Género" rules={[{ required: true, message: "Por favor selecciona una opción" }]} >
                  {/* <p style={{ textAlign: "left", color: "#595959", fontSize: "16px", marginBottom: "8px" }}>Género</p> */}
                  <Select onChange={onGenderChange} placeholder="Selecciona">
                    <Option value="female">Mujer</Option>
                    <Option value="male">Hombre</Option>
                    <Option value="other">Otro</Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.Item
                rules={[
                  { required: true, message: "Por favor selecciona un estado" },
                ]}

                name="state"
                label={<label >Estado</label>}
              >
                <Select onChange={onStateChange} placeholder="Selecciona tu estado">
                  {estados.map((estado) => <Option key={estado.id} value={estado.name}>{estado.name}</Option>)}
                </Select>
              </Form.Item>
              <Form.Item
                rules={[checkPassword]}

                name="password"
                hasFeedback
                label={<label> Contraseña </label>}
              >
                <Input.Password placeholder="Con al menos 8 dígitos y una mayúscula" />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                dependencies={["password"]}
                hasFeedback
                rules={[confirmPass]}
                label={
                  <label >Confirmar contraseña</label>
                }
              >
                <Input.Password placeholder="Con al menos 8 dígitos y una mayúscula" />
              </Form.Item>

              <Form.Item
                valuePropName="checked"
                rules={[checkConditions]}
                className={styles["form__accept-terms"]}
                name="acceptTerms"
              >
                <Checkbox className="terms">
                  Acepto
                  <a href="/aviso-privacidad" className={styles["accept-terms--link"]}>
                    los términos y condiciones
                  </a>
                  de uso de Panel.
                </Checkbox>
              </Form.Item>
              <ReCaptcha
                ref={captchaDemo}
                size="invisible"
                data-theme="dark"
                render="explicit"
                sitekey="6Lfu1dsZAAAAANqpynlrDBdLubGSAYlbbWl76Zip"
                onloadCallback={onLoadRecaptcha}
                verifyCallback={verifyCallback}
              />
              <Form.Item >
                {fetching ? (
                  <Spin style={{ margin: "0 46%" }} />
                ) : (
                  <button
                    className="btn-sg"
                    htmlType="submit"

                  >
                    <p >REGISTRARME</p>
                  </button>
                )}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeContainer;
