import { Input } from "antd";
import React from "react";
import styles from "./Gifts.module.css";

const GiftCard = ({ onClick, items = [], brandName, imageUrls }) => {
  const [item] = items;
  const { rewardName, faceValue = 100 } = item
  const points = Math.floor(faceValue / 1.5) + " Puntos"

  return (
    <div onClick={onClick} className={styles.card} onClick={onClick}>
      <img src={imageUrls["300w-326ppi"]} alt="product" className={styles.card_image} />
      <div className={styles.card_line}></div>
      <h1 className={styles.card_product}>{brandName}</h1>
      <div className={styles.card_overview}>
        {/* <p className={styles.card_description}>{description.slice(0,35) + "..."}</p> */}
        <p className={styles.card_point}>{points}</p>
      </div>
    </div>
  );
};

export default GiftCard;
