import React from "react";
import { BellOutlined } from "@ant-design/icons";

import styles from "./Admin.module.css";
import userImage from "../../assets/userImage.png";

const HeaderAdmin = () => {
  return (
    <div className={styles.header}>
      <BellOutlined style={{ fontSize: "18px", marginRight: "10px" }} />
      <div
        className={styles.header__element}
        style={{ borderLeft: "1px solid #C4C4C4" }}
      >
        <div className={styles["header__element--name"]}>Administrador</div>
      </div>
      <img
        src={userImage}
        alt="userImage"
        className={`${styles.header__element} ${styles["element--img"]}`}
      />
    </div>
  );
};
export default HeaderAdmin;
