import React from 'react'
import HomeNavbar from '../Home/HomeNavbar'
import styles from './PrivacyPolicies.module.css'

const PrivacyPolicies = () => {
    window.scrollTo(0,0)
    return (
        <div>
            <HomeNavbar />
            <div className={styles.container}>
                <h1 className={styles.title} >AVISO DE PRIVACIDAD</h1>
                <div className={styles["container-text"]}>
                    <div className={styles["container--textPragraphs"]}>
                        <p style={{color:"#323232", fontWeight:"bolder"}}>  ¿Quién dará tratamiento a tus datos personales?</p>
                        <span>
                            Los datos personales proporcionados por usted serán utilizados por <span style={{color:"#323232", fontWeight:"bolder"}}>Collectum Datos Panel
                            (En adelante “Collectum”)  </span>  con domicilio para efectos del presente aviso de privacidad,
                            y todo lo relativo a la protección de datos personales en Blvd. Manuel Ávila Camacho 6ª,
                            El Parque, Naucalpan CP: 53398 Estado de México.
                        </span>
                    </div>
                    <div className={styles["container--textPragraphs"]}>
                        <p>
                            <span style={{color:"#323232", fontWeight:"bolder"}}>Collectum  </span>   tiene como finalidad
                            realizar estudios de mercado en sus diferentes metodologías. Collectum es responsable
                            del cuidado y resguardo de los datos personales y sensibles que usted ingrese y serán utilizados
                            únicamente con fines de análisis y conocimiento de las opiniones, sólo personal autorizado por
                            su labor tendrá acceso a ellos. Collectum no vende información personal a terceros.
                        </p>
                    </div>
                    <div className={styles["container--textPragraphs"]}>
                        <p style={{color:"#323232", fontWeight:"bolder"}}> ¿A qué terceros podemos transferir tu información?
                        </p>
                        <p>
                            <span style={{color:"#323232", fontWeight:"bolder"}}>Collectum
                         </span>  podrá transferir, sin requerir de su consentimiento, los datos personales necesarios
                            a autoridades competentes en los casos legalmente previstos, así como a empresas subsidiarias
                            y filiales de Collectum, las cuales operan bajo políticas de privacidad que cumplen
                            con las disposiciones aplicables correspondientes.
                        </p>
                    </div>
                    <div className={styles["container--textPragraphs"]}>
                        <p style={{color:"#323232", fontWeight:"bolder"}}> ¿Cómo puedes ejercer tus derechos ARCO y/o revocar tu consentimiento?
                        </p>
                        <p>
                            Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación,
                            cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para
                            el tratamiento de sus datos personales enviando un correo electrónico a la dirección
                            electrónica <a href="mailto:contacto@collectumdata.com.mx" aria-label="Mail" rel="noopener noreferrer" >contacto@collectumdata.com.mx.</a> Su petición deberá ser realizada a través del Formato de
                            Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico
                            antes señalada. Para que el Departamento de Protección de Datos de Collectum pueda darle seguimiento
                            a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo
                            que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia
                            de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. Para obtener mayor
                            información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o
                            revocación del consentimiento contacte al Departamento de Protección de Datos de Collectum.

                        </p>
                    </div>
                    <div className={styles["container--textPragraphs"]}>
                        <p style={{color:"#323232", fontWeight:"bolder"}}> ¿Cómo puedes limitar el uso y/o divulgación de tus datos?
                        </p>
                        <p>
                            Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección
                            contacto@collectumdata.com.mx en caso de que su Solicitud sea procedente se le registrará en el listado
                            de exclusión propio de Collectum. Para mayor información favor de contactar al Departamento de
                            Protección de Datos de Collectum.
                        </p>
                    </div>
                    <div className={styles["container--textPragraphs"]}>
                        <p style={{color:"#323232", fontWeight:"bolder"}}> ¿Cómo te notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?

                        </p>
                        <p>
                            Collectum le notificará de cualquier cambio a su aviso de privacidad a través de
                            la página de internet de Collectum <a href="www.collectumdata.com.mx.">www.collectumdata.com.mx.</a>
                        </p>
                    </div>
                    <div className={styles["container--textPragraphs"]}>
                        <p style={{color:"#323232", fontWeight:"bolder"}}> ¿Cómo puedes hacernos llegar tus dudas?
                        </p>
                        <p>
                            Cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y
                            protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href="www.collectumdata.com.mx.">www.collectumdata.com.mx.</a>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PrivacyPolicies