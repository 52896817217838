import React, { useEffect, useState } from "react";
import { Table, Layout, Tabs, Input, Tag, Button } from "antd";

import { useSelector, useDispatch } from "react-redux";
import styles from "./Rewards.module.css";
import GiftsBar from "../../Gifts/GiftsBar";
import moment from 'moment'
import { getExchangedRewards, getPaypalRewards, updatePaypalStatusAction } from "../../../redux/rewardsDuck";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "utid",
    dataIndex: "utid",
    key: "utid"
  },
  {
    title: 'Premio',
    dataIndex: 'rewardName',
    key: 'rewardName'
  },
  {
    title: 'Puntos',
    dataIndex: 'points',
    key: 'points',
  },
  {
    title: "Pesos",
    dataIndex: 'amountCharged',
    key: 'amountCharged',
    render: value => "$" + value.value + "MXN"
  },
  {
    title: 'Panelista',
    dataIndex: 'user',
    key: 'user',
    render: user => user ? user.email : null
  },
  {
    title: "Fecha",
    dataIndex: "createdAt",
    key: "createdAt",
    render: date => moment(date).format("LL")
  }
]

const { TabPane } = Tabs;
const { Content } = Layout;
const { Search } = Input;

const Rewards = () => {
  const dispatch = useDispatch();
  const exchanged = useSelector(state => state.rewards?.exchanged?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
  const { rewards, fetching } = useSelector(state => ({ ...state.rewards, rewards: state.rewards?.list }))
  const [exArray, setExArray] = useState([])

  useEffect(() => {
    setExArray(exchanged)
  }, [exchanged])

  useEffect(() => {
    dispatch(getExchangedRewards())
  }, [dispatch])

  const filterExchanged = ({ target: { value } }) => {
    const regEx = new RegExp(value, "i")
    const first = exchanged.filter(ex => ex.user ? regEx.test(ex.user.email) : regEx.test(ex.rewardName) || regEx.test(ex.rewardName))
    const second = exchanged.filter(ex => regEx.test(ex.rewardName))
    const final = Array.from(new Set([...first, ...second]))
    setExArray(final)
  }

  const onChangePane = (val) => {
    switch (val) {
      case 'paypal':
        return dispatch(getPaypalRewards())
      default:
        return dispatch(getExchangedRewards())
    }
  }

  const markAsPaid = (record) => {
    if (!window.confirm("Estas segura de marcar este Reward como pagado?")) { return }
    dispatch(updatePaypalStatusAction(record._id))
  }

  const paypalColumns = [
    {
      title: "utid",
      dataIndex: "utid",
      key: "utid"
    },
    {
      title: 'Premio',
      dataIndex: 'referenceOrderID',
      key: 'referenceOrderID'
    },
    {
      title: 'Puntos',
      dataIndex: 'points',
      key: 'points',
    },
    {
      title: "Pesos",
      dataIndex: 'amountCharged',
      key: 'amountCharged',
      render: value => "$" + value.value + "MXN"
    },
    {
      title: 'Panelista',
      dataIndex: 'user',
      key: 'user',
      render: user => user ? user.email : null
    },
    {
      title: "Fecha de canje",
      dataIndex: "createdAt",
      key: "createdAt",
      render: date => moment(date).format("LL")
    },
    {
      title: "Fecha de pago",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: paymentDate => paymentDate ? moment(paymentDate).format("LL") : null
    },
    {
      title: "Cuenta PayPal",
      dataIndex: "paypalInfo",
      key: "paypalInfo",
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (value, record) => value === "PAID" ? <Tag color="success">Pagado</Tag> : <Tag color="orange">Pendiente</Tag>
    },
    {
      title: "Acciones",
      dataIndex: "status",
      key: "status",
      render: (_, record) => <a onClick={() => markAsPaid(record)} style={{ textDecoration: 'underline' }} href="#!">Pagar</a>
    }
  ]

  return (
    <Content
      style={{
        background: "#f9f9f9",
        padding: "0 3%",
      }}
    >
      <div className={styles.rewards__title}>Premios</div>
      <div className={styles.rewards__content}>
        Collectum Datos Panel tiene
        <span className={styles["rewards__title--points"]}> {exchanged.length} premios </span>
        canjeados y
        <span className={styles["rewards__title--points"]}> {rewards.length} premios </span>
        en inventario.
      </div>
      <Tabs
        onChange={onChangePane}
        defaultActiveKey="history"
        style={{
          fontFamily: "'ABeeZee',sans-serif",
          fontSize: "18px",
          color: "#323232",
        }}
        size={"large"}
      >
        <TabPane tab="Todos" key="rewards">
          <div className={styles["rewards--container"]}>
            <GiftsBar />
          </div>
        </TabPane>
        <TabPane tab="Historial" key="history">
          <Search
            placeholder="Buscar"
            style={{
              width: "100%",
              borderRadius: "20px",
              fontFamily: "AvenirBook",
              fontSize: "18px",
              margin: "8px 0 24px 0",
              border: "none",
              boxShadow: "0px 4px 16px rgba(196, 196, 196, 0.25)",
            }}
            onChange={filterExchanged}
            allowClear
          />
          <div className={styles["rewards--container"]}>
            <Table
              style={{ width: "100%" }}
              columns={columns}
              dataSource={exArray}
              loading={fetching}
            />
          </div>
        </TabPane>
        <TabPane tab="Paypal" key="paypal">
          {/* <Search
            placeholder="Buscar"
            style={{
              width: "100%",
              borderRadius: "20px",
              fontFamily: "AvenirBook",
              fontSize: "18px",
              margin: "8px 0 24px 0",
              border: "none",
              boxShadow: "0px 4px 16px rgba(196, 196, 196, 0.25)",
            }}
            onChange={filterExchanged}
            allowClear
          /> */}
          <div className={styles["rewards--container"]}>
            <Table
              loading={fetching}
              style={{ width: "100%" }}
              columns={paypalColumns}
              dataSource={exArray}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: event => {

            //     }, // click row

            //   };
            // }}
            />
          </div>
        </TabPane>
      </Tabs>
    </Content>
  );
};

export default Rewards;
