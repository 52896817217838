import React from "react";
import queryString from 'query-string'
import { Carousel } from "antd";
import { Link } from "react-router-dom";

import SignUpForm from "./SignUpForm";
import SignInForm from "./SignInForm";

import styles from "./Sign.module.css";
import slide1 from "../../assets/slider1.jpg";
import slide2 from "../../assets/slider2.jpg";
import slide3 from "../../assets/slider3.jpg";
import logo from "../../assets/logo-panel.svg";

const Sign = ({ location, showSignIn }) => {
  const query = queryString.parse(location?.search)
  const signupCode = query.c ? query.c : null
  return (
    <div className={styles.sign}>
      <div className={styles.slider}>
        <Carousel autoplay style={{ height: "100%" }}>
          <div className={styles.slider__element}>
            <img src={slide1} alt="back-1" className={styles.slider__image} />
            <div className={styles.slider__content}>
              <p>¡Empieza a ganar premios ya!</p>
            </div>
          </div>
          <div className={styles.slider__element}>
            <img src={slide2} alt="back-1" className={styles.slider__image} />
            <div className={styles.slider__content}>
              <p>Entre más encuestas completes, más premios podrás canjear.</p>
            </div>
          </div>
          <div className={styles.slider__element}>
            <img src={slide3} alt="back-1" className={styles.slider__image} />
            <div className={styles.slider__content}>
              <p>Invita a tus amigos y diviértanse en Collectum Datos Panel.</p>
            </div>
          </div>
        </Carousel>
      </div>
      <div className={styles["side-right"]}>
        <Link to="/">
          <img src={logo} alt="logo" className={styles["side-right__logo"]} />
        </Link>
        <div>
          {showSignIn ? <SignInForm /> : <SignUpForm signupCode={signupCode} />}
        </div>

      </div>
    </div>
  );
};

export default Sign;
