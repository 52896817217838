import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import { Menu, Dropdown, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  faChartLine,
  faPoll,
  faTrophy,
  faPowerOff,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

import { logOutUserAction } from "../../redux/userDuck";

import "../Home/Home.css";
import menu from "../../assets/menu.svg";
import cancel from "../../assets/tache.svg";
import logo from "../../assets/logo-panel.svg";
import userImage from "../../assets/userImage.png";

const HomeNavbar = () => {
  const history = useHistory()
  let [show, setShow] = useState(false);
  // show && (document.body.style.overflow = "hidden");
  // !show && (document.body.style.overflow = "unset");

  useEffect(() => () => !show && (document.body.style.overflow = "unset"), []) // allow scroll on destroy

  const { loggedIn, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const doLogOut = () => {
    dispatch(logOutUserAction());
  };
  const userMenu =
    user.role === "ADMIN" ? (
      <Menu>
        <Menu.Item
          onClick={() => history.push("/admin/resumen")}
          icon={
            <FontAwesomeIcon
              icon={faChartLine}
              className={"sider__menu__element"}
            />
          }
        >
          Resumen
        </Menu.Item>
        <Menu.Item
          onClick={() => history.push("/admin/encuestas")}
          icon={
            <FontAwesomeIcon icon={faPoll} className={"sider__menu__element"} />
          }
        >
          Encuestas
        </Menu.Item>
        <Menu.Item
          onClick={() => history.push("/admin/premios")}
          icon={
            <FontAwesomeIcon
              icon={faTrophy}
              className={"sider__menu__element"}
            />
          }
        >
          Premios
        </Menu.Item>
        <Menu.Item
          onClick={() => history.push("/admin/panelistas")}
          className="menuItem"
          icon={
            <FontAwesomeIcon
              icon={faUserFriends}
              className={"sider__menu__element"}
            />
          }
        >
          Panelistas
        </Menu.Item>
        <Menu.Item
          className="menuItem"
          icon={
            <FontAwesomeIcon
              icon={faPowerOff}
              className={"sider__menu__element"}
            />
          }
        >
          <span className="menuItem" onClick={doLogOut}>
            Cerrar sesión
          </span>
        </Menu.Item>
      </Menu>
    ) : (
        <Menu>
          <Menu.Item
            onClick={() => history.push("/perfil/resumen")}
            icon={
              <FontAwesomeIcon
                icon={faChartLine}
                className={"sider__menu__element"}
              />
            }
          >
            Resumen
        </Menu.Item>
          <Menu.Item
            onClick={() => history.push("/perfil/encuestas")}
            icon={
              <FontAwesomeIcon icon={faPoll} className={"sider__menu__element"} />
            }
          >
            Encuestas
        </Menu.Item>
          <Menu.Item
            onClick={() => history.push("/perfil/premios")}
            icon={
              <FontAwesomeIcon
                icon={faTrophy}
                className={"sider__menu__element"}
              />
            }
          >
            Premios
        </Menu.Item>
          <Menu.Item
            onClick={() => history.push("/perfil/mi-perfil")}
            className="menuItem"
            icon={
              <FontAwesomeIcon
                icon={faUserCircle}
                className={"sider__menu__element"}
              />
            }
          >
            Mi perfil
        </Menu.Item>
          <Menu.Item
            className="menuItem"
            icon={
              <FontAwesomeIcon
                icon={faPowerOff}
                className={"sider__menu__element"}
              />
            }
          >
            <span className="menuItem" onClick={doLogOut}>
              Cerrar sesión
          </span>
          </Menu.Item>
        </Menu>
      );

  const style = {
    button: {
      fontFamily: "AvenirRoman",
      background: "#95BF48",
      color: "#FFFFFF",
      fontSize: "18px",
      borderRadius: "4px",
      border: "none",
      height: "40px",
      minWidth: "200px",
      maxWidth: "150px",
      width: "100%",
      margin: "5%",
      paddingTop: "4px",

    },
  };

  return (
    <header className="header-nav">
      {/* Desktop */}
      <div className="container-logo-nav">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        {loggedIn ? (
          <nav className="navigation_end">
            <Dropdown overlay={userMenu}>
              <div className="dropdown-button">
                <img src={userImage} alt="userImage" />
                {user.role === "ADMIN"
                  ? "Administrador"
                  : user.displayName
                    ? user.displayName
                    : user.email}
                <FontAwesomeIcon
                  icon={faCaretDown}
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </Dropdown>
          </nav>
        ) : (
            <nav className="navigation_end">
              <NavLink to="/premios" className="nav_premios">
                Premios
            </NavLink>
              <NavLink className="nav_linkthree" to="/registro">
                Registro
            </NavLink>
              <div className="navigation__end-separatorline"></div>
              <NavLink className="nav_linkfour" to="/signin">
                Iniciar sesión
            </NavLink>
            </nav>
          )}
      </div>
      {/* mobile */}
      <div className="container-logo-mobile-nav">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div>
          <input type="checkbox" className="btn-menu" id="btn-menu" />
          <figure
            onClick={() => setShow(!show)}
            htmlFor="btn-menu"
            className="icon-menu"
          >
            {show ? (
              <img src={cancel} alt="cancelar" />
            ) : (
                <img src={menu} alt="menu" />
              )}
          </figure>
        </div>
      </div>
      <div
        className={
          show
            ? "navigation-mobile"
            : "navigation-mobile navigation-mobile-hide"
        }
      >
        <nav className="navigation-mobile-links">
          <div className="logo-mobile">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div>
            <input type="checkbox" className="btn-menu" id="btn-menu" />
            <figure
              onClick={() => setShow(!show)}
              htmlFor="btn-menu"
              className="icon-menu"
            >
              {show ? (
                <img className="cancel-nav" src={cancel} alt="cancelar" />
              ) : (
                  <img src={menu} alt="menu" />
                )}
            </figure>
          </div>
          {/* Navigation in mobile*/}
          <NavLink to="/premios"> Premios </NavLink>
          <NavLink to="/contact"> Contacto </NavLink>
          {loggedIn ? (
            <div style={{ width: "90%", padding: "5% 6%" }}>
              <Dropdown overlay={userMenu}>
                <div className="dropdown-button">
                  <img src={userImage} alt="userImage" />
                  {user.role === "ADMIN"
                    ? "Administrador"
                    : user.displayName
                      ? user.displayName
                      : user.email}
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              </Dropdown>
            </div>
          ) : (
              <div>
                <NavLink to="/signin"> Iniciar Sesión </NavLink>
                <div className="borde-nav"></div>
                <Link className="link-register" to="/registro" style={{ padding: "0%" }}>
                  <Button className="register" style={style.button}>
                    REGISTRARME
              </Button>
                </Link>
              </div>
            )}
        </nav>
      </div>
    </header>
  );
};

export default HomeNavbar;
