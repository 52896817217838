import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";

import styles from "./Contact.module.css";

import Navbar from "../Home/HomeNavbar";
import HomeFooter from "../Home/HomeFooter";

const Contact = () => {


  const onFinish = (values) => {
    // console.log(values);
    const form = {
      bliss: "bliss",
      ...values
    }
    message.success('Hemos recibido tu mensaje, te responderemos lo antes posible.', 10);
    fetch(process.env.REACT_APP_BACKEND_URL + '/contact', {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        "Content-Type": "application/json"
      }
    })
  };

  const style = {
    button: {
      background: "#95BF48",
      color: "#FFFFFF",
      fontSize: "18px",
      borderRadius: "4px",
      border: "none",
      minWidth: "259px",
      maxWidth: "350px",
      width: "100%",
      height: "40px",
    },
  };

  return (
    <div>
      <Navbar />
      <div className={styles.ContactBanner}>
        <div className={styles.ContactBanner__title}>
          <p className={styles.ContactBanner__paragraph}>
            ¿Tienes alguna duda?
          </p>
          <p className={styles.ContactBanner__paragraph}> Contáctanos</p>
        </div>
      </div>
      <div className={styles.formContact}>
        <div className={styles.formContact__h1}>Completa el formulario</div>
        <p className={styles.formContact__paragraph}>
          ¿Tienes alguna duda? Nos pondremos en contacto contigo lo antes
          posible.
        </p>
        <Form
          name="comentarios"
          onFinish={onFinish}
          className={styles.formContact__form}
          style={{ margin: "24px 0" }}
          hideRequiredMark
          colon={false}
          layout="vertical"
        >
          <Form.Item
            label={<label className={styles.form__label}>Nombre</label>}
            rules={[{ required: true, message: "Por favor ingresa tu nombre" }]}
            name="name"
            className={styles.form__input}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "Por favor ingresa tu correo" },
              { type: "email", message: "Ingresa un correo valido" },
            ]}
            name="email"
            label={<label className={styles.form__label}>E-mail</label>}
            className={styles.form__input}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"message"}
            label={<label className={styles.form__label}>Mensaje</label>}
            rules={[
              { required: true, message: "Por favor ingresa un mensaje" },
            ]}
            className={styles["form__input--textarea"]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button
              style={style.button}
              className={styles.form__button}
              type="primary"
              htmlType="submit"
            >
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </div>
      <HomeFooter />
    </div>
  );
};

export default Contact;
