import React from 'react'
import { Table, Empty } from 'antd'
import styles from "./MyProfile.module.css";
import { useSelector } from 'react-redux'
import moment from 'moment'



export default function RedeemRewardsTable({ nocode }) {

  const columns = [
    {
      title: "Premio",
      dataIndex: "rewardName",
      key: "rewardName",
      className: "table__light-data ",
      render: (value, record) => value === "PAYPAL" ? record.referenceOrderID : value
    },
    {
      title: "Fecha de canje",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "table__light-data ",
      render: value => moment(value).format("LL")
    },
    {
      title: "Código",
      dataIndex: "credentials",
      key: "credentials",
      className: "table__light-data ",
      render: value => JSON.stringify(value.credentials).replace("{", "").replace("}", "")
    },
    {
      title: "Puntos",
      dataIndex: "points",
      key: "points",
      className: "table__data highlight--data",
    },
  ];

  if (nocode) columns.splice(2, 1)

  const { rewards = [] } = useSelector(({ user: { user } }) => user);
  rewards.reverse()
  return (
    <Table
      style={{ width: "100%" }}
      dataSource={nocode ? rewards.slice(0, 3) : rewards}
      columns={columns}
      pagination={false}
      className={styles.table}
      locale={{
        emptyText: (
          <Empty
            description={
              <div className={styles.emptyText}>
                Aún no has canjeado ni un Premio
                <a href="/perfil/premios">¡Empieza ya!</a>
              </div>
            }
          />
        ),
      }}
    />
  )
}