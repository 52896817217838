import React from "react";

import "../Home/Home.css";
import { Button } from 'antd'

import ellipse from "../../assets/Ellipsegreen.png";
import line from "../../assets/Linegreen.png";

const HomeCard = () => {
  const style = {
    button: {
      fontFamily:"AvenirRoman",
      background: "#95BF48",
      color: "#FFFFFF",
      fontSize: "18px",
      borderRadius: "4px",
      border: "none",
      minWidth: "259px",
      maxWidth: "350px",
      width: "70%",
      height: "40px",
    },
  };

  return (
    <div className="homeCard">
      <div className="homeCard--background" />
      <div className="card">
        <div className="card__title">Únete</div>
        <div className="container_images">
          <img className="ellipse" src={ellipse} alt="" />
          <img className="line" src={line} alt="" />
        </div>
        <div className="card__content">
          <p>
          Ahora puedes ganar premios compartiendo tu experiencia acerca de productos o servicios que usas diariamente, solo necesitas unos minutos al día, y podrás ganar todo tipo de premios. ¡Y recuerda! Los datos que recogemos son tratados de forma anónima y confidencial, y además, tú decides qué información quieres compartir con nosotros. 
          </p>
        </div>
        <div className="numbersContainer">
          <div className="Container__element">
            <p className="elment__number">+999</p>
            <p className="elemnt__text">COLABORADORES</p>
          </div>
          <div className="Container__element">
            <p className="elment__number">+20</p>
            <p className="elemnt__text">PREMIOS</p>
          </div>
          <div className="Container__element">
            <p className="elment__number">+300</p>
            <p className="elemnt__text">ENCUESTAS</p>
          </div>
        </div>
        <Button
              className="form__button"
              htmlType="submit"
              style={style.button}
              href="/registro"
            >
          <p style={{paddingTop:"1.8%"}}>REGISTRARME</p>
            </Button>
      </div>
    </div>
  );
};

export default HomeCard;
