import React from "react";
import { Form, Input, Button, Spin } from "antd";
import { useHistory, Link } from "react-router-dom";
import { Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { loginUserAction } from "../../redux/userDuck";

import styles from "./Sign.module.css";
const SignInForm = () => {
  const { fetching, error } = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const doSignin = async (form) => {
    let signin = await dispatch(loginUserAction(form));
    if (signin) {
      // check for next?
      const next = localStorage.getItem('next')
      if (next) {
        localStorage.removeItem('next')
        return history.push(next)
      }
      if (signin.user.role === "ADMIN") return history.push("/admin/resumen");
    } else { return history.push("/perfil/resumen") }
  };

  const style = {
    button: {
      background: "#95BF48",
      color: "#FFFFFF",
      fontSize: "16px",
      borderRadius: "4px",
      border: "none",
      width: "85%",
      maxWidth: "280px",
      height: "40px",
      fontWeight: "800",
      lineHeight: "initial",
    },
  };

  return (
    <div className={styles.signinform}>
      <h2 className={styles["side-right__Title"]}>Inicia sesión</h2>
      <div className={styles["side-right__content"]}>
        Continúa compartiendo tu experiencia y gana puntos para canjearlos por
        tus premios favoritos.
      </div>
      <Form
        name="signin"
        onFinish={doSignin}
        className={styles.form}
        hideRequiredMark
        colon={false}
        layout="vertical"
      >
        {error && error !== "" && (
          <Alert message={error} type="error" showIcon />
        )}
        <Form.Item
          rules={[
            { required: true, message: "Por favor ingresa tu correo" },
            { type: "email", message: "Ingresa un correo valido" },
          ]}
          className={styles["form__input-container"]}
          name="email"
          label={<label className={styles.form__label}>E-mail</label>}
        >
          <Input className={styles.form__input} />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: "Por favor ingresa tu contraseña" },
          ]}
          className={styles["form__input-container"]}
          name="password"
          label={<label className={styles.form__label}> Contraseña </label>}
        >
          <Input.Password className={styles.form__input} />
        </Form.Item>
        <Form.Item className={styles.form__container__button}>
          {fetching ? (
            <Spin style={{ margin: "0 46%" }} />
          ) : (
            <Button
              className={styles.form__button}
              htmlType="submit"
              style={style.button}
            >
              <p style={{ paddingTop: "3%" }}>INICIAR SESIÓN</p>
            </Button>
          )}
        </Form.Item>
        <div className={styles["if-password"]}>
          <Link to="/olvide-contraseña">¿Olvidaste tu contraseña?</Link>
        </div>
      </Form>
      <div className={styles["if-account"]}>
        ¿No tienes cuenta?{" "}
        <Link to="/registro" className={styles["if-account--link"]}>
          Regístrate
        </Link>
      </div>
    </div>
  );
};
export default SignInForm;
