import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Table, Pagination, Layout, Input, Modal, Switch, Button, Select, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  deactivateUserAction,
  getAllUsersAction,
  getAllUsersWithProfileAction,
  getUsersWithoutProfilePaginated,
  setCurrentUserAction,
  getGroupsAction,
  setUserToGroupsAction,
} from '../../../redux/adminDuck'

import styles from './Panelists.module.css'
import userImage from '../../../assets/userImage.png'
import { useHistory } from 'react-router-dom'

import {
  _header,
} from '../../tools/Exportxlsx'
import { Link } from 'react-router-dom'

const { Option } = Select;
const { Content } = Layout
const { Search } = Input

const Panelists = () => {
  const history = useHistory()
  const data = []
  //Signed user
  const { token: accessToken } = useSelector(state => state.user)

  const [modal, handleModal] = useState(false)
  const [handleData, setHandleData] = useState(data)
  const [panelist, setPanelist] = useState(null)
  const [show, setShow] = useState(true)
  const [internalLoading, setInternalLoading] = useState(false)
  // const [isNoProfile, setIsNoProfile] = useState(false)
  const dispatch = useDispatch()
  // const { panelists, fetching } = useSelector((state) => state.user);
  const { groups, page, pages, limit, total, items: panelists, fetching } = useSelector(
    ({ admin }) => admin,
  )
  const [allUsers, setAllUsers] = React.useState([])
  const searchRef = React.useRef()

  //groups
  const [selectedRows, setSelectedRows] = React.useState([])
  const [openGroupModal, setOpenGroupModal] = React.useState(false)
  const [selectedGroups, setSelectedGroups] = React.useState([])

  const addUsersToGroups = () => {
    setOpenGroupModal(false)
    const body = {
      groupNames: selectedGroups,
      userIds: selectedRows
    }
    dispatch(setUserToGroupsAction(body))
      .then(result => {
        if (result) {
          dispatch(getAllUsersAction())
          setSelectedGroups([])
        }
      })
  }

  const getAllUsersAndFormat = async (cb = () => false) => {
    const API_URL = process.env.REACT_APP_BACKEND_URL
    const getpanelists = await axios.get(API_URL + '/auth/users')
    const data = formatPanelists(getpanelists.data?.users, true)
    cb(data)
    return data

  }

  const currentPage = localStorage.currentPage ? Number(localStorage.currentPage) : 1

  useEffect(() => {
    // if (isNoProfile) {
    //   doGetPanelists('no-profile')
    // } else {
    //   doGetPanelists()
    // }
    dispatch(getAllUsersAction())
    dispatch(getGroupsAction())
    const getAll = async () => {
      setInternalLoading(true)
      const all = await getAllUsersAndFormat()
      setInternalLoading(false)
      setAllUsers(all)
    }
    // getAll()
  }, [])

  useEffect(() => {
    formatPanelists(panelists)
  }, [panelists])

  const formatPanelists = (users, returnData = false) => {
    moment.locale('es')
    const dataProcessed = users.map((item) => {
      return {
        ...item,
        key: item.id,
        photoURL: item.photoURL ? item.photoURL : userImage,
        displayName: item.displayName ? item.displayName : item.username,
        email: item.email,
        createdAt: item.createdAt,
        completed: item.answers?.length,
        active: item.active,
        birthDate: item.birthDate,
        state: item.state,
        gender: item.gender,
        puntos: typeof item.points === 'object' ? item.puntos : item.points,
        // ...item.profile[0],
      }
    })
    if (returnData) {
      return dataProcessed
    } else {
      setHandleData(dataProcessed)
    }
  }

  const doGetPanelists = async (noProfile) => {
    if (noProfile) {
      dispatch(getUsersWithoutProfilePaginated({ p: currentPage }))
    } else {
      dispatch(getAllUsersWithProfileAction({ p: currentPage }))
    }
  }

  const searchInData = async (event = { target: { value: null } }, updatedUser) => {
    let { target: { value } } = event
    //
    if (!value) {
      value = searchRef.current?.state?.value
    }
    // change this for a server request
    if (value.length > 3) {
      setInternalLoading(true)

      const all = updatedUser ? [...allUsers.map(u => u._id === updatedUser._id ? { ...u, active: updatedUser.active } : u)] : [...allUsers]
      const regEx = new RegExp(value, 'i')
      let filteredData = all.filter(
        (item) => regEx.test(item.displayName) || regEx.test(item.email),
      )
      formatPanelists(filteredData)
      setShow(false)
      setInternalLoading(false)
      return
    } else {
      // changePage(currentPage)
      // setShow(true)
      setHandleData(panelists)
    }
    return

  }

  const columns = [
    {
      title: '',
      dataIndex: 'photoURL',
      key: 'photoURL',
      className: 'table__product-img',
      render: (imageArray) => (
        <img
          src={imageArray || userImage}
          onError={(e) => {
            e.target.src = userImage
          }}
          className={styles['panelist--image']}
          alt="imagen de perfil"
        />
      ),
    },
    {
      title: 'Nombre',
      dataIndex: 'displayName',
      key: 'displayName',
      className: 'table__light-data',
      render: (val, record) => <a
        href="#!"
        onClick={async () => {
          await dispatch(setCurrentUserAction(record))
          history.push('/admin/info')
          localStorage.currentPage = String(page)
        }}
      >{val}</a>
    },
    {
      title: 'Código de registro',
      dataIndex: 'signupCode',
      key: 'signupCode',
    },
    {
      title: 'Correo',
      dataIndex: 'email',
      key: 'email',
      className: 'table__light-data',
    },
    {
      title: 'Miembro desde',
      dataIndex: 'createdAt',
      key: 'createdAt',
      className: 'table__light-data',
      render: (val) => moment(val).format('LL'),
    },
    {
      title: 'Encuestas asignadas',
      dataIndex: 'completed',
      key: 'completed',
      className: 'table__data',
      align: 'center',
    },
    {
      title: 'Puntos',
      dataIndex: 'puntos',
      key: 'puntos',
      className: 'table__data',
      align: 'center',
    },
    {
      title: 'Activar/Desactivar',
      dataIndex: 'active',
      key: 'active',
      render: (value, record) => (
        <Switch checked={value} onChange={() => openModal(record)} />
      ),
    },
  ]

  const openModal = (record) => {
    setPanelist(record)
    if (!record.active) return deactivateUser(record)
    handleModal(true)
  }

  const closeModal = () => handleModal(false)

  const deactivateUser = (item) => {
    if (panelist) { item = panelist }
    dispatch(deactivateUserAction(item, !item.active))
      .then(data => {
        // console.log("aqui")
        // setAllUsers(all => all.map(u => u._id === data._id ? data : u))
        // update the users with the search value
        searchInData(undefined, data)
      })
    closeModal()

  }

  const exportXls = async () => {
    const data = await getAllUsersAndFormat()
    const headersKeys = _header.map((cell) => cell.key)
    const rows = [
      _header.map((cell) => cell.name),
      ...data.map((record) =>
        headersKeys.map((he) =>
          typeof record[he] === 'object'
            ? record[he].join('/')
            : `${record[he]}`,
        ),
      ),
    ]
    let csvContent =
      'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n')
    var encodedUri = encodeURI(csvContent)
    window.open(encodedUri)
  }

  const changePage = (nextPage) => {
    const paginated = {
      p: nextPage,
      l: limit,
    }
    // if (isNoProfile) {
    //   dispatch(getUsersWithoutProfilePaginated(paginated))
    // } else {
    //   dispatch(getAllUsersWithProfileAction(paginated))
    // }
    dispatch(getAllUsersAction(paginated))
  }

  const removeGroup = async (e, group) => {
    if (!window.confirm('¿Estas segura de borrar este grupo?')) { return e.preventDefault() }
    const API_URL = process.env.REACT_APP_BACKEND_URL
    const result = await axios.delete(API_URL + `/groups/delete/${group._id}`, {
      headers: { Authorization: accessToken }
    })
  }

  return (
    <Content
      style={{
        background: '#f9f9f9',
        padding: '0 3%',
      }}
    >
      <div className={styles.panelists__title}>Panelistas</div>
      <div className={styles.panelists__content}>
        Collectum Datos Panel tiene{' '}
        <div className={styles['panelists__title--quantity']}>
          <span> {total} panelistas registrados</span>
          <div className={styles['panelists__title--quantity']}>
            <Button
              onClick={exportXls}
              style={{ margin: '0 0 0 24px' }}
            >
              Descargar Excel
            </Button>
            <Button
              onClick={() => setOpenGroupModal(true)}
              type="primary"
              style={{ margin: '0 0 0 24px' }}
              disabled={!!!selectedRows.length}
            >
              Agrupar
            </Button>
          </div>
        </div >
        {/* {isNoProfile ? 'Sin perfil' : 'registrados.'} */}
        {/* <Button
          onClick={() => setIsNoProfile((state) => !state)}
          style={isNoProfile ? { background: '#95bf48', color: 'white' } : {}}
          className={styles['panelists__title--quantity']}
        >
          Usuarios sin perfil
        </Button> */}

      </div>
      <Search
        ref={searchRef}
        placeholder="Buscar"
        style={{
          width: '100%',
          borderRadius: '20px',
          fontFamily: 'AvenirBook',
          fontSize: '18px',
          margin: '7px 0',
        }}
        allowClear
        onChange={(e) => searchInData(e)}
      />
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys) => {
            setSelectedRows(selectedRowKeys)
          }
        }
        }
        columns={columns}
        dataSource={handleData}
        pagination={false}
        style={{ width: '100%', margin: '20px 0' }}
        loading={fetching || internalLoading}
      // onRow={(record, rowIndex) => {
      //   return {
      //     onClick: async event => {
      //       await dispatch(setCurrentUserAction(record))
      //       history.push('/admin/info')
      //       localStorage.currentPage = String(page)
      //       // console.log("page? ", localStorage.currentPage)
      //     }, // click row

      //   };
      // }}
      />
      {show && (
        <Pagination
          showSizeChanger={false}
          pageSize={limit || 50}
          current={page}
          onChange={changePage}
          total={total}
          style={{ marginTop: 32 }}
        />
      )}
      <Modal
        visible={modal}
        onCancel={closeModal}
        width="65%"
        footer={null}
        style={{ minWidth: '290px' }}
      >
        <div className={styles.modal__title}>Desactivar panelista</div>
        <div className={styles.modal__content}>
          ¿Estas seguro de desactivar la cuenta de este panelista? La cuenta se
          desactivará inmediatamente y ya no podrá acceder.
        </div>
        <div className={styles.modal__footer}>
          <button
            onClick={closeModal}
            className={styles['modal__button--cancel']}
          >
            Cancelar
          </button>
          <button
            onClick={deactivateUser}
            className={styles['modal__button--delete']}
          >
            Desactivar
          </button>
        </div>
      </Modal>

      <Modal
        visible={openGroupModal}
        onCancel={() => setOpenGroupModal(false)}
        width="65%"
        footer={null}
        style={{ minWidth: '290px' }}
      >
        <div className={styles.modal__title}>Agrupar panelistas</div>
        <div className={styles.modal__content}>
          <div>
            <h3>Grupos existentes</h3>
            {groups.map(g => <Tag onClick={() => {
              setSelectedGroups([...new Set([...selectedGroups, g.title])])
            }} onClose={e => removeGroup(e, g)} closable key={g.title}>{g.title}</Tag>)}
            <br />
            <br />
          </div>
          <Select
            value={selectedGroups}
            mode="tags"
            allowClear
            style={{ width: '100%' }}
            placeholder="Selecciona o crea un grupo"
            onChange={(values) => {
              setSelectedGroups(values)
            }}
          >
            {groups.map(g => <Option key={g.title}>{g.title}</Option>)}
          </Select>
        </div>
        <div className={styles.modal__footer}>
          <button
            onClick={() => {
              setSelectedGroups([])
              setOpenGroupModal(false)
            }}
            className={styles['modal__button--cancel']}
          >
            Cancelar
          </button>
          <button
            onClick={addUsersToGroups}
            className={styles['modal__button--delete']}
          >
            Guardar
          </button>
        </div>
      </Modal>
    </Content >
  )
}
export default Panelists
