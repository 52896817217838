import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Collapse } from "antd";
import { DownOutlined } from "@ant-design/icons";

import HomeNavbar from "../Home/HomeNavbar";

import styles from "./FrequentQ.module.css";

const { Panel } = Collapse;

const renderQuestion = ({ question, answer }, id) => {
  return (
    <Panel header={question} className={styles.questions_question} key={id}>
      <div className={styles.questions__answer}>{answer}</div>
    </Panel>
  );
};

const questionsList = [
  {
    question: "¿Qué es Collectum Datos Panel?",
    answer:
      "Collectum Datos Panel es una comunidad online exclusiva donde ganarás regalos al dar tu opinión y compartir tus experiencias. Por cada encuesta que completes, ganarás puntos que podrás canjear por regalos. Descubre más acerca de nuestra historia, valores y protección de datos.",
  },
  {
    question: "¿Cómo me registro en Collectum Datos Panel?",
    answer:
      "Registrarte en Collectum Datos Panel es muy sencillo, solo da clic en el botón Registro e ingresa tu nombre, email y contraseña. Al haber creado tu cuenta, contesta un breve cuestionario para comenzar a enviarte las encuestas que concuerden con tu perfil.",
  },
  {
    question: "¿Cómo puedo canjear los regalos?",
    answer:
      "Al completar encuestas ganaras puntos, que puedes canjear en cualquier momento por regalos de la plataforma. Solo ve a la sección Premios, elige el que más te guste, y canjéalo, podrás ver en tu perfil el historial de tus premios.",
  },
  {
    question: "¿Cómo puedo saber cuántos puntos tengo?",
    answer:
      "En tu perfil podrás ver los puntos que has ganado, y también podrás ver a detalle cuantos puntos has ganado por cada encuesta.",
  },
  {
    question: "¿Qué hago si tengo problemas para acceder a mi cuenta?",
    answer:
      "No te preocupes, ponte en contacto con nosotros por medio de nuestras redes sociales, y te ayudaremos a acceder a tu cuenta.",
  },
  {
    question: "¿Cómo puedo cambiar o recuperar mi contraseña?",
    answer:
      (<p>Si no recuerdas tu contraseña, ve a la sección de <NavLink activeClassName={styles.signin} className={styles.signin} to="/signin">Inicia sesión</NavLink> y encontrarás la opción de <NavLink activeClassName={styles.signin} className={styles.signin} to="/olvide-contraseña">¿Olvide mi contraseña?</NavLink>, da clic e ingresa tu email, te enviaremos una liga a tu email registrado para restablecer tu contraseña.</p>),
  },
];

const FrequentQ = () => {
  return (
    <div className={styles.frequentQ}>
      <HomeNavbar />
      <div className={styles.banner}>
        <div className={styles.banner__content}> ¿Cómo podemos ayudarte? </div>
      </div>
      <div className={styles.questions}>
        <Collapse
          defaultActiveKey={["0"]}
          bordered={false}
          className={styles["site-collapse-custom-collapse"]}
          expandIconPosition="right"
          style={{ background: "#ffffff" }}
          expandIcon={({ isActive }) => (
            <DownOutlined rotate={isActive ? 180 : 0} />
          )}
        >
          {questionsList.map((item, index) => renderQuestion(item, index))}
        </Collapse>
      </div>
    </div>
  );
};
export default FrequentQ;