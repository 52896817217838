import React, { useEffect, useState } from 'react';
import { Modal, Button, message, Input } from "antd";
import styles from "./Rewards.module.css";
import { useDispatch } from 'react-redux'
import { redeemPaypalAction, redeemRewardAction } from '../../../redux/rewardsDuck';
import Disclaimer from '../../Gifts/Disclaimer';


const RewardModal = ({ isVisible, onClose, reward, userPoints }) => {
  const dispatch = useDispatch()
  const { items = [], description = "", imageUrls = {}, terms } = reward
  const [amount, setAmount] = useState(100)
  // const [item = {}] = items;
  const [item, setItem] = useState({})
  const { rewardName = "", faceValue = amount } = item
  const [modal, contextHolder] = Modal.useModal();
  const [points, setPoints] = useState(Math.floor(faceValue / 1.5))
  const [paypalInfo, setPaypalInfo] = useState(null)

  const onOkModal = () => {
    let rew = item
    if (!item.faceValue) {
      rew = { ...item, faceValue: amount }
    }
    if (rew.rewardType === "CASH") {
      dispatch(redeemPaypalAction({ ...rew, paypalInfo }))
    } else {
      dispatch(redeemRewardAction(rew))
    }

    onClose()
  }

  const config = {
    title: 'Confirma tu premio',
    content: (
      <p>
        Una vez canjeado tu premio, no hay devolución.
      </p>
    ),
    onOk: onOkModal,
  };

  useEffect(() => {
    if (items.length) setItem(items[0])
  }, [items])

  useEffect(() => {
    setPoints(Math.floor(item.faceValue / 1.5))
  }, [item])

  useEffect(() => {
    setPoints(Math.floor(amount / 1.5))
  }, [amount])

  const onChange = ({ target: { name, value } }) => {
    if (name === "paypalInfo") {
      setPaypalInfo(value)
    }
  }

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      width="65%"
      footer={null}
      style={{ minWidth: "290px" }}
    >
      <div className={styles.modal__Title}>{rewardName}</div>
      <div className={styles.alligner}>
        <div className={styles.left}>
          <img
            src={imageUrls["300w-326ppi"]}
            alt="card image"
          />
        </div>
        <div className={styles.right}>
          <div className={styles.right__description}>DESCRIPCIÓN</div>
          <div className={styles.right__content}>
            <div className="content" dangerouslySetInnerHTML={{ __html: description.slice(0, 400) }}></div>
          </div>

          {!rewardName.includes("Amazon") && <select style={{ padding: "5px 20px" }} onChange={({ target: { value } }) => {
            setItem(items[value])
            setPoints(Math.floor(items[value].faceValue / 1.5))
          }}>
            {items.map((it, index) => <option value={index} >{it.rewardName}</option>)}
          </select>}

          {reward.brandName === "Amazon.com.mx" && <><span>$ </span><input min="50" max="2000" onChange={({ target: { value } }) => {
            if (value > 2000) setAmount(2000)
            else if (value < 50) {
              setAmount(value)
            }
            else setAmount(value)
          }} value={amount} placeholder="500" style={{ padding: "5px 20px", border: "1px solid #ccc" }} type="number" /><span> MXN</span></>}

          <div className={styles.right__points}>
            {points} puntos{" "}
          </div>
          {reward.brandName === "PayPal" && <>
            <p>Ingresa tu email o número telefónico de tu cuenta de PayPal</p>
            <Input name="paypalInfo" onChange={onChange} placeholder="Email o teléfono" />
          </>}
          <Button primary disabled={userPoints < points || points < 1 || (!reward.faceValue && amount < 50) || (reward.brandName === "PayPal" && (!paypalInfo || paypalInfo.length < 10))} className={styles.right__button} onClick={() => {
            if (userPoints < points) return alert("No tienes puntos suficientes")
            modal.confirm(config)
          }}>
            {" "}CANJEAR
          </Button>
          {userPoints < points && <p style={{ color: "red" }}>No tienes puntos suficientes</p>}
        </div>
      </div>
      {contextHolder}
      <Disclaimer text={terms} width="100%" />
    </Modal>
  );
}

export default RewardModal;