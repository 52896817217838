import React, { useEffect } from "react";
import { Layout, Tabs, Table, Button } from "antd";
import { useSelector, useDispatch } from 'react-redux'
import { getSelfDataAction } from '../../../redux/userDuck'
import moment from 'moment'
import styles from "./Surveys.module.css";
import constants from '../../../constanst.json'

const { TabPane } = Tabs;
const { Content } = Layout;
const data = [
  // {
  //   key: "1",
  //   name: "Santa Clara Helados 2014 - 2015",
  //   date: "01/may/2020",
  //   questions: "23 preguntas",
  //   points: "120 puntos",
  //   estatus: "new",
  //   link: "/",
  // },
  // {
  //   key: "2",
  //   name: "Santa Clara Helados 2014 - 2015",
  //   date: "01/may/2020",
  //   questions: "23 preguntas",
  //   points: "120 puntos",
  //   estatus: "pending",
  //   link: "/",
  // },
  // {
  //   key: "3",
  //   name: "Santa Clara Helados 2014 - 2015",
  //   date: "01/may/2020",
  //   questions: "23 preguntas",
  //   points: "120 puntos",
  //   estatus: "completed",
  // },
  // {
  //   key: "4",
  //   name: "Santa Clara Helados 2 2014 - 2015",
  //   date: "02/may/2020",
  //   questions: "23 preguntas",
  //   points: "120 puntos",
  //   estatus: "new",
  //   link: "/",
  // },
];

const Surveys = () => {

  const { user } = useSelector(state => state.user)
  const surveys = useSelector(state => {
    if (state.user.user.answers && state.user.user.answers.length) {
      const surveys = state.user.user.answers.map(answer => {
        return ({ ...answer, ...answer.survey })
      })
      return surveys
    } else {
      return []
    }
  })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSelfDataAction())
  }, [dispatch])

  const filterData = (estatus) => surveys.filter((item) => item.completed === estatus);
  const notEqual = (estatus) => surveys.filter((item) => item.completed !== estatus);

  const newOnes = () => {
    const t = filterData("N").filter((item) => {
      const amonth = moment().subtract(1, 'months')
      const diff = amonth.diff(moment(item.createdAt), "days")
      return diff <= 0
    })
    return filterData("N").filter((item) => {
      const amonth = moment().subtract(1, 'months')
      const diff = amonth.diff(moment(item.createdAt), "days")
      return diff <= 0
    }).sort((a, b) => b.createdAt?.localeCompare(a.createdAt)) // 2022
  };

  const renderTag = (type, record) => {
    console.log("WAT: ", type)
    switch (type) {
      case "new":
        return <div className={styles["tag--new"]}> !Nueva! </div>;
      case "N":
        return <div onClick={() => openSurvey(record)} className={styles["tag--pending"]}> Pendiente </div>;
      // case undefined:
      //   return <div className={styles["tag--warning"]}> No cumples con el perfil </div>; // 2022
      default:
        return <div className={styles["tag--completed"]}> Completada </div>;
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "title",
      key: "title",
      className: "table__light-data",
    },
    {
      title: "Fecha",
      dataIndex: "datecreated",
      key: "datecreated",
      className: "table__light-data",
      render: date => moment(date).format('ll')
    },
    {
      title: "Código de acceso",
      dataIndex: "token",
      key: "token",
      className: "table__light-data",
      align: "center",
      render: (value, record) => record.completed === "N" ? <div className="table__light-data">{value}</div> : 'No necesario'
    },
    {
      title: "Puntos",
      dataIndex: "points",
      key: "points",
      className: "table__data",
    },
    {
      title: "Estatus",
      dataIndex: "completed",
      key: "completed",
      render: (type, record) => renderTag(type, record),
    },
    {
      title: "Acciones",
      dataIndex: "ver",
      key: "ver",
      render: (value, record) => record.completed === "N" && <a href="#!" style={{ textDecoration: 'underline' }} onClick={() => openSurvey(record)}>Responder</a>,
    }
  ];

  function openSurvey(survey) {
    if (survey.external) { return window.open(`${survey.link}`, "", "width=800,height=800") }
    window.open(`${constants.openSurveyURL}${survey.sid}`, "", "width=800,height=800")
  }

  return (
    <Content
      style={{
        background: "#f9f9f9",
        padding: "0 2%",
      }}
    >
      <div className={styles.polls__title}>Encuestas</div>
      <div className={styles.polls__content}>
        Cada encuesta que completes te otorgará puntos para canjearlos por los
        premios que más te gusten. Hasta el día de hoy has acumulado
        <span className={styles["polls__title--points"]}> {user.points} puntos</span>.
      </div>
      <Tabs
        defaultActiveKey="1"
        style={{
          fontFamily: "'ABeeZee',sans-serif",
          fontSize: "18px",
          color: "#323232",
          marginBottom: "60px",
        }}
      >
        <TabPane tab="Todas" key="1">
          <Table
            dataSource={surveys}
            columns={columns}
            pagination={false}
            className={styles.table}
          />
        </TabPane>
        <TabPane tab="Pendientes" key="2">
          <Table
            dataSource={filterData("N")}
            columns={columns}
            pagination={false}
            className={styles.table}
          />
        </TabPane>
        <TabPane tab="Completadas" key="3">
          <Table
            dataSource={notEqual("N")}
            columns={columns}
            pagination={false}
            className={styles.table}
          />
        </TabPane>
        <TabPane tab="Nuevas" key="4">
          <Table
            dataSource={newOnes()}
            columns={columns}
            pagination={false}
            className={styles.table}
          />
        </TabPane>
      </Tabs>
      <div onClick={() => window.location.reload()} className={styles["tag--pending"]}> Actualiza la página al terminar de responder una encuesta </div>
    </Content>
  );
};

export default Surveys;
