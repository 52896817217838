import React from "react";
import { Layout } from "antd";
import { useParams } from "react-router-dom";

import SiderAdmin from "./SiderAdmin";
import HeaderAdmin from "./HeaderAdmin";
import Summary from "./Summary/Summary";
import Panelists from "./Panelists/Panelists";
import Rewards from "./Rewards/Rewards";
import Surveys from "./Surveys/Surveys";

import styles from "./Admin.module.css";
import Info from "./Panelists/Info";

const { Header, Content, Sider } = Layout;

const renderSection = (section) => {
  switch (section) {
    case "resumen":
      return <Summary />;
    case "premios":
      return <Rewards />;
    case "encuestas":
      return <Surveys />;
    case "panelistas":
      return <Panelists />;
    case 'info':
      return <Info />
    default:
      return;
  }
};

const Admin = () => {
  const { section } = useParams();
  return (
    <Layout
      className={styles.admin}
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        breakpoint="sm"
        collapsedWidth="0"
        className={styles.sider}
        style={{ background: "#323232" }}
      >
        <SiderAdmin />
      </Sider>
      <Layout>
        <Header
          theme="light"
          style={{
            background: "#f9f9f9",
          }}
        >
          <HeaderAdmin />
        </Header>
        <Content
          style={{
            background: "#f9f9f9",
            padding: "0 3%",
          }}
        >
          {renderSection(section)}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Admin;
