import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import image from "../assets/visual.svg"

const CloseWindow = () => {
    const params = useParams()
    const closeWindow = () => window.close()
    const { token } = useSelector(state => state.user)
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)

    useEffect(() => {
        const { surveySid } = params
        const updateAnswer = async () => {
            const result = await fetch(new URL(process.env.REACT_APP_BACKEND_URL + '/surveys/redeem/' + surveySid), {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({ sid: surveySid })
            })
            console.log("RESULT: ", result)
            const data = await result.json()
            console.log("DATA: ", data)
            setData(data)
            return data;
        }

        if (token && surveySid) {
            // redeem answer here (service)
            updateAnswer()
        } else {
            setError('No es posible abonar los puntos, vuelve a iniciar sesión.')
        }

    }, [])

    useEffect(() => {
        if (data && !data.success) {
            setError(data.title)
        }
    }, [data])

    return (<>
        <div style={{ width: "100%", padding: "5% 10%", textAlign: "center" }}>
            <h2>
                {error ?
                    error
                    :
                    "Has completado la encuesta exitosamente"
                }
            </h2>
            {!error && <p>Sigue compartiendo tus experiencias y gana puntos por cada encuesta completada. </p>}
            <img src={image} alt="success-image" style={{ width: "280px" }} />
            <br />
            {!error && <button style={{
                backgroundColor: "#95BF48",
                border: "none",
                color: "white",
                padding: "8px 16px",
                borderRadius: "20px",
                cursor: "pointer"
            }}
                onClick={closeWindow}
            >

                ¡Mira cuantos puntos has ganado!
            </button>}
        </div>

    </>);
}

export default CloseWindow;