import React from 'react'
import image from "../assets/oops.svg"

const NotSuitable = () => {

    const closeWindow = () => window.close()

    return (<>
        <div style={{ width: "100%", padding: "5% 10%", textAlign: "center" }}>
            <h2>¡Gracias por participar!</h2>
            <p>Desafortunadamente no cumples el perfil para completar esta encuesta, pero te enviaremos otra pronto. ¡Sigue comaprtiendo tus experiencias! </p>
            <p style={{ marginTop: 50 }} />
            <a href="www.collectumdata.com.mx" style={{ color: "#2d9cdb", cursor: "pointer" }} onClick={closeWindow}>
                www.collectumdata.com.mx
            </a>
            <p style={{ marginTop: 50 }} />
            <img src={image} alt="success-image" style={{ width: "280px" }} />
        </div>

    </>);
}

export default NotSuitable;