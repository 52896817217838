import React from 'react'
import styles from './Settings.module.css'
import { Layout, Form, Input, Button, Message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { updatePasswordAction } from '../../../redux/userDuck'

let { Content } = Layout
const Settings = () => {
  const dispatch = useDispatch()
  const { fetching } = useSelector(({ user }) => user)
  const [form] = Form.useForm()
  const changePassword = () => {
    const password = form.getFieldValue('password')
    dispatch(updatePasswordAction(password))
    form.resetFields()
  }

  return (
    <Content
      style={{
        background: '#f9f9f9',
        padding: '0 3%',
      }}
    >
      <div className={styles.settings__title}>Configuración</div>
      <div className={styles.settings__container__sttings}>
        <div className={styles.settings__change__password__card}>
          <div className={styles.change__password__card__title}>
            <h3 className={styles.change_password__card__title__paragraph}>
              Actualización de contraseña
            </h3>
          </div>
          <div className={styles.change__password__card__info}>
            <p className={styles.change__password__card__info__paragraph}>
              Recuerda que tu contraseña debe contener al menos 8 caracteres,
              una mayúscula y un número.
            </p>
          </div>
          <div className={styles.change__password__card__form}>
            <Form
              form={form}
              onFinish={changePassword}
              name="change-password"
              className={styles.change__password__form}
              style={{ margin: '10px 0' }}
              hideRequiredMark
              colon={false}
              layout="vertical"
            >
              <Form.Item
                label={<label className={styles.form__label}>Contraseña</label>}
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa tu nueva contraseña',
                  },
                ]}
                name="password"
                className={styles.form__input}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Por favor confirma tu contraseña',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue('password').length < 8) {
                        return Promise.reject(
                          new Error(
                            'La contraseña debe contener al menos 8 caracteres',
                          ),
                        )
                      }
                      if (!value || getFieldValue('password') !== value) {
                        return Promise.reject(
                          new Error('Las contraseñas no coinciden'),
                        )
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
                name="confirm"
                dependencies={['password']}
                label={
                  <label className={styles.form__label}>
                    Confirmar contraseña
                  </label>
                }
                className={styles.form__input}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={fetching}
                  style={styles.button}
                  className={styles.settings__change__password__form__button}
                  type="primary"
                  htmlType="submit"
                >
                  Actualizar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Content>
  )
}

export default Settings
