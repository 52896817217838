import AboutUs from "./components/AboutUs/AboutUs";
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./components/Home/Home";
import Sign from "./components/Sign/Sign";
import FrequentQ from "./components/FrequentQ/FrequentQ";
import Profile from "./components/Profile/Profile";
import Admin from "./components/Admin/Admin";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import Gifts from "./components/Gifts/Gifts";
import Contact from "./components/Contact/Contact";
import PrivacyPolicies from "./components/PrivacyPolicies/PrivacyPolicies";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import RewardForm from "./components/Admin/Rewards/RewardForm";
import { useSelector } from "react-redux";
import ProfileForm from "./components/Profile/ProfileForm/ProfileForm";
import CloseWindow from "./components/CloseWindow";
import Participants from "./components/Admin/Surveys/Participants";
import NotSuitable from "./components/NotSuitable";
// import Info from "./components/Admin/Panelists/Info";
import RedeemPoints from "./components/ExternalSurvey/RedeemPoints";


const AdminRoute = (props) => {
  const { loggedIn, user } = useSelector((state) => state.user);
  if (loggedIn) if (user.role === "ADMIN") return <Route {...props} />;
  return <Redirect to={props.to} />;
};

const PrivateRoute = (props) => {
  const { loggedIn, user } = useSelector((state) => state.user);
  let asignProps = { ...props };
  if (user.role === "ADMIN") asignProps.to = "/admin/resumen";
  return loggedIn ? <Redirect to={asignProps.to} /> : <Route {...props} />;
};

const ProfileRoute = (props) => {
  const { loggedIn, user } = useSelector((state) => state.user);
  return loggedIn ? <Route {...props} /> : <Redirect to={props.to} />;
};

const renderSignIn = () => <Sign showSignIn />;

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/preguntas-frecuentes" component={FrequentQ} />
      <Route path="/premios" component={Gifts} />
      <Route path="/productos/detalle" component={ProductDetails} />
      <Route path="/contacto" component={Contact} />
      <Route path="/quienes-somos" component={AboutUs} />
      <Route path="/aviso-privacidad" component={PrivacyPolicies} />
      <Route path="/olvide-contraseña" component={ResetPassword} />
      <Route path="/formulario-de-perfilamiento" component={ProfileForm} />

      <Route path="/completada/:surveySid" component={CloseWindow} />
      <Route path="/not/suitable" component={NotSuitable} />

      <PrivateRoute
        path="/signin"
        component={renderSignIn}
        to="/perfil/resumen"
      />

      <PrivateRoute

        path="/registro"
        component={Sign}
        to="/perfil/resumen"
      />
      <ProfileRoute

        path="/perfil/:section"
        to="/signin"
        component={Profile}
      />
      <AdminRoute path="/admin/:section" to="/signin" component={Admin} />
      {/* <AdminRoute exact path="/admin/encuestas/:id/participantes" to="/signin" component={Participants} /> */}
      {/* <Route path="/admin" component={Panelists} /> */}
      <AdminRoute
        path="/admin/premios/:id"
        to="/signin"
        component={RewardForm}
      />

      {/* Encuestas externas */}
      <Route path="/externa/:token" component={RedeemPoints} />

      {/* Atrapa todo */}
      <Redirect path="*" to="/perfil/mi-perfil" />
    </Switch>
  );
}
