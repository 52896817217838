import axios from "axios";
import constants from '../constanst.json'
import { getSelfDataAction } from "./userDuck";
import { message, notification } from 'antd';

const API_URL = process.env.REACT_APP_BACKEND_URL || constants.backendURL
// const API_URL = "http://localhost:8000"

let initialData = {
  fetching: false,
  list: [],
  exchanged: []
};

let REDEEM = "REDEEM";
let REDEEM_SUCCESS = "REDEEM_SUCCESS";
let REDEEM_ERROR = "REDEEM_ERROR";

let GET_REWARDS = "GET_REWARDS";
let GET_REWARDS_SUCCESS = "GET_REWARDS_SUCCESS";
let GET_REWARDS_ERROR = "GET_REWARDS_ERROR";

let GET_EXCHANGED = "GET_EXCHANGED";
let GET_EXCHANGED_SUCCESS = "GET_EXCHANGED_SUCCESS";
let GET_EXCHANGED_ERROR = "GET_EXCHANGED_ERROR";

let UPDATE_PAYPAL_STATUS = "UPDATE_PAYPAL_STATUS";
let UPDATE_PAYPAL_STATUS_SUCCESS = "UPDATE_PAYPAL_STATUS_SUCCESS";
let UPDATE_PAYPAL_STATUS_ERROR = "UPDATE_PAYPAL_STATUS_ERROR";

export default function reducer(state = initialData, action) {
  switch (action.type) {
    case GET_EXCHANGED:
      return { ...state, fetching: true, error: null };
    case GET_EXCHANGED_ERROR:
      return { ...state, fetching: false, error: action.payload };
    case GET_EXCHANGED_SUCCESS:
      return { ...state, fetching: false, exchanged: action.payload };

    case UPDATE_PAYPAL_STATUS:
    case REDEEM:
      return { ...state, fetching: true, error: null };
    case UPDATE_PAYPAL_STATUS_ERROR:
    case REDEEM_ERROR:
      return { ...state, fetching: false, error: action.payload };
    case UPDATE_PAYPAL_STATUS_SUCCESS:
    case REDEEM_SUCCESS:
      return { ...state, fetching: false };

    case GET_REWARDS:
      return { ...state, fetching: true, error: null };
    case GET_REWARDS_ERROR:
      return { ...state, fetching: false, error: action.payload };
    case GET_REWARDS_SUCCESS:
      return { ...state, fetching: false, list: action.payload };

    default:
      return state;
  }
}

export const updatePaypalStatusAction = (id) => (dispatch, getState) => {
  dispatch({ type: UPDATE_PAYPAL_STATUS })
  const { token } = getState().user
  return axios.patch(`${API_URL}/rewards/updatePaypalStatus/${id}`, {}, { headers: { Authorization: token } })
    .then(result => {
      dispatch({ type: UPDATE_PAYPAL_STATUS_SUCCESS, payload: result.data })
      message.success("Estado actualizado con éxito");
      dispatch(getPaypalRewards())
      return result.data
    })
    .catch(err => {
      message.error("No se pudo actualizar, " + err.response?.data);
      dispatch({ type: UPDATE_PAYPAL_STATUS_ERROR, payload: err })
    })
}

export const getPaypalRewards = () => (dispatch, getState) => {
  dispatch({ type: GET_EXCHANGED })
  const { token } = getState().user
  return axios.get(`${API_URL}/rewards?paypal=true`, { headers: { Authorization: token } })
    .then(result => {
      dispatch({ type: GET_EXCHANGED_SUCCESS, payload: result.data })
      return result.data
    })
    .catch(err => dispatch({ type: GET_EXCHANGED_ERROR, payload: err }))
}

export const getExchangedRewards = () => (dispatch, getState) => {
  dispatch({ type: GET_EXCHANGED })
  const { token } = getState().user
  return axios.get(`${API_URL}/rewards`, { headers: { Authorization: token } })
    .then(result => {
      dispatch({ type: GET_EXCHANGED_SUCCESS, payload: result.data })
      return result.data
    })
    .catch(err => dispatch({ type: GET_EXCHANGED_ERROR, payload: err }))
}

export const redeemPaypalAction = (reward) => (dispatch, getState) => {
  dispatch({ type: REDEEM })
  const { token } = getState().user
  return axios.post(`${API_URL}/rewards/paypal`, reward, { headers: { Authorization: token } })
    .then(({ data }) => {
      dispatch(getSelfDataAction())
      dispatch({ type: REDEEM_SUCCESS })
      // console.log(data)
      // message.success('Tu premio ha sido canjeado, revisa tu email.');
      notification.open({
        message: 'Tu premio ha sido canjeado',
        duration: 0,
        description:
          `
          Te hemos enviado un correo electronico con los detalles de tu canje.
          Tu número de referencia es ${data.referenceOrderID}
          `,
      });
      return data
    })
    .catch(err => {
      dispatch({ type: REDEEM_ERROR, payload: err })
      message.error("Lo sentimos, ocurrió un error, " + err.response?.data);
    })
}

export const redeemRewardAction = (reward) => (dispatch, getState) => {
  dispatch({ type: REDEEM })
  const { token } = getState().user
  return axios.post(`${API_URL}/rewards/redeem`, reward, { headers: { Authorization: token } })
    .then(({ data }) => {
      dispatch(getSelfDataAction())
      dispatch({ type: REDEEM_SUCCESS })
      // console.log(data)
      // message.success('Tu premio ha sido canjeado, revisa tu email.');
      notification.open({
        message: 'Tu premio ha sido canjeado',
        duration: 0,
        description:
          `
          Te hemos enviado un correo electronico con los detalles de tu canje.
          Tu número de referencia es ${data.referenceOrderID}
          `,
      });
      return data
    })
    .catch(err => {
      dispatch({ type: REDEEM_ERROR, payload: err })
      message.error("Lo sentimos, ocurrió un error, " + err.response?.data);
    })
}

export const getRewards = () => (dispatch, getState) => {
  let pisi = "Basic " + Buffer.from(constants.username + ":" + constants.password).toString('base64')
  dispatch({ type: GET_REWARDS })
  return axios.get(`${API_URL}/rewards/catalog`)
    .then(res => {
      dispatch({ type: GET_REWARDS_SUCCESS, payload: res.data.brands })
      return res.data
    })
    .catch(err => dispatch({ type: GET_REWARDS_ERROR, payload: err }))
}
