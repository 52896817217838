import React, { useState } from 'react';
import { Tabs, Layout, Button, Avatar, Modal, Form, Input, Tag } from 'antd';
import styles from './info.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment'
import { patchUserAction } from '../../../redux/adminDuck';

// const user = {
//     imgURL: 'https://via.placeholder.com/600/92c952',
//     nombre: 'Roberto Gomez Bolaños',
//     email: 'ahbahb@dfsd.com',
//     genero: 'Punk',
//     estado: 'soltero',
//     edad: 27,
//     nivel: "intermedio",
//     completadas: 12,
//     pendientes: 2,
//     puntos: 11928,
// }

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};
const { TabPane } = Tabs;
const { Content } = Layout;

const Info = () => {
    const dispatch = useDispatch()
    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const { currentUser } = useSelector(({ admin }) => admin)
    const history = useHistory()
    const [user, setUser] = useState({})
    React.useEffect(() => {
        if (!Object.keys(currentUser).length) {
            history.goBack()
        }

    }, [])

    React.useEffect(() => setUser(currentUser), [currentUser])

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setVisible(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setVisible(false);
        setUser(currentUser)
    };

    const onChange = ({ target: { name, value } }) => setUser({ ...user, [name]: value })
    const sendUpdate = () => {
        setVisible(false)
        const body = {
            displayName: user.displayName,
            email: user.email,
            points: user.puntos,
        }
        dispatch(patchUserAction(user._id, body))
            .then(res => {
                if (res) {
                    history.goBack()
                }
            })
    }

    return (
        <Content style={{ background: "#f9f9f9", padding: "0 3%", }}>
            <div className={styles.info__profile__container__top}>
                <div className={styles.info__image__container}>
                    <Avatar src={user.photoURL} size={100} />
                </div>
                <div className={styles.info__profile__text}>
                    <h3 className={styles.info__profile__title}>{user.displayName}</h3>
                    <p>Usuario de Collectum Datos Panel desde <strong>{moment(user.createdAt).format('ll')}</strong></p>
                </div>

            </div>
            <div className={styles.info__profile}>
                <Tabs
                    defaultActiveKey="1"
                    size="large"
                    style={{
                        fontFamily: "'ABeeZee',sans-serif",
                        fontSize: " 18px",
                        color: "#323232",
                        marginTop: "-15px",
                    }}
                >
                    <TabPane tab="Información" key="1">
                        <div className={styles.tab__information}>
                            <div className={styles.tab__information__container}>
                                <div className={styles.tab__information__general}>
                                    <div className={styles.tab__information__general__header}>
                                        <p className={styles.tab__information__general__header__title}>Informacion general</p>
                                        <Button shape="round" onClick={showModal} style={{ margin: 0 }}>Editar</Button>
                                        <Modal
                                            title="Editar panelista"
                                            visible={visible}
                                            onOk={handleOk}
                                            confirmLoading={confirmLoading}
                                            onCancel={handleCancel}
                                            footer={[<Button key="back" onClick={handleCancel}>
                                                Cancelar
                                            </Button>, <Button type="primary" key="ok" onClick={sendUpdate}>
                                                Guardar
                                            </Button>]}
                                        >
                                            <p>Actualiza la información del panelista.</p>
                                            <div>
                                                <label for="displayName">Nombre</label>
                                                <Input name="displayName" onChange={onChange} value={user.displayName} />
                                                <br />
                                                <br />
                                                <label for="email">Email</label>
                                                <Input name="email" onChange={onChange} value={user.email} />
                                                <br />
                                                <br />
                                                <label for="puntos">Puntos</label>
                                                <Input name="puntos" onChange={onChange} value={user.puntos} />
                                            </div>


                                        </Modal>
                                    </div>
                                    <div className={styles.tab__information__general__body}>
                                        <div className={styles.tab__information__general__type}>
                                            <p>Nombre</p>
                                            <p>E-mail</p>
                                            <p>Fecha de nacimiento</p>
                                            <p>Estado</p>
                                            <p>Genero</p>
                                        </div>
                                        <div className={styles.tab__information__general__data}>
                                            <p>{user.displayName}</p>
                                            <p>{user.email}</p>
                                            <p>{moment(user.birthDate).format('ll')}</p>
                                            <p>{user.state}</p>
                                            <p>{user.gender}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.tab__summary__collectum}>
                                    <div className={styles.tab__information__general__header}>
                                        <p className={styles.tab__information__general__header__title}>Resumen de collectum datos</p>
                                    </div>
                                    <div className={styles.tab__information__general__body}>
                                        <div className={styles.tab__information__general__type}>
                                            <p>Nivel</p>
                                            {/* <p>Encuestas Completas</p> */}
                                            {/* <p>Encuestas Pendientes</p> */}
                                            <p>Puntos</p>
                                            <p>Grupos</p>
                                        </div>
                                        <div className={styles.tab__information__general__data}>
                                            <p>{user.level}</p>
                                            {/* <p>{user.completed}</p> */}
                                            {/* <p>{user.pendientes}</p> */}
                                            <p>{user.puntos}</p>
                                            <p>{user.groups?.map(g => <Tag key={g.title}>{g.title}</Tag>)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </Content>
    )
}

export default Info
