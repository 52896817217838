import React from 'react'
import { Select } from "antd";

const { Option } = Select;


export default function StateSelect({ onChange }) {
    if (onChange) return (
        <Select style={{ width: "100%" }} onChange={onChange} placeholder="Estado">
            <Option value="otro">Ninguno</Option>
            <Option value="CDMX">Ciudad de México</Option>
            <Option value="AGS">Aguascalientes</Option>
            <Option value="BCN">Baja California</Option>
            <Option value="BCS">Baja California Sur</Option>
            <Option value="CAM">Campeche</Option>
            <Option value="CHP">Chiapas</Option>
            <Option value="CHI">Chihuahua</Option>
            <Option value="COA">Coahuila</Option>
            <Option value="COL">Colima</Option>
            <Option value="DUR">Durango</Option>
            <Option value="GTO">Guanajuato</Option>
            <Option value="GRO">Guerrero</Option>
            <Option value="HGO">Hidalgo</Option>
            <Option value="JAL">Jalisco</Option>
            <Option value="MEX">Estado de M&eacute;xico</Option>
            <Option value="MIC">Michoac&aacute;n</Option>
            <Option value="MOR">Morelos</Option>
            <Option value="NAY">Nayarit</Option>
            <Option value="NLE">Nuevo Le&oacute;n</Option>
            <Option value="OAX">Oaxaca</Option>
            <Option value="PUE">Puebla</Option>
            <Option value="QRO">Quer&eacute;taro</Option>
            <Option value="ROO">Quintana Roo</Option>
            <Option value="SLP">San Luis Potos&iacute;</Option>
            <Option value="SIN">Sinaloa</Option>
            <Option value="SON">Sonora</Option>
            <Option value="TAB">Tabasco</Option>
            <Option value="TAM">Tamaulipas</Option>
            <Option value="TLX">Tlaxcala</Option>
            <Option value="VER">Veracruz</Option>
            <Option value="YUC">Yucat&aacute;n</Option>
            <Option value="ZAC">Zacatecas</Option>

        </Select>
    )
    return (<Select placeholder="Estado">
        <Option value="otro">Ninguno</Option>
        <Option value="CDMX">Ciudad de México</Option>
        <Option value="AGS">Aguascalientes</Option>
        <Option value="BCN">Baja California</Option>
        <Option value="BCS">Baja California Sur</Option>
        <Option value="CAM">Campeche</Option>
        <Option value="CHP">Chiapas</Option>
        <Option value="CHI">Chihuahua</Option>
        <Option value="COA">Coahuila</Option>
        <Option value="COL">Colima</Option>
        <Option value="DUR">Durango</Option>
        <Option value="GTO">Guanajuato</Option>
        <Option value="GRO">Guerrero</Option>
        <Option value="HGO">Hidalgo</Option>
        <Option value="JAL">Jalisco</Option>
        <Option value="MEX">Estado de M&eacute;xico</Option>
        <Option value="MIC">Michoac&aacute;n</Option>
        <Option value="MOR">Morelos</Option>
        <Option value="NAY">Nayarit</Option>
        <Option value="NLE">Nuevo Le&oacute;n</Option>
        <Option value="OAX">Oaxaca</Option>
        <Option value="PUE">Puebla</Option>
        <Option value="QRO">Quer&eacute;taro</Option>
        <Option value="ROO">Quintana Roo</Option>
        <Option value="SLP">San Luis Potos&iacute;</Option>
        <Option value="SIN">Sinaloa</Option>
        <Option value="SON">Sonora</Option>
        <Option value="TAB">Tabasco</Option>
        <Option value="TAM">Tamaulipas</Option>
        <Option value="TLX">Tlaxcala</Option>
        <Option value="VER">Veracruz</Option>
        <Option value="YUC">Yucat&aacute;n</Option>
        <Option value="ZAC">Zacatecas</Option>

    </Select>)
}