import React from "react";
import photo1 from "../../assets/laptop-garden.png";
import photo2 from "../../assets/pexels-photo-2.png";
import circle from "../../assets/Recurso10.png";
import point from "../../assets/Capa2.png";

export default function Step({ left, number = "02." }) {
  if (left) {
    return (
      <div className="steps__section">
        <div className="steps__section__text">
          <div className="steps__section__number">{number}</div>
          <div className="steps__section__tittle">
            Comparte tus experiencias
          </div>
          <p className="steps__section__content">
            Comparte tu opinión contestando encuestas sobre productos y
            servicios.
          </p>
        </div>
        <div className="steps__section__image" id="steps__section__image__01">
          <img
            src={photo1}
            alt="teamPhoto"
            className="steps__section__img"
            id="img-1"
          />
          <img
            src={circle}
            alt="bgCircle"
            className="steps__section__img__bg"
            id="bg-1"
          />
        </div>
      </div>
    );
  }
  return (
    <div className="steps__section__02">
      <div className="steps__section__text">
        <div className="steps__section__number">{number}</div>
        <div className="steps__section__tittle">
          Gana puntos por cada encuesta
        </div>
        <p className="steps__section__content">
          Gana puntos por cada encuesta que completes en Collectum Datos.
        </p>
      </div>
      <div className="steps__section__image" id="steps__section__image__02">
        <img
          src={photo2}
          alt="teamPhoto"
          className="steps__section__img"
          id="img-2"
        />
        <img
          src={point}
          alt="bgPoint"
          className="steps__section__img__bg"
          id="bg-2"
        />
      </div>
    </div>
  );
}
