import React from "react";
import styles from './Gifts.module.css';
import stuff from "../../assets/pexels-photo-4.png"

const GiftsHeader = () => {
    return (
        <section style={{ backgroundImage: `url(${stuff})` }} className={styles.header}>
            <div className={styles.header_background}>
                <p className={styles.header_text}>
                    ¿Qué premios puedes ganar?
               </p>
            </div>
        </section >
    );
};
export default GiftsHeader;