import React from "react";
import { NavLink, Link } from "react-router-dom";

import "../Home/Home.css";

import Logo from "../../assets/logo-panel.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

const HomeFooter = () => {
  return (
    <div>
      <footer className="homeFooter">
        <div className="homeFooter--section1">
        <Link to="/home">
            <img src={Logo} alt="Logo" className="logo_image" />
          </Link>
        </div>

        <div className="homeFooter--section2">

          <NavLink
            activeClassName="homeFooter__paraph"
            className="homeFooter__paraph"
            to="/preguntas-frecuentes"
          >
            Preguntas Frecuentes
          </NavLink>
          <NavLink
            activeClassName="homeFooter__paraph"
            className="homeFooter__paraph"
            to="/contacto"
          >
            Contacto
          </NavLink>
        </div>

        <section className="homeFooter--section3">
          <p className="homeFooter__title">Síguenos</p>
          <div className="homeFooter--icon">
            <a href="https://www.facebook.com/Collectum-Datos-Panel-102308841545082/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon
                  className="fab"
                  icon={faFacebookF}
              />
            </a>
            
          <a href="https://www.instagram.com/collectumdatospanel/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon
              className="fab"
              style={{ color: "#595959" }}
              icon={faInstagram}
              />
          </a>
          </div>
        </section>
      </footer>

      <div className="homeFooter--section4">
        <p className="section4__p">@2020 Todos los derechos reservados</p>
        <Link
          activeClassName="section4__p"
          className="section4__p"
          to="/aviso-privacidad"
        >
          Aviso de privacidad
        </Link>
      </div>
    </div>
  );
};

export default HomeFooter;
