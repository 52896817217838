import { useForm } from 'antd/lib/form/Form'
import React, { useRef, useEffect } from 'react'

const Disclaimer = ({ width, text }) => {
    let p = useRef()

    useEffect(() => {
        if (text && p.current) p.current.innerHTML = text
    }, [text, p])
    return (
        <>
            <p ref={p} style={{ margin: "0 auto", width: width ? width : "60%", fontWeight: "bold" }}>
                {text ? null : "Los comercios aquí representados no son patrocinadores de las recompensas ni están afiliadas a este programa. Los logotipos y marcas registradas son propiedad de cada una de las compañías o de sus empresas afiliadas. Por favor visite la página de internet de cada empresa para términos y condiciones adicionales."}
            </p>
            <br />
        </>
    );
}

export default Disclaimer;