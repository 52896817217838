import React, { useState } from "react";
import { Form, Input, Select, Button, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import styles from "./RewardForm.module.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import { UpdateProduct } from "../../../redux/rewardsDuck";
import { useParams } from "react-router-dom";

const UpdateProduct = () => {}

const PrintReward = ({ reward = {}, setEditingTrue, isEditing }) => {
  const { id } = useParams();
  const { Option } = Select;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [modal, handleModal] = useState(false);
  const [FileList, setFileList] = useState([]);
  form.setFieldsValue({ ...reward });
  const history = useHistory();
  const layout = {
    labelCol: {
      xs: { span: 7 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  /* function removePic(index) {
    let arr = reward.pics;
    let newArray = arr.splice(index, 1);
    console.log(newArray);
    setimagesArray(newArray);
    setFileList(newArray)
  }*/
  const closeModal = () => handleModal(false);
  const imageprew = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      let input = e.target.files[i];
      FileList.push(input);
      setFileList([...FileList], input);
      if (FileList.length <= 4) {
        setFileList([FileList]);
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[i]);
        reader.onload = function () {
          let preview = document.getElementById("preview"),
            image = document.createElement("img");
          image.classList.add(styles.imageprew);
          image.src = reader.result;
          preview.append(image);
        }
      } else {
        handleModal(true);
      }
    }
  };
  const editReward = async (form) => {
    FileList.length = 4;
    delete form.photos;
    let updatereward = await dispatch(UpdateProduct(form, id, FileList));
    if (updatereward === true) {
      history.push("/admin/premios");
    }
  };

  return isEditing ? (
    <div className={styles.form__newReward}>
      <p className={styles.subtitle}>
        Completa la información para agregar un premio.
      </p>
      <Form
        {...layout}
        form={form}
        name="Rewards"
        onFinish={editReward}
        getFieldsValue={form.isFieldsTouched(true) ? { ...form } : { reward }}
        style={{ padding: "3%" }}
        onChange={form.setFieldsValue({ ...form })}
      >
        <Form.Item
          name="title"
          style={{ fontFamily: "AvenirBook" }}
          label="Nombre"
        >
          <Input
            style={{ background: "#f9f9f9" }}
            placeholder="Maximo 30 caracteres"
          />
        </Form.Item>
        <Form.Item name="body" label="Descripción:">
          <TextArea
            style={{ background: "#f9f9f9" }}
            placeholder="Maximo 250 caracteres"
          />
        </Form.Item>
        <Form.Item name="price" label="Puntos:">
          <Input
            style={{ background: "#f9f9f9" }}
            placeholder="Escribe el precio"
          />
        </Form.Item>
        <Form.Item
          name="category"
          style={{ background: "#f9f9f9" }}
          label="Categoría"
        >
          <Select
            style={{ width: "100%", background: "#f9f9f9" }}
            placeholder="Selecciona una categoría"
            optionFilterProp="children"
          >
            <Option value="Hogar">Hogar</Option>
            <Option value="Tecnología">Tecnología</Option>
            <Option value="Tarjetas de regalo">Tarjetas de regalo</Option>
            <Option value="Entretenimiento">Entretenimiento</Option>
            <Option value="Moda y belleza">Moda y belleza</Option>
            <Option value="Otros">Otros</Option>
          </Select>
        </Form.Item>

        {reward.category === "Tarjetas de regalo" ? (
          <div>
            <Form.Item
              name="subCategory"
              label={
                <label className={styles.form__label}> Subcategoría </label>
              }
              style={{ width: "100%" }}
            >
              <Select>
                <Option value="netflix">Netflix</Option>
                <Option value="Blim">Blim</Option>
                <Option value="amazon">Amazon</Option>
                <Option value="playStore">Play store</Option>
              </Select>
            </Form.Item>
            {/*  Array */}
            <Form.List name="cardCodes">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <Form.Item
                        label={`Tarjeta ${index + 1}`}
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                          style={{ width: "100%" }}
                        >
                          <Input style={{ background: "#f9f9f9" }} />
                        </Form.Item>
                        {fields.length >= 1 ? (
                          <CloseOutlined
                            className="dynamic-delete-button"
                            style={{
                              position: "absolute",
                              zIndex: "1",
                              top: "5.3px",
                              fontSize: "20px",
                              left: "93%",
                            }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <div className={styles.contentaddTarjet}>
                        <input
                          onClick={() => {
                            add();
                          }}
                          type="button"
                          value="Agregar otra tarjeta"
                          className={styles.addTarjet}
                        />
                      </div>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </div>
        ) : (
            <Form.Item
              name="stock"
              label={<label className={styles.form__label}> Piezas </label>}
            >
              <Input style={{ background: "#f9f9f9" }} placeholder="00" />
            </Form.Item>
          )}

        <Form.Item name="photos" label="Fotografías">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <div className={styles.imagePreview} id="preview">
            </div>
            <div>
              <input
                id="imagebutton"
                className={styles["custom-file-input"]}
                type="file"
                onChange={imageprew}
                multiple="true"
              />
            </div>
          </div>
        </Form.Item>

        <div className={styles.containerButton}>
          <Button
            htmlType="button"
            style={{
              background: "#f9f9f9",
              border: "none",
              fontSize: "18px",
              textAlign: "center",
              marginRight: "40px",
            }}
            className={styles.form__cancel}
            onClick={() => history.push("/admin/premios")}
          >
            Cancelar
          </Button>
          <Button
            style={{
              background: "#95BF48",
              color: "white",
              border: "1px solid #95BF48",
              borderRadius: "20px",
              width: "150px",
            }}
            className={styles.form__save}
            type="primary"
            htmlType="submit"
          >
            Guardar
          </Button>
        </div>
      </Form>
      <Modal
        visible={modal}
        onCancel={closeModal}
        width="65%"
        footer={null}
        style={{ minWidth: "290px" }}
      >
        <div className={styles.modal__title}>Eliminar premio</div>
        <div className={styles.modal__content}>
          ¿Estas seguro de eliminar este premio? Se eliminará
          automáticamente de la lista y nadie podrá canjearlo.
                </div>
        <div className={styles.modal__footer}>
          <button
            onClick={closeModal}
            className={styles["modal__button--cancel"]}
          >
            Ok
                  </button>
        </div>
      </Modal>
    </div>
  ) : (
      <div className={styles.printReward}>
        <div className={styles.reward__subtitle}>Detalle del premio</div>
        <div className={styles["printReward--row"]}>
          <div className={styles.row__first}>Nombre:</div>
          <div className={styles.row__second}>{reward.title}</div>
        </div>
        <div className={styles["printReward--row"]}>
          <div className={styles.row__first_d}>Descripción:</div>
          <div className={styles.row__second_d}>{reward.body}</div>
        </div>
        <div className={styles["printReward--row"]}>
          <div className={styles.row__first_p}>Puntos:</div>
          <div className={styles.row__second_p}>{reward.price}</div>
        </div>
        <div className={styles["printReward--row"]}>
          <div className={styles.row__first_c}>Categoría:</div>
          <div className={styles.row__second_c}>{reward.category}</div>
        </div>
        <div className={styles["printReward--row"]}>
          <div className={styles.row__first_pieces}>Piezas:</div>
          <div className={styles.row__second_pieces}>
            {reward.stock ? reward.stock : 1}
          </div>
        </div>
        <div className={styles["printReward--row--images"]}>
          <div className={styles.row__first_f}>Fotografias:</div>
          <div className={styles.row__second_f}>
            {reward.pics &&
              reward.pics.map((item, index) => (
                <img
                  key={index}
                  src={item}
                  alt={item}
                  className={styles.second__image}
                />
              ))}
          </div>
        </div>
        <div className={styles["editButton-container"]}>
          <button onClick={setEditingTrue} className={styles.editButton}>
            Editar
        </button>
        </div>
      </div>
    );
};
export default PrintReward;
