import axios from 'axios'
import { notification } from 'antd'
import { getPanelistsAction } from './userDuck'

const API_URL = process.env.REACT_APP_BACKEND_URL

function getBasicOptions(method = 'POST', path, token) {
  let url = `${process.env.REACT_APP_BACKEND_URL}/admin${path}`
  return {
    url,
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  }
}

const initialState = {
  summary: {},
  users: [],
  titles: [],
  activeTitles: [
    // 'socialNetworks',
    // 'socialClass',
    // 'age',
    'state',
    'gender',
    'groups',
    // 'pantry',
    // 'pantryBrands',
  ],
  titleValues: {
    socialNetworks: [
      'facebook',
      'instagram',
      'twitter',
      'tiktok',
      'whatsapp',
      'youtube',
      'telegram',
      'snapchat',
      'twitch',
    ],
    socialClass: ['A/B', 'C+', 'C', 'C-', 'D+', 'D', 'E'],
    gender: ['male', 'female'],
    pantry: ['always', 'most', 'sometimes', 'notResponsible'],
    pantryBrands: ['decide', 'influence', 'notDecide'],
  },
  currentSurvey: {},
  fetching: false,
  page: 1,
  limit: 50,
  pages: 1,
  total: 0,
  items: [],
  currentUser: {},
  groups: []
}
const GET_SURVEY_PARTICIPANTS = 'GET_SURVEY_PARTICIPANTS'
const GET_SURVEY_PARTICIPANTS_ERROR = 'GET_SURVEY_PARTICIPANTS_ERROR'
const GET_SURVEY_PARTICIPANTS_SUCCESS = 'GET_SURVEY_PARTICIPANTS_SUCCESS'

const GET_USER_WITH_PROFILES = 'GET_USER_WITH_PROFILES'
const GET_USER_WITH_PROFILES_ERROR = 'GET_USER_WITH_PROFILES_ERROR'
const GET_USER_WITH_PROFILES_SUCCESS = 'GET_USER_WITH_PROFILES_SUCCESS'

const GET_USER_WITHOUT_PROFILES = 'GET_USER_WITHOUT_PROFILES'
const GET_USER_WITHOUT_PROFILES_ERROR = 'GET_USER_WITHOUT_PROFILES_ERROR'
const GET_USER_WITHOUT_PROFILES_SUCCESS = 'GET_USER_WITHOUT_PROFILES_SUCCESS'

const GET_ALL_USERS = 'GET_ALL_USERS'
const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR'
const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'

const GET_SUMMARY = 'GET_SUMMARY'
const GET_SUMMARY_ERROR = 'GET_SUMMARY_ERROR'
const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS'

const DEACTIVATE_USER = 'DEACTIVATE_USER'
const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS'
const DEACTIVATE_USER_ERROR = 'DEACTIVATE_USER_ERROR'
// const INJECT_UPDATED_USER = 'INJECT_UPDATED_USER'

const PATCH_USER = 'PATCH_USER'
const PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS'
const PATCH_USER_ERROR = 'PATCH_USER_ERROR'

const SET_CURRENT_USER = 'SET_CURRENT_USER'

const GET_GROUPS = 'GET_GROUPS'
const GET_GROUPS_ERROR = 'GET_GROUPS_ERROR'
const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS'

const SET_USERS_TO_GROUPS = 'SET_USERS_TO_GROUPS'
const SET_USERS_TO_GROUPS_ERROR = 'SET_USERS_TO_GROUPS_ERROR'
const SET_USERS_TO_GROUPS_SUCCESS = 'SET_USERS_TO_GROUPS_SUCCESS'

const GET_NOT_ENROLLED_USERS = 'GET_NOT_ENROLLED_USERS'
const GET_NOT_ENROLLED_USERS_ERROR = 'GET_NOT_ENROLLED_USERS_ERROR'
const GET_NOT_ENROLLED_USERS_SUCCESS = 'GET_NOT_ENROLLED_USERS_SUCCESS'

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS_TO_GROUPS_SUCCESS:
      return { ...state, fetching: false }
    case GET_GROUPS_SUCCESS:
      return { ...state, groups: action.payload, fetching: false }

    case SET_CURRENT_USER:
      return { ...state, currentUser: action.payload }

    case PATCH_USER_SUCCESS:
      return { ...state, fetching: false, currentUser: action.payload }
    case GET_SURVEY_PARTICIPANTS_SUCCESS:
      return { ...state, fetching: false, currentSurvey: action.payload }
    case GET_NOT_ENROLLED_USERS_SUCCESS:
      return { ...state, fetching: false, ...action.payload }

    case GET_NOT_ENROLLED_USERS:
    case SET_USERS_TO_GROUPS:
    case GET_GROUPS:
    case PATCH_USER:
    case GET_SURVEY_PARTICIPANTS:
      return { ...state, fetching: true }

    case GET_NOT_ENROLLED_USERS_ERROR:
    case SET_USERS_TO_GROUPS_ERROR:
    case GET_GROUPS_ERROR:
    case PATCH_USER_ERROR:
    case GET_SURVEY_PARTICIPANTS_ERROR:
      return { ...state, fetching: false, error: action.payload }

    case DEACTIVATE_USER:
      return { ...state, fetching: true }
    case DEACTIVATE_USER_SUCCESS:
      return { ...state, fetching: false }
    case DEACTIVATE_USER_ERROR:
      return { ...state, fetching: false, error: action.payload }
    // case INJECT_UPDATED_USER:
    //   return {
    //     ...state, currentUser: action.payload
    //     // [...state.users.map(u => u._id === action.payload._id ? action.payload : u)] 

    //   }

    case GET_SUMMARY:
      return { ...state, fetching: true }
    case GET_SUMMARY_SUCCESS:
      return { ...state, fetching: false, summary: action.payload }
    case GET_SUMMARY_ERROR:
      return { ...state, fetching: false, error: action.payload }

    case 'UPDATE_TITLE_VALUES':
      return { ...state, titleValues: action.payload }
    case 'UPDATE_TITLES':
      return { ...state, titles: action.payload }

    case GET_USER_WITHOUT_PROFILES_SUCCESS:
    case GET_USER_WITH_PROFILES_SUCCESS:
      return { ...state, fetching: false, ...action.payload }

    case GET_ALL_USERS_SUCCESS:
      return { ...state, fetching: false, ...action.payload }

    case GET_ALL_USERS:
    case GET_USER_WITHOUT_PROFILES:
    case GET_USER_WITH_PROFILES:
      return { ...state, fetching: true }

    case GET_ALL_USERS_ERROR:
    case GET_USER_WITHOUT_PROFILES_ERROR:
    case GET_USER_WITH_PROFILES_ERROR:
      return { ...state, fetching: false, error: action.payload }
    default:
      return { ...state }
  }
}

//UTILS

function getRidOfNonProfileUsers(object) {
  const items = object.items.filter((u) => u.profile && u.profile.length)
  return { ...object, items }
}

// THUNKS
// --------------- CRUD Participantes de la encuesta ---------------

//PATCH
export const patchUserAction = (id, body) => (
  dispatch,
  getState,
) => {
  // console.log("enviando: ", id, body)
  dispatch({ type: PATCH_USER })
  const options = getBasicOptions('PATCH', `/users/${id}`, getState().user.token)
  options.data = { ...body }
  return axios(options)
    .then(({ data }) => {
      dispatch({ type: PATCH_USER_SUCCESS, payload: data })
      dispatch(getPanelistsAction())
      notification.success({
        message: `Éxito`,
        description: 'Se ha actualizado al panelista exitosamente',
        placement: 'bottomLeft',
      })
      return true
    })
    .catch((e) => {
      notification.error({
        message: `Error al conectar con el servidor`,
        description: e.response?.data,
        placement: 'bottomLeft',
      })
      dispatch({ type: PATCH_USER_ERROR, payload: e.message })
      return false
    })
}

export const setUserToGroupsAction = (body) => (
  dispatch,
  getState,
) => {
  // console.log("enviando: ", id, body)
  dispatch({ type: SET_USERS_TO_GROUPS })
  const { token } = getState().user
  return axios.post(API_URL + `/groups/add`, body, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ data }) => {
      dispatch({ type: SET_USERS_TO_GROUPS_SUCCESS })
      dispatch(getGroupsAction())
      notification.success({
        message: `Éxito`,
        description: 'Se han actualizado los panelistas exitosamente',
        placement: 'bottomLeft',
      })
      return true
    })
    .catch((e) => {
      notification.error({
        message: `Error al conectar con el servidor`,
        description: e.response?.data,
        placement: 'bottomLeft',
      })
      dispatch({ type: SET_USERS_TO_GROUPS_ERROR, payload: e.message })
      return false
    })
}

//GET
export const getGroupsAction = () => (dispatch, getState) => {
  dispatch({
    type: GET_GROUPS,
  })
  const token = getState().user.token
  return axios
    .get(API_URL + `/groups`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_GROUPS_SUCCESS,
        payload: res.data,
      })
      return res.data
    })
    .catch((e) => {
      dispatch({
        type: GET_GROUPS_ERROR,
        payload: e.message ? e.message : 'Error de servidor',
      })
    })
}

export const getSurveyParticipants = (survey) => (dispatch, getState) => {
  dispatch({
    type: GET_SURVEY_PARTICIPANTS,
  })
  const token = getState().user.token
  return axios
    .get(API_URL + `/surveys/${survey.id}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_SURVEY_PARTICIPANTS_SUCCESS,
        payload: res.data,
      })
      return res.data
    })
    .catch((e) => {
      dispatch({
        type: GET_SURVEY_PARTICIPANTS_ERROR,
        payload: e.message ? e.message : 'Error de servidor',
      })
    })
}

// ---------------

export const deactivateUserAction = (record, deactivate) => (
  dispatch,
  getState,
) => {
  dispatch({ type: DEACTIVATE_USER })
  const options = getBasicOptions('POST', '/deactivate', getState().user.token)
  // console.log(record)
  options.data = { id: record.key, deactivate }
  return axios(options)
    .then(({ data }) => {
      dispatch({ type: DEACTIVATE_USER_SUCCESS })
      // dispatch(getPanelistsAction())
      // dispatch(getAllUsersAction())
      // dispatch({ type: INJECT_UPDATED_USER, payload: data })
      return data
    })
    .catch((e) => {
      notification.error({
        message: `Error al conectar con el servidor`,
        description: 'Algo inesperado sucedió, intenta más tarde',
        placement: 'bottomLeft',
      })
      dispatch({ type: DEACTIVATE_USER_ERROR, payload: e.message })
      return false
    })
}

export const getSummaryAction = () => (dispatch, getState) => {
  dispatch({ type: GET_SUMMARY })
  return axios(getBasicOptions('GET', '/summary', getState().user.token))
    .then((res) => {
      dispatch({ type: GET_SUMMARY_SUCCESS, payload: res.data })
      return true
    })
    .catch((e) => {
      notification.error({
        message: `Error al conectar con el servidor`,
        description: 'Algo inesperado sucedió, intenta más tarde',
        placement: 'bottomLeft',
      })
      dispatch({ type: GET_SUMMARY_ERROR, payload: e.message })
      return false
    })
}

export const getAllUsersWithProfileAction = (paginated) => (
  dispatch,
  getState,
) => {
  const { token } = getState().user
  dispatch({ type: GET_USER_WITH_PROFILES })
  return axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/admin/users`, {
      headers: { Authorization: token },
      params: {
        p: paginated && paginated.p ? paginated.p : 1,
        l: paginated && paginated.l ? paginated.l : 50,
        q: paginated && paginated.q ? paginated.q : null,
      },
    })
    .then((res) => {
      if (res.data.items)
        return dispatch({
          type: GET_USER_WITH_PROFILES_SUCCESS,
          payload: getRidOfNonProfileUsers(res.data),
        })
      dispatch({ type: GET_USER_WITH_PROFILES_SUCCESS, payload: res.data })
    })
    .catch((e) => {
      console.error(e)
      notification.error({
        message: `Error al conectar con el servidor`,
        description: 'Algo inesperado sucedió, intenta más tarde',
        placement: 'bottomLeft',
      })
      dispatch({ type: GET_USER_WITH_PROFILES_ERROR, payload: e })
    })
}

export const getUsersWithoutProfilePaginated = (paginated) => (
  dispatch,
  getState,
) => {
  const { token } = getState().user
  dispatch({ type: GET_USER_WITHOUT_PROFILES })
  return axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/admin/users`, {
      headers: { Authorization: token },
      params: {
        p: paginated && paginated.p ? paginated.p : 1,
        l: paginated && paginated.l ? paginated.l : 50,
        q: paginated && paginated.q ? paginated.q : 'no-profile',
      },
    })
    .then((res) => {
      if (res.data.items)
        return dispatch({
          type: GET_USER_WITHOUT_PROFILES_SUCCESS,
          payload: res.data,
        })
    })
    .catch((e) => {
      console.error(e)
      notification.error({
        message: `Error al conectar con el servidor`,
        description: 'Algo inesperado sucedió, intenta más tarde',
        placement: 'bottomLeft',
      })
      dispatch({ type: GET_USER_WITHOUT_PROFILES_ERROR, payload: e })
    })
}

export const setCurrentUserAction = (user) => (dispatch, getState) => {
  dispatch({ type: SET_CURRENT_USER, payload: user })
}

export const getNotEnrolledUsersPaginatedAction = (paginated, surveyId) => (dispatch, getState) => {
  const { token } = getState().user
  dispatch({ type: GET_NOT_ENROLLED_USERS })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/users/not/enrolled/${surveyId}`, {
    headers: { Authorization: token },
    params: {
      p: paginated && paginated.p ? paginated.p : 1,
      l: paginated && paginated.l ? paginated.l : 50,
      q: paginated && paginated.q ? paginated.q : null,
    },
  })
    .then((res) => {
      if (res.data.items) {
        res.data.items = res.data.items.map(u => ({ ...u, key: u._id }))
      }
      console.log("not enrolled", res.data)
      return dispatch({
        type: GET_NOT_ENROLLED_USERS_SUCCESS,
        payload: res.data,
      })
    })
    .catch((e) => {
      console.error(e)
      notification.error({
        message: `Error al conectar con el servidor`,
        description: 'Algo inesperado sucedió, intenta más tarde',
        placement: 'bottomLeft',
      })
      dispatch({ type: GET_NOT_ENROLLED_USERS_ERROR, payload: e })
    })
}

export const getAllUsersAction = (paginated) => (dispatch, getState) => {
  const { token } = getState().user
  dispatch({ type: GET_ALL_USERS })
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/users`, {
    headers: { Authorization: token },
    params: {
      p: paginated && paginated.p ? paginated.p : 1,
      l: paginated && paginated.l ? paginated.l : 200,
      q: paginated && paginated.q ? paginated.q : 'all-users',
    },
  })
    .then((res) => {
      if (res.data.items) {
        res.data.items = res.data.items.map(u => ({ ...u, key: u._id }))
      }
      return dispatch({
        type: GET_ALL_USERS_SUCCESS,
        payload: res.data,
      })
    })
    .catch((e) => {
      console.error(e)
      notification.error({
        message: `Error al conectar con el servidor`,
        description: 'Algo inesperado sucedió, intenta más tarde',
        placement: 'bottomLeft',
      })
      dispatch({ type: GET_ALL_USERS_ERROR, payload: e })
    })
}