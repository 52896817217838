import React from "react";
import "../Home/Home.css";
import photo3 from "../../assets/pexels-photo-3.png";
import zigzag1 from "../../assets/Recurso81.png";
import zigzag2 from "../../assets/Recurso82.png";
import Step from "./Step";

const HomeSteps = () => {
  return (
    <section className="steps">
      <Step
        left
        number="01."
      />
      <Step />

      <div className="steps__section">
        <div className="steps__section__text" id="steps__section__text__03">
          <div className="steps__section__number">03.</div>
          <div className="steps__section__tittle">
            Canjea tus premios
          </div>
          <p className="steps__section__content">
            Canjea premios con tus puntos ganados, el que quieras y cuando quieras.
          </p>
        </div>
        <div className="steps__section__image" id="steps__section__image__03">
          <img
            src={photo3}
            alt="teamPhoto"
            className="steps__section__img"
            id="img-3"
          />
          <img
            src={zigzag1}
            alt="bgZigzag"
            className="steps__section__img__bg"
            id="bg-3-right"
          />
          <img
            src={zigzag2}
            alt="bgZigzag"
            className="steps__section__img__bg"
            id="bg-3-left"
          />
        </div>
      </div>
    </section>
  );
};
export default HomeSteps;
