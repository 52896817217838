import React from "react";
import { Menu } from "antd";
import { useParams, Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faPoll,
  faTrophy,
  faPowerOff,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { logOutUserAction } from "../../redux/userDuck";
import { useDispatch } from "react-redux";

import styles from "./Admin.module.css";
import Logo from "../../assets/logo-panel-white.svg";

const SiderAdmin = () => {
  const { section } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const doLogOut = () => {
    dispatch(logOutUserAction());
    history.push("/");
  };
  return (
    <div>
      <Link to="/">
        <img src={Logo} alt="logo" className={styles.sider__logo} />
      </Link>
      <Menu
        mode="inline"
        defaultSelectedKeys={[section]}
        className={styles.sider__menu}
        style={{ background: "#323232", color: "#ffffff" }}
      >
        <Menu.Item
          key="resumen"
          icon={
            <FontAwesomeIcon
              icon={faChartLine}
              className={styles.sider__menu__element}
            />
          }
        >
          <Link to="/admin/resumen" className={styles.sider__menu__text}>
            Resumen
          </Link>
        </Menu.Item>
        
        <Menu.Item
          key="encuestas"
          icon={
            <FontAwesomeIcon
              icon={faPoll}
              className={styles.sider__menu__element}
            />
          }
          contai
        >
          <Link to="/admin/encuestas" className={styles.sider__menu__text}>
            Encuestas
          </Link>
        </Menu.Item>
        <Menu.Item
          key="premios"
          icon={
            <FontAwesomeIcon
              icon={faTrophy}
              className={styles.sider__menu__element}
            />
          }
        >
          <Link to="/admin/premios" className={styles.sider__menu__text}>
            Premios
          </Link>
        </Menu.Item>
        <Menu.Item
          key="panelists"
          icon={
            <FontAwesomeIcon
              icon={faUserFriends}
              className={styles.sider__menu__element}
            />
          }
        >
          <Link to="/admin/panelistas" className={styles.sider__menu__text}>
            Panelistas
          </Link>
        </Menu.Item>
        <Menu.Item
          key="5"
          icon={
            <FontAwesomeIcon
              icon={faPowerOff}
              className={styles.sider__menu__element}
            />
          }
          style={{ marginTop: "50%" }}
          onClick={doLogOut}
        >
          Cerrar sesión
        </Menu.Item>
      </Menu>
    </div>
  );
};
export default SiderAdmin;
