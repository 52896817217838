import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { redeemExternalPointsAction } from '../../redux/surveysDuck'
import image from '../../assets/points.jpg'
import oops from "../../assets/oops.svg"
import { message, Spin } from 'antd'

const ReedemPoints = ({ match, history }) => {
    const dispatch = useDispatch()
    const { title, points, success, fetching } = useSelector(({ surveys }) => surveys.externalResult)
    const { token: userToken } = useSelector(({ user }) => user)
    const token = React.useMemo(() => match.params?.token, [match])


    React.useEffect(() => {
        if (!token) {
            message.warning('Token inválido');
            return history.push(`/perfil`)
        }
        if (!userToken) {
            message.warning('Debes iniciar sesión');
            localStorage.setItem('next', `/externa/${token}`)
            return history.push(`/signin`)
        }
        dispatch(redeemExternalPointsAction(token))
    }, [])

    const closeWindow = () => window.close()


    if (fetching) { return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><Spin /></div> }
    if (success) {
        return (
            <div style={{ width: "100%", padding: "5% 10%", textAlign: "center" }}>
                <h2>Se te han añadido tus puntos correctamente</h2>
                <p>{title}</p>
                <img src={image} alt="success-image" style={{ width: "280px" }} />
                <br />
                <button style={{ backgroundColor: "#95BF48", border: "none", color: "white", padding: "8px 16px", borderRadius: "20px", cursor: "pointer" }}
                    onClick={closeWindow}>
                    {`¡Has ganado ${points} puntos!`}
                </button>
            </div>
        )
    }
    if (!success) {
        return (
            <div style={{ width: "100%", padding: "5% 10%", textAlign: "center" }}>
                <h2>¡Gracias por participar!</h2>
                <p>{title} </p>
                <p style={{ marginTop: 50 }} />
                <a href="http://www.collectumdata.com.mx/perfil" style={{ color: "#2d9cdb", cursor: "pointer" }}
                    onClick={closeWindow}>
                    www.collectumdata.com.mx
            </a>
                <p style={{ marginTop: 50 }} />
                <img src={oops} alt="success-image" style={{ width: "280px" }} />
            </div>
        )
    }

}

export default ReedemPoints