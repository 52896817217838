import React from "react";
import HomeNavbar from "./HomeNavbar";
import HomeContainer from "./HomeContainer";
import HomeUnete from "./HomeUnete"
import HomeSteps from './HomeSteps';
import HomeFooter from './HomeFooter'
import HomeCard from "./HomeCard";


const Home = () => {

  return (
    <div className="home">
      <div className="main-home-navbar">
        <HomeNavbar />
        <HomeContainer />
      </div>
      <HomeUnete />
      <HomeSteps />
      <HomeCard />
      <HomeFooter />
    </div>

  );
};
export default Home;