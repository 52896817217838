import React from "react";
import { Tabs } from "antd";
import { useSelector } from "react-redux";

import GiftsCard from "./GiftsCard";
import paypal from '../../assets/paypal.svg'
import styles from "./Gifts.module.css";


const paypalReward = {
  "brandName": "PayPal",
  "disclaimer": "<p>Al canjear tus puntos por dinero en tu cuenta PayPal, puedes utilizarlo para comprar cualquier tipo de producto en cualquier establecimiento participante con PayPal. Usted esta obligado a crear una cuenta previamente en PayPal.com y aceptar los términos de su servicio para usar el valor asociado a la tarjeta de regalo. Este canje puede tardar hasta 48hrs en verse reflejado en tu cuenta PayPal. Es completa responsabilidad del panelistas o usuario ingresar los datos correctamente antes de confirmar el canje, cualquier error al ingresar la información PayPal es completa responsabilidad del usuario.</p>\r\n",
  "terms": "<p>Al canjear tus puntos por dinero en tu cuenta PayPal, puedes utilizarlo para comprar cualquier tipo de producto en cualquier establecimiento participante con PayPal. Usted esta obligado a crear una cuenta previamente en PayPal.com y aceptar los términos de su servicio para usar el valor asociado a la tarjeta de regalo. Este canje puede tardar hasta 48hrs en verse reflejado en tu cuenta PayPal. Es completa responsabilidad del panelistas o usuario ingresar los datos correctamente antes de confirmar el canje, cualquier error al ingresar la información PayPal es completa responsabilidad del usuario.</p>\r\n",
  "description": "<p>Recibe dinero directamente en tu cuenta de PayPal para realizar compras en muchos otros sitios web. Para canjear tus puntos por dinero en payPal debes contar con una cuenta previamente registrada, ya que te pediremos tus datos al canjear el premio. Este canje puede tardar hasta 48hrs en verse reflejado en tu cuenta PayPal.</p>\r\n",
  "shortDescription": "<p>Recibe dinero directamente en tu cuenta de PayPal para realizar compras en muchos otros sitios web.  </p>\r\n",
  "imageUrls": {
    "300w-326ppi": paypal,
  },
  "status": "active",
  "items": [
    {
      "utid": "PAYPAL200",
      "rewardName": "$200 MXN",
      "currencyCode": "MXN",
      "status": "active",
      "valueType": "FIXED_VALUE",
      "rewardType": "CASH",
      "isWholeAmountValueRequired": false,
      "faceValue": 200,
      "createdDate": "2018-10-05T19:32:02.89Z",
      "lastUpdateDate": "2020-09-03T18:33:07.186Z",
      "countries": [
        "MX"
      ],
      "credentialTypes": [
        "CASH"
      ],
      "redemptionInstructions": "<ol>\r\n\t<li>Para canjear tus puntos por dinero en payPal debes contar con una cuenta previamente registrada, ya que te pediremos tus datos al canjear el premio. Este canje puede tardar hasta 48hrs en verse reflejado en tu cuenta PayPal.</li>\r\n\t</ol>\r\n"
    },
    {
      "utid": "PAYPAL300",
      "rewardName": "$300 MXN",
      "currencyCode": "MXN",
      "status": "active",
      "valueType": "FIXED_VALUE",
      "rewardType": "CASH",
      "isWholeAmountValueRequired": false,
      "faceValue": 300,
      "createdDate": "2018-10-05T19:36:40.633Z",
      "lastUpdateDate": "2020-09-03T18:33:16.229Z",
      "countries": [
        "MX"
      ],
      "credentialTypes": [
        "CASH"
      ],
      "redemptionInstructions": "<ol>\r\n\t<li>Para canjear tus puntos por dinero en payPal debes contar con una cuenta previamente registrada, ya que te pediremos tus datos al canjear el premio. Este canje puede tardar hasta 48hrs en verse reflejado en tu cuenta PayPal.</li>\r\n\t</ol>\r\n"
    },
    {
      "utid": "PAYPAL500",
      "rewardName": "$500 MXN",
      "currencyCode": "MXN",
      "status": "active",
      "valueType": "FIXED_VALUE",
      "rewardType": "CASH",
      "isWholeAmountValueRequired": false,
      "faceValue": 500,
      "createdDate": "2018-10-05T20:56:54.548Z",
      "lastUpdateDate": "2020-09-03T18:33:22.624Z",
      "countries": [
        "MX"
      ],
      "credentialTypes": [
        "CASH"
      ],
      "redemptionInstructions": "<ol>\r\n\t<li>Para canjear tus puntos por dinero en payPal debes contar con una cuenta previamente registrada, ya que te pediremos tus datos al canjear el premio. Este canje puede tardar hasta 48hrs en verse reflejado en tu cuenta PayPal.</li>\r\n\t</ol>\r\n"
    }
  ]
}

const { TabPane } = Tabs;
const GiftsBar = ({ onSelect = () => { } }) => {
  const cards = useSelector((state) => state.rewards.list);
  const rewards = [...new Set([paypalReward, ...cards])]
  const renderRewards = () => {
    return rewards.map((reward, index) => (
      <GiftsCard onClick={() => onSelect(reward)} key={index} {...reward} />
    ));
  };

  return (
    <div className={styles.bar}>
      <div className={styles.tabs__container}>

        <div className={styles.cardsContainer}>{renderRewards()}</div>

      </div>
    </div>
  );
};

export default GiftsBar;
