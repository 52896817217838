import React, { useEffect, useState } from "react";
import { Table, Layout } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import styles from "./Summary.module.css";
import { getSummaryAction } from "../../../redux/adminDuck";
import summaryImg from "../../../assets/summary.svg";

const { Content } = Layout;
const resultsDataTable = {
  data: [
    { key: "1", lastMonth: "Panelistas registrados", quantity: "24" },
    { key: "2", lastMonth: "Encuestas creadas", quantity: "12" },
    { key: "3", lastMonth: "Encuestas completadas", quantity: "9" },
    { key: "4", lastMonth: "Premios canjeados", quantity: "23" }
  ],
  columns: [
    {
      title: "Últimos datos",
      dataIndex: "lastMonth",
      key: "lastMonth",
      className: "table__light-data",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      key: "quantity",
      className: "table__light-data highlight--data",
      align: "center",
    },
  ],
};
const profilesDataTable = {
  data: [
    { key: "1", attribute: "Hombres (Panelistas)", quantity: "354" },
    { key: "2", attribute: "Mujeres (Panelistas)", quantity: "280" }
  ],
  columns: [
    {
      title: "Característica",
      dataIndex: "attribute",
      key: "attribute",
      className: "table__light-data",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      key: "quantity",
      className: "table__light-data highlight--data",
      align: "center",
    },
  ],
};

const Summary = () => {

  const dispatch = useDispatch()
  const { summary } = useSelector(state => state.admin)
  const [data, setData] = useState(resultsDataTable.data)
  const [data2, setData2] = useState(profilesDataTable.data)

  useEffect(() => {
    dispatch(getSummaryAction())
  }, [])

  useEffect(() => {
    let realData = [
      { key: "1", lastMonth: "Panelistas registrados", quantity: summary.users },
      { key: "2", lastMonth: "Encuestas creadas", quantity: summary.surveys },
      { key: "3", lastMonth: "Encuestas completadas", quantity: summary.answers },
      { key: "4", lastMonth: "Premios canjeados", quantity: summary.rewards }
    ]
    setData(realData)
    realData = [
      { key: "1", attribute: "Hombres (Panelistas)", quantity: summary.men },
      { key: "2", attribute: "Mujeres (Panelistas)", quantity: summary.women }
    ]
    setData2(realData)

  }, [summary])

  return (
    <Content
      style={{
        background: "#f9f9f9",
        padding: "0 3%",
      }}
    >
      <div className={styles.summary__title}>Collectum Datos Panel</div>
      <div className={styles.summary}>
        <div className={styles["summary--left"]}>
          <div className={styles["summary--left__title"]}>
            Bienvenido al admin panel
          </div>
          <div className={styles["summary--left__content"]}>
            <h3>¿Qué puedes hacer aquí?</h3>
          </div>
          <div className={styles["summary--left__content"]}>
            Cómo administrador de Collectum Datos Panel puedes:
            <br />
            <br />
            <p><span className={styles["summary--bullet"]}>•</span> Visualizar  las encuestas creadas desde LimeSurvey</p>
            <p><span className={styles["summary--bullet"]}>•</span> Asignar encuestas a panelistas registrados</p>
            <p><span className={styles["summary--bullet"]}>•</span> Visualizar la información de todos los panelistas
              registrados</p>
            <p><span className={styles["summary--bullet"]}>•</span> Descargar la base de datos de panelistas</p>
            <p><span className={styles["summary--bullet"]}>•</span> Visualizar los premios registrados para Panel en Tango</p>
            <p><span className={styles["summary--bullet"]}>•</span> Visualizar los premios canjeados por los panelistas</p>
            <img
              src={summaryImg}
              alt="summaryImg"
              className={styles["summary--left__image"]}
            />
          </div>
          <div style={{ marginTop: "30%" }}>
            {/* <SummaryPieChart /> */}
          </div>

        </div>
        <div className={styles["summary--right"]}>
          <div className={styles["summary--right__content"]}>
            Resultados de Collectum Data:
          </div>
          <Table
            dataSource={data}
            columns={resultsDataTable.columns}
            pagination={false}
            className={styles.table}
          />
          <div className={styles["summary--right__content"]}>
            Perfil de nuestros usuarios:
          </div>
          <Table
            dataSource={data2}
            columns={profilesDataTable.columns}
            pagination={false}
            className={styles.table}
          />
        </div>
      </div>
    </Content>
  );
};

export default Summary;
