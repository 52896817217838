import React, { useEffect } from "react";
import { Layout, Menu } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faPoll,
  faTrophy,
  faPowerOff,
  faUserCircle,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { useParams, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Summary from "./Summary/Summary";
import Surveys from "./Surveys/Surveys";
import MyProfile from "./MyProfile/MyProfile";
import Rewards from "./Rewards/Rewards";
import Settings from "./Settings/Settings";
import { logOutUserAction } from "../../redux/userDuck";

import styles from "./Profile.module.css";
import Logo from "../../assets/logo-panel-white.svg";
import userImage from "../../assets/userImage.png";

const { Header, Sider } = Layout;

const renderSection = (section) => {
  switch (section) {
    case "resumen":
      return <Summary />;
    case "encuestas":
      return <Surveys />;
    case "mi-perfil":
      return <MyProfile />;
    case "premios":
      return <Rewards />;
    case "configuracion":
      return <Settings />
    default:
      return;
  }
};

const Profile = () => {
  const { section } = useParams();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const doLogOut = () => {
    dispatch(logOutUserAction());
    history.push("/");
  };

  useEffect(() => {
    // if(!user.profile || !user.profile.length) history.push('/formulario-de-perfilamiento') // this is no longer active due to perfil is no longer used
  }, [])

  return (
    <Layout
      className={styles.profile}
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        breakpoint="sm"
        collapsedWidth="0"
        className={styles.sider}
        style={{ background: "#323232" }}
      >
        <div className={styles["sticky--menu"]}>
          <Link to="/">
            <img src={Logo} alt="logo" className={styles.sider__logo} />
          </Link>
          <Menu
            mode="inline"
            defaultSelectedKeys={[section]}
            className={styles.sider__menu}
            style={{ background: "#323232", color: "#ffffff" }}
          >
            <Menu.Item
              key="resumen"
              icon={
                <FontAwesomeIcon
                  icon={faChartLine}
                  className={styles.sider__menu__element}
                />
              }
            >
              <Link to="/perfil/resumen" className={styles.sider__menu__text}>
                Resumen
              </Link>
            </Menu.Item>
            <Menu.Item
              key="encuestas"
              icon={
                <FontAwesomeIcon
                  icon={faPoll}
                  className={styles.sider__menu__element}
                />
              }
            >
              <Link to="/perfil/encuestas" className={styles.sider__menu__text}>
                Encuestas
              </Link>
            </Menu.Item>
            <Menu.Item
              key="premios"
              icon={
                <FontAwesomeIcon
                  icon={faTrophy}
                  className={styles.sider__menu__element}
                />
              }
            >
              <Link to="/perfil/premios" className={styles.sider__menu__text}>
                Premios
              </Link>
            </Menu.Item>
            <Menu.Item
              key="mi-perfil"
              icon={
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className={styles.sider__menu__element}
                />
              }
            >
              <Link to="/perfil/mi-perfil" className={styles.sider__menu__text}>
                Mi perfil
              </Link>
            </Menu.Item>
            <Menu.Item
              key="configuracion"
              icon={
                <FontAwesomeIcon
                  icon={faCog}
                  className={styles.sider__menu__element}
                />
              }
            >
              <Link to="/perfil/configuracion" className={styles.sider__menu__text}>
                Configuración
              </Link>
            </Menu.Item>
            <Menu.Item
              key="5"
              icon={
                <FontAwesomeIcon
                  icon={faPowerOff}
                  className={styles.sider__menu__element}
                />
              }
              style={{ marginTop: "50%" }}
              onClick={doLogOut}
            >
              Cerrar sesión
            </Menu.Item>
          </Menu>
        </div>
      </Sider>
      <Layout>
        <Header
          theme="light"
          style={{
            background: "#f9f9f9",
          }}
        >
          <div className={styles.header}>
            <BellOutlined style={{ fontSize: "18px", marginRight: "10px" }} />
            <div
              className={styles.header__element}
              style={{ borderLeft: "1px solid #C4C4C4" }}
            >
              <div className={styles["header__element--name"]}>
                {user.displayName ? user.displayName : user.email}
              </div>
              <div className={styles["header__element--points"]}>
                {user.points ? user.points?.toFixed(2) : 0} puntos
              </div>
            </div>
            <img
              src={user.photoURL ? user.photoURL : userImage}
              onError={(e) => {
                e.target.src = userImage;
              }}
              alt="userImage"
              className={`${styles.header__element} ${styles["element--img"]}`}
            />
          </div>
        </Header>
        {renderSection(section)}
      </Layout>
    </Layout>
  );
};

export default Profile;
