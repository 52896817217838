import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Layout, Button, Select } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { CloseOutlined} from "@ant-design/icons";
// import { newRewardAction } from "../../../redux/rewardsDuck";

import SiderAdmin from "../SiderAdmin";
import HeaderAdmin from "../HeaderAdmin";
import PrintReward from "./PrintReward";

import styles from "./RewardForm.module.css";

const newRewardAction = () => {}

const { Header, Content, Sider } = Layout;

const RewardForm = () => {
  const { id } = useParams();
  const { rewards } = useSelector((state) => state.rewards);
  const [localReward, setLocalReward] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const [option, setOption] = useState();
  const [form] = Form.useForm();
  const [FileList, setFileList] = useState([]);
  const [modal, handleModal] = useState(false);
  const history = useHistory();
  const { Option } = Select;
  const layout = {
    labelCol: {
      xs: { span: 7 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  useEffect(() => {
    let reward = rewards.find((item) => item._id === id);
    setLocalReward(reward);
    setFileList([])
  }, []);

  const setEditingTrue = () => {
    setIsEditing(true);
  };


  const imageprew = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      let input = e.target.files[i];
      FileList.push(input);
      setFileList([...FileList], input);
      if (FileList.length <= 4) {
            setFileList([FileList]);
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[i]);
            reader.onload = function(){
              let preview = document.getElementById("preview"),
              image = document.createElement("img");
            image.classList.add(styles.imageprew);
            image.src = reader.result;
            preview.append(image);
            }
      } else {
        handleModal(true);
      }
    }
  };
  
  const closeModal = () => handleModal(false);

  function onSelect(value) {
    setOption(value);
  }
  const doNewReward = async (form) => {
    FileList.length = 4;
    delete form.photos;
     let reward = await dispatch(newRewardAction(form, FileList));
   if(reward=== true){
    history.push("/admin/premios")
   } 
  };

  return (
    <Layout
      className={styles.admin}
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        breakpoint="sm"
        collapsedWidth="0"
        className={styles.sider}
        style={{ background: "#323232" }}
      >
        <SiderAdmin />
      </Sider>
      <Layout>
        <Header
          theme="light"
          style={{
            background: "#f9f9f9",
          }}
        >
          <HeaderAdmin />
        </Header>
        <Content style={{ background: "#f9f9f9", padding: "0 3%" }}>
          <div className={styles.reward__title}>Premios</div>
          {localReward ? (
            <PrintReward
              reward={localReward}
              setEditingTrue={setEditingTrue}
              isEditing={isEditing}
            />
          ) : (
            <div className={styles.form__newReward}>
              <p className={styles.subtitle}>
                Completa la información para agregar un premio.
              </p>
              <Form
                {...layout}
                form={form}
                name="Rewards"
                onFinish={doNewReward}
                style={{ padding: "3%" }}
              >
                <Form.Item
                  name="title"
                  label={<label className={styles.form__label}> Nombre </label>}
                >
                  <Input
                    style={{ background: "#f9f9f9" }}
                    placeholder="Maximo 30 caracteres"
                  />
                </Form.Item>

                <Form.Item
                  name="body"
                  label={
                    <label className={styles.form__label}> Descripción</label>
                  }
                >
                  <TextArea
                    style={{ background: "#f9f9f9" }}
                    placeholder="Maximo 250 caracteres"
                  />
                </Form.Item>

                <Form.Item
                  name="price"
                  label={<label className={styles.form__label}> Puntos </label>}
                >
                  <Input
                    style={{ background: "#f9f9f9" }}
                    placeholder="Escribe el precio"
                  />
                </Form.Item>

                <Form.Item
                  name="category"
                  label={
                    <label className={styles.form__label}> Categoría </label>
                  }
                >
                  <Select
                    style={{ width: "100%", background: "#f9f9f92" }}
                    placeholder="Selecciona una categoría"
                    optionFilterProp="children"
                    onSelect={onSelect}
                  >
                    <Option value="Hogar">Hogar</Option>
                    <Option value="Tecnología">Tecnología</Option>
                    <Option value="Tarjetas de regalo">
                      Tarjetas de regalo
                    </Option>
                    <Option value="Entretenimiento">Entretenimiento</Option>
                    <Option value="Moda y belleza">Moda y belleza</Option>
                    <Option value="Otros">Otros</Option>
                  </Select>
                </Form.Item>

                {option === "Tarjetas de regalo" ? (
                  <div>
                    <Form.Item
                      name="subCategory"
                      label={
                        <label className={styles.form__label}>
                          {" "}
                          Subcategoría{" "}
                        </label>
                      }
                      style={{ width: "100%" }}
                    >
                      <Select>
                        <Option value="netflix">Netflix</Option>
                        <Option value="Blim">Blim</Option>
                        <Option value="amazon">Amazon</Option>
                        <Option value="playStore">Play store</Option>
                      </Select>
                    </Form.Item>
                    {/*  Array */}
                    <Form.List name="cardCodes">
                      {(fields, { add, remove }) => {
                        return (
                          <div>
                            {fields.map((field, index) => (
                              <Form.Item
                                label={`Tarjeta ${index + 1}`}
                                required={false}
                                key={field.key}
                              >
                                <Form.Item
                                  {...field}
                                  validateTrigger={["onChange", "onBlur"]}
                                  style={{ width: "100%" }}
                                >
                                  <Input style={{ background: "#f9f9f9" }} />
                                </Form.Item>
                                {fields.length >= 1 ? (
                                  <CloseOutlined
                                    className="dynamic-delete-button"
                                    style={{
                                      position: "absolute",
                                      zIndex: "1",
                                      top: "5.3px",
                                      fontSize: "20px",
                                      left: "93%",
                                    }}
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  />
                                ) : null}
                              </Form.Item>
                            ))}
                            <Form.Item>
                              <div className={styles.contentaddTarjet}>
                                <input
                                  onClick={() => {
                                    add();
                                  }}
                                  type="button"
                                  value="Agregar otra tarjeta"
                                  className={styles.addTarjet}
                                />
                              </div>
                            </Form.Item>
                          </div>
                        );
                      }}
                    </Form.List>
                  </div>
                ) : (
                  <Form.Item
                    name="stock"
                    label={
                      <label className={styles.form__label}> Piezas </label>
                    }
                  >
                    <Input style={{ background: "#f9f9f9" }} placeholder="00" />
                  </Form.Item>
                )}

                <Form.Item
                  label={
                    <label className={styles.form__label}> Fotografías</label>
                  }
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "flex-start",
                  }}
                  name="photos"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <div className={styles.imagePreview} id="preview">
                    </div>
                    <div>
                      <input
                        id="imagebutton"
                        className={styles["custom-file-input"]}
                        type="file"
                        onChange={imageprew}
                        multiple="true"
                      />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item shouldUpdate={true}>
                  {() => (
                    <Button
                      style={{
                        width: "158px",
                        height: "40px",
                        borderRadius: "20px",
                        fontFamily: "AvenirBook",
                        fontSize: "18px",
                      }}
                      className={styles.Button}
                      type="primary"
                      htmlType="submit"
                      disabled={
                        !form.isFieldsTouched(true) ||
                        form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    >
                      Agregar
                    </Button>
                  )}
                </Form.Item>
              </Form>
              <Modal
                visible={modal}
                onCancel={closeModal}
                width="65%"
                footer={null}
                style={{ minWidth: "290px" }}
              >
                <div className={styles.modal__title}>Eliminar premio</div>
                <div className={styles.modal__content}>
                  ¿Estas seguro de eliminar este premio? Se eliminará
                  automáticamente de la lista y nadie podrá canjearlo.
                </div>
                <div className={styles.modal__footer}>
                  <button
                    onClick={closeModal}
                    className={styles["modal__button--cancel"]}
                  >
                    Ok
                  </button>
                </div>
              </Modal>
            </div>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};
export default RewardForm;
