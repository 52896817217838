import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward } from "@fortawesome/free-solid-svg-icons";

import Navbar from "../Home/HomeNavbar";
import HomeFooter from "../Home/HomeFooter";

import computer from "../../assets/laptop-garden.png";
import styles from "../AboutUs/AboutUs.module.css";

const AboutUs = () => {
  return (
    <div>
      <div className={styles.aboutus}>
        <Navbar />
        <div className={styles.aboutus__title}>
          Quien tú eres, nos{" "}
          <span className={styles["aboutus__title--color"]}>importa </span>
        </div>
        <p className={styles.aboutus__description}>
          Juntos, somos una comunidad mundial en donde podemos ver reflejado el
          contexto de nuestra sociedad. Y donde lo que más nos importa, es tu
          opinión.
        </p>
        <div className={styles["images-container"]}>
          <img src={computer} alt="computer-woman" id={styles["image-left"]} />
          <div className={styles["images--middle-container"]}>
            <img
              src={computer}
              alt="computer-woman"
              id={styles["image-middle-top"]}
            />
            <img
              src={computer}
              alt="computer-woman"
              id={styles["image-middle-bottom"]}
            />
          </div>
          <div className={styles["images--right-container"]}>
            <img
              src={computer}
              alt="computer-woman"
              id={styles["image-right-top"]}
            />
            <img
              src={computer}
              alt="computer-woman"
              id={styles["image-right-bottom"]}
            />
          </div>
        </div>
      </div>
      <div className={styles["aboutus__container-card"]}>
        <div className={styles["aboutus__container-card--background"]} />
        <div className={styles.aboutus__card}>
          <div className={styles.card__title}>Nuestros valores</div>
          <p className={styles.card__description}>
            Juntos, somos una comunidad mundial en donde podemos ver reflejado
            el contexto de nuestra sociedad. Y donde lo que más nos importa, es
            tu opinión.
          </p>
          <div className={styles["elements-container"]}>
            <div className={styles.card__element}>
              <FontAwesomeIcon
                icon={faAward}
                className={styles.card__element__icon}
              />
              <div className={styles.card__element__title}>Responsabilidad</div>
              <div className={styles.card__element__description}>
                Juntos, somos una comunidad mundial en donde podemos ver
                reflejado el contexto de nuestra sociedad
              </div>
            </div>
            <div className={styles.card__element}>
              <FontAwesomeIcon
                icon={faAward}
                className={styles.card__element__icon}
              />
              <div className={styles.card__element__title}>Responsabilidad</div>
              <div className={styles.card__element__description}>
                Juntos, somos una comunidad mundial en donde podemos ver
                reflejado el contexto de nuestra sociedad
              </div>
            </div>
            <div className={styles.card__element}>
              <FontAwesomeIcon
                icon={faAward}
                className={styles.card__element__icon}
              />
              <div className={styles.card__element__title}>Responsabilidad</div>
              <div className={styles.card__element__description}>
                Juntos, somos una comunidad mundial en donde podemos ver
                reflejado el contexto de nuestra sociedad
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default AboutUs;
