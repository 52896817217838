import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSurveyParticipants } from '../../../redux/adminDuck'
import { Table, Spin } from 'antd'

const Participants = ({ survey }) => {
    const dispatch = useDispatch()
    const { participants } = useSelector(({ admin: { currentSurvey } }) => currentSurvey)
    const { fetching } = useSelector(({ admin }) => admin)

    useEffect(() => {
        dispatch(getSurveyParticipants(survey))
    }, [])

    if (fetching) return <Spin />
    return (
        <>
            <p>Total de participantes:<span style={{ fontWeight: "bold", fontSize: 24 }}> {participants?.length}</span></p>
            <Table
                columns={[{
                    title: "Nombre",
                    dataIndex: "user",
                    key: "user",
                    render: user => user.displayName
                },
                {
                    title: "Correo",
                    dataIndex: "user",
                    key: "user",
                    render: user => user.email
                },
                {
                    title: "Nivel",
                    dataIndex: "user",
                    key: "user",
                    render: user => user.level
                },
                {
                    title: "Puntos",
                    dataIndex: "user",
                    key: "user",
                    render: user => user.points
                }
                ]}
                dataSource={participants}
            />
        </>
    );
}

export default Participants;