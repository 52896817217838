import React from "react";
import GiftsHeader from "./GiftsHeader";
import GiftsBar from "./GiftsBar";
import HomeNavbar from '../Home/HomeNavbar';
import Disclaimer from "./Disclaimer";

const Gifts = () => {
    return (
        <div className="Gifts">
            <HomeNavbar />
            <GiftsHeader />
            <GiftsBar />
            <Disclaimer />
        </div>

    );
};
export default Gifts;