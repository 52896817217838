import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { Layout, Tabs, Table, Empty, message, Spin, Progress } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { uploadPicProfileAction } from "../../../redux/userDuck";

import styles from "./MyProfile.module.css";
import userImage from "../../../assets/userImage.png";
import flag from "../../../assets/flag.svg";

import cup from "../../../assets/cup.svg";
import gold from "../../../assets/gold.svg";
import bronze from "../../../assets/bronze.svg";
import silver from "../../../assets/silver.svg";
import RedeemRewardsTable from "./RedeemRewardsTable";
import medallas from "../../../assets/banderas.png"

const { TabPane } = Tabs;
const { Content } = Layout;

const MyProfile = () => {
  const { user, error, fetching } = useSelector((state) => state.user);
  const [inputfile] = useState(React.createRef());
  const { rewards = [] } = user
  const dispatch = useDispatch();
  const surveys = useSelector(state => {
    if (state.user.user.answers && state.user.user.answers.length) {
      return state.user.user.answers.map(answer => ({ ...answer, ...answer.survey })).filter(s => s.completed !== "N")
    } else {
      return []
    }
  })

  const sendImage = ({ target: { files: [image] } }) => {
    dispatch(uploadPicProfileAction(image))
  };

  return (
    <Content
      style={{
        background: "#f9f9f9",
        padding: "0 3%",
      }}
    >
      <div className={styles.profile__title}> Mi perfil </div>
      <div className={styles.myProfile}>
        <div className={styles["myProfile--left"]}>
          <div className={styles.left__card}>
            <div className={styles["left__card__image-container"]}>
              {fetching && (
                <div className={styles["left__card__spinner-container"]}>
                  <Spin size="large" />
                </div>
              )}
              <img
                src={user.photoURL || userImage}
                onError={(e) => {
                  e.target.src = userImage;
                }}
                alt="userImage"
                className={styles.left__card__image}
              />
              <FontAwesomeIcon
                icon={faCamera}
                className={styles.left__card__icon}
                onClick={() => inputfile.current.click()}
              />
              <input
                type="file"
                className={styles.left__card__input}
                ref={inputfile}
                onChange={sendImage}
                accept="image/*"
              />
            </div>
            <div className={styles.left__card__name}>
              {user.displayName ? user.displayName : user.email}
            </div>
            <div className={styles.left__card__email}>{user.email}</div>
            <div className={styles.left__card__content}>
              <div className={styles["card__content--element"]}>
                Encuestas completadas
                <span className={styles["card__content--element--number"]}>
                  {surveys.length}
                </span>
              </div>
              {/* <div className={styles["card__content--element"]}>
                Encuestas pendientes
                <span className={styles["card__content--element--number"]}>
                  1
                </span>
              </div> */}
              <div className={styles["card__content--element"]}>
                Premios canjeados
                <span className={styles["card__content--element--number"]}>
                  {rewards.length}
                </span>
              </div>

            </div>
          </div>
        </div>
        <div className={styles["myProfile--right"]}>
          <Tabs
            defaultActiveKey="1"
            size="large"
            style={{
              fontFamily: "'ABeeZee',sans-serif",
              fontSize: "18px",
              color: "#323232",
              marginTop: "-15px",
            }}
          >
            <TabPane tab="Información" key="1">
              <div className={styles.information}>
                <div className={styles["information--container"]}>
                  <div className={styles.information__name}>
                    {user.displayName ? user.displayName : user.email},{" "}
                    <span className={styles["name--have"]}>
                      {user.points ? "tienes" : "aún no tienes puntos"}
                    </span>{" "}
                    <span className={styles.name__points}>
                      {" "}
                      {user.points && user.points + " puntos"}{" "}
                    </span>
                  </div>
                  <div className={styles.badge}>
                    <img
                      src={user.level === "intermediate" ? silver : user.level === "advanced" ? gold : user.level === "expert" ? cup : bronze}
                      alt="insignia"
                      className={styles.badge__image}
                    />
                    {user.level === "intermediate" ? "Intermedio" : user.level === "advanced" ? "Avanzado" : user.level === "expert" ? "Experto" : "Principiante"}
                  </div>
                </div>
                <div className={styles.information__flex}>
                  <div className={styles["information--container"]}>
                    <div className={styles["information__title"]}>
                      Has sido parte de Collectum data desde:
                    </div>
                    <div className={styles["information__content"]}>
                      {moment(user.createdAt).fromNow()}
                    </div>
                  </div>
                  <div
                    className={styles["information--container"]}
                    style={{ marginRight: "0px" }}
                  >
                    <div className={styles["information__title"]}>
                      Has completado:
                    </div>
                    <div className={styles["information__content"]}>
                      {surveys.length}
                    </div>
                  </div>
                </div>
                <div className={styles["information--container"]}>
                  <div className={styles["information__title"]}>
                    Completa las encuestas y sube de nivel
                  </div>
                  <div
                    className={styles.information__flex}
                    style={{ alignItems: "center" }}
                  >
                    <img
                      src={flag}
                      alt="flag"
                      className={styles.surveys__flag}
                    />
                    <Progress
                      percent={surveys.length * 100 / (user.level === "intermediate" ? 10 : user.level === "advanced" ? 15 : user.level === "expert" ? 20 : 4)}
                      surveys
                      trailColor="#F2F2F2"
                      showInfo={false}
                      style={{ margin: "0 3%" }}
                    />
                    <img
                      src={user.level === "intermediate" ? gold : user.level === "advanced" ? cup : user.level === "expert" ? cup : silver}
                      alt="insignia"
                      className={styles.surveys__badge}
                    />
                  </div>
                  <div className={styles["surveys-status"]}>
                    {" "}
                    {surveys.length}/{user.level === "intermediate" ? 10 : user.level === "advanced" ? 15 : user.level === "expert" ? 20 : 4} encuestas{" "}
                  </div>
                  <hr className={styles["divider"]} />
                  <div>
                    <div className={styles["information__title"]}>
                      ¿Tienes dudas acerca de como subir de nivel? Completa 16 encuestas para ser nivel experto, conforme subas de nivel, recibirás más puntos por encuesta. <strong>¡Empieza ya!</strong>                     </div>
                    <div>
                      <img src={medallas} className={styles["medallas"]} />

                    </div>
                  </div>
                </div>

              </div>
            </TabPane>
            <TabPane tab="Premios" key="2">
              <RedeemRewardsTable />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Content>
  );
};
export default MyProfile;
