import React from "react";
import { Button } from "antd";



const HomeUnete = () => {
    const style = {
        button: {
            fontFamily: "AvenirRoman",
            background: "#95BF48",
            color: "#FFFFFF",
            fontSize: "18px",
            borderRadius: "4px",
            border: "none",
            minWidth: "259px",
            maxWidth: "350px",
            width: "100%",
            height: "40px",
        },
    };
    return (
        <div className="home-container__unete">
            ÚNETE A NUESTROS MÁS DE 1,000 COLABORADORES Y COMPARTE TU
            EXPERIENCIA
            <br />
            <Button
                className="form__button"
                htmlType="submit"
                style={style.button}
                href="/registro"
            >
                <p style={{ paddingTop: "1.5%" }}>REGISTRARME</p>
            </Button>
        </div>
    )
}

export default HomeUnete