import React, { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Input,
  Layout,
  InputNumber,
  Popconfirm,
  Form,
  notification,
  Button,
  Tooltip,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  getSurveysAction,
  createSurveyAction,
  editSurveyAction,
  createExternalSurveyAction,
  deleteSurveyAction
} from "../../../redux/surveysDuck";

import styles from "./Surveys.module.css";
import { useHistory } from 'react-router-dom'
import SelectPanelists from "./SelectPanelists";
import Participants from "./Participants";
import Modal from "antd/lib/modal/Modal";

const { Search } = Input;
const { Content } = Layout;


const Surveys = () => { // component
  // const [sendDisabled, setSendDisabled] = useState(true);
  const dispatch = useDispatch();
  const { surveys, fetching, error } = useSelector((state) => state.surveys);
  const [sending, setSending] = useState(false);
  const [sendingSid, setSendingSid] = useState(null);
  const history = useHistory()
  const [currentSurvey, setCurrentSurvey] = useState()
  const [showModal, setShowModal] = useState(false)
  const [newSurvey, setNewSurvey] = useState({ points: 10 })
  const [newSurveyError, setError] = useState(false)

  const [surveyList, setSurveyList] = useState([])


  useEffect(() => {
    doGetSurveys();
  }, []);

  useEffect(() => {

    if (error) notification.error({
      message: `Acción no completada, ocurrió un error inesperado.`,
      description: error,
      placement: "bottomLeft",
    })
  }, [error])

  useEffect(() => {
    if (surveys.length) {
      setSurveyList(
        surveys.sort((a, b) => b.createdAt?.localeCompare(a.createdAt))
      )
    }
  }, [surveys])

  const deleteSurvey = (survey) => {
    if (!window.confirm('¿Estas seguro de elminar? esta acción no es reversible')) {
      return
    }
    dispatch(deleteSurveyAction(survey._id))
  }

  const onChange = ({ target: { name, value } }) => {
    setNewSurvey({ ...newSurvey, [name]: value })
  }

  const doGetSurveys = () => {
    dispatch(getSurveysAction());
  };

  const [handleData, setHandleData] = useState([]);

  const searchInData = ({ target: { value } }) => {
    let filteredData = surveys.filter((item) =>
      item.title.toUpperCase().includes(value.toUpperCase())
    );
    setHandleData([...filteredData]);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
              maxWidth: "130px",
            }}
            rules={[
              {
                required: true,
                message: `Por favor ingresa ${title}!`,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    if (record.active === "N") return
    form.setFieldsValue({
      incidence: "",
      surveyTime: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key, record) => {
    const row = await form.validateFields();

    const localSurvey = surveys.find((item) => item.sid === key);
    if (localSurvey.points && localSurvey.active) {
      if (newSurvey.link) {
        dispatch(
          editSurveyAction(localSurvey._id, { ...row, link: newSurvey.link })
        )
        setNewSurvey({})
      } else {
        dispatch(
          editSurveyAction(localSurvey._id, { ...row })
        )
      }

    } else {
      dispatch(
        createSurveyAction({
          sid: key,
          title: record.surveyls_title,
          ...row,
        })
      )
    }
    cancel();

  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "surveyls_title",
      key: "surveyls_title",
      className: "table__light-data",
      render: (title, record) => (
        <p
          style={{ cursor: "pointer" }}
          onClick={() => {
            setCurrentSurvey(record)
            if (record.id) history.push(`/admin/encuestas/${record.id}/participantes`)
          }}
        >{title}</p>

      )
    },
    {
      title: "Puntos",
      dataIndex: "points",
      key: "points",
      editable: true,
      className: "table__data",
      sorter: (a, b) => a.points - b.points,
      render: (points) => <div>{(points || 0) + " puntos"}</div>,
    },
    {
      title: "Estado",
      dataIndex: "active",
      key: "active",
      className: "table__light-data",
      render: (value, record) => record.external ?
        <Tag color={"blue"}>Externa</Tag>
        : value === "N" ?
          <Tag color="error">No disponible</Tag> :
          <Tag color="green">Interna</Tag>
    },
    {
      title: 'Token para puntos',
      dataIndex: 'external',
      key: 'external',
      render: (val, record) => val ? <Tooltip onClick={() => {
        navigator.clipboard.writeText(`https://collectumdata.com.mx/externa/${record.sid}`)
      }} color="blue" placement="bottom" title={"Clic para copiar"}>
        <Button>{record.sid}</Button>
      </Tooltip> : <Tag color="green">Automática</Tag>
    },
    {
      title: 'Link externo o Link de salida',
      dataIndex: 'link',
      key: 'link',
      render: (val, record) => {
        const editable = isEditing(record);
        return editable && record.external ? <Input
          defaultValue={val}
          onChange={onChange}
          style={{ width: 300, marginTop: 30 }}
          value={newSurvey.link}
          name="link" placeholder="Escribe el enlace de la encuesta externa" />
          :
          <Tooltip
            onClick={() => {
              navigator.clipboard.writeText(`https://collectumdata.com.mx/${val ? 'externa' : 'completada'}/${record.sid}`)
            }}
            color={"#95BF48"} placement="bottom" title={"Clic para copiar"}
          >
            <Tag
              style={{ cursor: 'pointer' }}
              color={val ? 'blue' : 'green'}>
              {val ? val : `https://collectumdata.com.mx/completada/${record.sid}`}
            </Tag>
          </Tooltip>

      }

    },
    {
      title: "Acciones",
      dataIndex: "Acciones",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div className={styles.table__actions}>
            <Button
              className={styles.table__cancel}
              onClick={cancel}
            >
              Cancelar
            </Button>
            <Popconfirm
              okText="Aceptar"
              cancelText="Cancelar"
              icon={<QuestionCircleOutlined style={{ color: "#95BF48" }} />}
              title="¿Estas seguro(a) de guardar?"
              onConfirm={() => save(record.key, record)}
            >
              <Button
                type="primary"
                className={styles.table__save} >Guardar</Button>
            </Popconfirm>
          </div>
        ) : (
          <div className={styles.table__actions}>
            {record.active === true && <div
              className={
                record.points ? styles.table__send : styles.send__inactive
              }
              onClick={() => {
                setSending(true);
                setSendingSid(record);
              }}
            >
              Enviar
            </div>}
            {record.active !== "N" && <div
              className={styles.table__edit}
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Editar
            </div>}
          </div>
        );
      },
    },
    {
      title: 'Eliminar',
      dataIndex: '_id',
      key: '_id',
      render: (_, record) => {
        const editable = isEditing(record)
        if (editable) {
          return <Button onClick={() => deleteSurvey(record)} style={{ marginTop: 25 }} type="danger">Eliminar</Button>
        }
      }
    }
  ];

  //TABLA PANELISTAS






  /*------------------------------------*\
  #FILTROS DE PANELISTS
  \*------------------------------------*/


  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  if (sending) return <SelectPanelists onFinish={() => setSending(false)} survey={sendingSid} />
  if (currentSurvey) return <div>
    <h3 onClick={() => setCurrentSurvey(undefined)} style={{ fontSize: 40, cursor: "pointer" }}><FontAwesomeIcon icon={faArrowLeft} /></h3>
    <span>Participantes de la encuesta <strong style={{ fontSize: 24 }}>{currentSurvey.title}</strong></span>
    <Participants survey={currentSurvey} />
  </div>

  const handleCreateExternalSurvey = () => {
    if (!newSurvey.points || !newSurvey.title || !newSurvey.link || newSurvey.title.length < 3) {
      return setError(true)
    } else {
      setError(false)
    }
    dispatch(createExternalSurveyAction(newSurvey))
    setNewSurvey({ points: 10 })
    setShowModal(false)
  }

  // 
  console.log("orden? ", surveyList)

  return (
    <Content
      style={{
        background: "#f9f9f9",
        padding: "0 3%",
      }}
    >
      <div className={styles.polls__title}>Encuestas</div>
      <div className={styles.polls__content}>
        Collectum Datos Panel tiene{" "}
        <span className={styles["polls__title--points"]}>{surveys.length} encuestas</span>{" "}
        creadas.
      </div>
      <div style={{
        // gap: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Search
          placeholder="Buscar"
          style={{
            width: "94%",
            borderRadius: "20px",
            fontFamily: "AvenirBook",
            fontSize: "18px",
            margin: "20px 0",
          }}
          // allowClear
          onChange={(e) => searchInData(e)}
        />
        <button
          onClick={() => setShowModal(true)}
          className={styles.plusButton} >
          <span style={{ marginTop: "-4px" }}>
            +
          </span>
        </button>
      </div>
      <Form form={form} component={false}>
        <Table
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => {
          //       setCurrentSurvey(record)
          //       if (record.id) history.push(`/admin/encuestas/${record.id}/participantes`)
          //     } // click row
          //     // onDoubleClick: event => {}, // double click row
          //     // onContextMenu: event => {}, // right button click row
          //     // onMouseEnter: event => {}, // mouse enter row
          //     // onMouseLeave: event => {}, // mouse leave row
          //   }
          // }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          // dataSource={handleData.length >= 1 ? handleData : surveys}
          dataSource={handleData.length >= 1 ? handleData : surveyList}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          loading={fetching}
        />
      </Form>


      <Modal
        onCancel={() => setShowModal(false)}
        title="Agregar encuesta externa"
        visible={showModal}
        okText="Guardar"
        onOk={handleCreateExternalSurvey}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <p>
            <span>Nombre:</span>
            {" "}<br />
            <Input
              onChange={onChange}
              style={{ width: 300 }}
              value={newSurvey.title}
              name="title" placeholder="Escribe el nombre de la encuesta" />
          </p>

          <p>
            <span>Link de la encuesta externa:</span>
            {" "}<br />
            <Input
              onChange={onChange}
              style={{ width: 300 }}
              value={newSurvey.link}
              name="link" placeholder="Escribe el enlace de la encuesta externa" />
          </p>
          <p>
            <span>Puntos:</span>
            {" "}<br />
            <InputNumber
              onChange={value => onChange({ target: { name: 'points', value } })}
              value={newSurvey.points}
              style={{ width: 100 }}
              name="points" placeholder="Escribe los puntos de la encuesta" />
          </p>
          {newSurveyError && <p style={{ color: 'red' }}>Completa todos los campos</p>}

        </div>
      </Modal>

    </Content>
  );
};

export default Surveys;
