import React, { useEffect, useState } from 'react'
import {
  Layout,
  Form,
  Input,
  Button,
  Select,
  Spin,
  Alert,
  InputNumber,
  Checkbox,
  Col,
  Row,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { sendProfileAction } from '../../../redux/userDuck'
import { useHistory } from 'react-router-dom'
import 'moment/locale/es-us'
import moment from 'moment'
import { DatePicker } from 'antd/es'
import styles from './ProfileForm.module.css'
import { Collapse } from 'antd'
import StateSelect from './StateSelect'

const { Panel } = Collapse

const { Option } = Select
const { Content } = Layout

const ProfileForm = () => {
  const { fetching, error } = useSelector((state) => state.user)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const [showKids, setShowKids] = useState(false)
  //
  const initialForm = localStorage.profile
    ? JSON.parse(localStorage.profile)
    : null
  if (initialForm) delete initialForm.birthday
  form.setFieldsValue(initialForm)

  const submitProfile = async (form) => {
    let profile = await formatPoints(form)
    profile = { ...profile, ...form }
    let updateprofile = await dispatch(sendProfileAction(profile))
    if (updateprofile) history.push('/perfil/resumen')
  }

  async function formatPoints(form) {
    const points = {}
    if (form.lastStudy === 'notStudy' || form.lastStudy === 'preschool') {
      points.lastStudy = 0
    }
    if (form.lastStudy === 'incompletePrimary') {
      points.lastStudy = 10
    }
    if (form.lastStudy === 'completePrimary') {
      points.lastStudy = 22
    }
    if (form.lastStudy === 'incompleteSecondary') {
      points.lastStudy = 23
    }
    if (form.lastStudy === 'completeSecondary') {
      points.lastStudy = 31
    }
    if (form.lastStudy === 'incompleteHighSchool') {
      points.lastStudy = 35
    }
    if (form.lastStudy === 'completeHighSchool') {
      points.lastStudy = 43
    }
    if (form.lastStudy === 'incompleteDegree') {
      points.lastStudy = 59
    }
    if (form.lastStudy === 'completeDegree') {
      points.lastStudy = 73
    }
    if (form.lastStudy === 'postgraduate') {
      points.lastStudy = 101
    }
    if (form.bathrooms === 'none') {
      points.bathrooms = 0
    }
    if (form.bathrooms === 'justOne') {
      points.bathrooms = 24
    }
    if (form.bathrooms === 'twoMore') {
      points.bathrooms = 47
    }
    if (form.car === 'none') {
      points.car = 0
    }
    if (form.car === 'justOne') {
      points.car = 18
    }
    if (form.car === 'twoMore') {
      points.car = 37
    }
    if (form.internet === 'no') {
      points.internet = 0
    }
    if (form.internet === 'yes') {
      points.internet = 31
    }
    if (form.workedLastMonth === 'none') {
      points.workedLastMonth = 0
    }
    if (form.workedLastMonth === 'onePerson') {
      points.workedLastMonth = 15
    }
    if (form.workedLastMonth === 'twoPeople') {
      points.workedLastMonth = 31
    }
    if (form.workedLastMonth === 'threePeople') {
      points.workedLastMonth = 46
    }
    if (form.workedLastMonth === 'fourPeopleMore') {
      points.workedLastMonth = 61
    }
    if (form.sleepingRooms === 'none') {
      points.sleepingRooms = 0
    }
    if (form.sleepingRooms === '1') {
      points.sleepingRooms = 6
    }
    if (form.sleepingRooms === '2') {
      points.sleepingRooms = 12
    }
    if (form.sleepingRooms === '3') {
      points.sleepingRooms = 17
    }
    if (form.sleepingRooms === 'fourMore') {
      points.sleepingRooms = 23
    }
    const totalPoints = Object.values(points).reduce((acc, n) => acc + n, 0)
    return {
      points,
      totalPoints,
      socialClass:
        totalPoints > 204
          ? 'A/B'
          : totalPoints > 165
            ? 'C+'
            : totalPoints > 135
              ? 'C'
              : totalPoints > 111
                ? 'C-'
                : totalPoints > 89
                  ? 'D+'
                  : totalPoints > 47
                    ? 'D'
                    : 'E',
    }
  }

  const style = {
    button: {
      fontSize: '18px',
      borderRadius: '20px',
      border: 'none',
      height: '40px',
      minWidth: '158px',
      maxWidth: '158px',
      width: '100%',
      margin: 'auto',
      marginLeft: '30%',
    },
    datePicker: {
      width: '100%',
      minWidth: '50px',
      maxWidth: '780px',
      marginLeft: '1%',
    },
  }

  return (
    <Content
      style={{
        background: '#f9f9f9',
        padding: '0% 3%',
        height: '100vh',
      }}
    >
      <div className={styles.profileform__title}>¿Estas listo?</div>
      <div className={styles.profileform__description}>
        Antes de comenzar, completa esta breve encuesta que nos ayudará a
        clasificar mejor el tipo de cuestionarios que puedes completar acorde a
        tus gustos y preferencias.
      </div>

      <Form
        name="newProfile"
        className={styles.profileform__form}
        layout="vertical"
        hideRequiredMark
        onFinish={submitProfile}
        form={form}
      >
        {error && error !== '' && (
          <Alert message={error} type="error" showIcon />
        )}
        <Collapse defaultActiveKey={['1']} ghost>
          <Panel
            style={{ fontSize: '22px' }}
            header="Información general"
            key="1"
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa tu nombre completo',
                },
              ]}
              name="displayName"
              className={styles.profileform__item}
            >
              <Input placeholder="Nombre completo" />
            </Form.Item>
            <div className={styles.profileform__item__age}>
              <Form.Item
                rules={[
                  {
                    type: 'number',
                    message:
                      'Por favor ingresa tu edad, debes ser mayor a 18 años',
                    min: 18,
                    max: 100,
                  },
                ]}
                name="age"
              >
                <InputNumber placeholder="Edad" />
              </Form.Item>
              <Form.Item
                className={styles.profileform__item__calendar}
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa tu fecha de nacimiento',
                  },
                ]}
                name="birthday"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  defaultValue={moment().subtract(18, 'years')}
                  placeholder="Fecha de nacimiento"
                  style={style.datePicker}
                  disabledDate={(d) => {
                    // console.log(d)
                    return !d || d.isAfter(moment().subtract(18, 'years'))
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item
              rules={[
                { required: true, message: 'Por favor ingresa tu estado' },
              ]}
              name="state"
              className={styles.profileform__item}
            >
              <StateSelect />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa tu municipio o alcaldía',
                },
              ]}
              name="city"
              className={styles.profileform__item}
            >
              <Input placeholder="Municipio o alcaldía" />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true, message: 'Por favor ingresa tu colonia' },
              ]}
              name="neighborhood"
              className={styles.profileform__item}
            >
              <Input placeholder="Colonia" />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa tu código postal',
                },
              ]}
              name="zipCode"
              className={styles.profileform__item}
            >
              <Input placeholder="Código Postal" />
            </Form.Item>

            <Form.Item
              label="¿Cuál es su género?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="gender"
              className={styles.profileform__item}
            >
              <Select placeholder="selecciona una opción">
                <Option value="male">Hombre</Option>
                <Option value="female">Mujer</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="¿Cuál de las siguientes opciones describe mejor tu ocupación?
                    Si combinas algunas de estas actividades márquelas que apliquen en su caso"
              rules={[
                {
                  required: true,
                  message: 'Por favor selecciona tus respuestas',
                  type: 'array',
                },
              ]}
              name="occupation"
              className={styles.profileform__item}
            >
              <Select mode="multiple" placeholder="selecciona tus opciones">
                <Option value="fullTimeO">
                  Trabajo tiempo completo en mi propio negocio
                </Option>
                <Option value="partTime">
                  Trabajo medio tiempo en mi propio negocio
                </Option>
                <Option value="partTimeEmployee">
                  Empleado de medio tiempo
                </Option>
                <Option value="unemployed">
                  Sin empleo en búsqueda de trabajo
                </Option>
                <Option value="student">Estudiante</Option>
                <Option value="housewife">Ama de casa</Option>
                <Option value="retired">Jubilado / pensionado</Option>
                <Option value="other">Otro</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="¿Cuál es tu último año de estudio?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="studies"
              className={styles.profileform__item}
            >
              <Select placeholder="selecciona una opción">
                <Option value="notStudy">No estudio</Option>
                <Option value="incompletePrimary">Primaria incompleta</Option>
                <Option value="completePrimary">Primaria completa</Option>
                <Option value="Secondary">
                  Secundaria incompleta o completa
                </Option>
                <Option value="commercialTechnicalCareer">
                  Carrera comercial o técnica
                </Option>
                <Option value="highSchool">
                  Preparatoria incompleta o completa
                </Option>
                <Option value="degree">
                  Licenciatura incompleta o completa
                </Option>
                <Option value="diplomaMasterDoctorate">
                  Diplomado, maestría o doctorado
                </Option>
                <Option value="notAnswer">No sabe / no contestó</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="¿Cuál es tu estado civil?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="maritalStatus"
              className={styles.profileform__item}
            >
              <Select placeholder="selecciona una opción">
                <Option value="single">Soltero</Option>
                <Option value="married">Casado</Option>
                <Option value="freeUnion">Unión libre</Option>
                <Option value="separated">Separado</Option>
                <Option value="divorced">Divorciado</Option>
                <Option value="widower">Viudo</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="¿Tienes hijos?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="kids"
              className={styles.profileform__item}
            >
              <Select
                onChange={(value) => {
                  if (value === 'yes') setShowKids(true)
                  else setShowKids(false)
                }}
                placeholder="selecciona una opción"
              >
                <Option value="yes">Sí</Option>
                <Option value="no">No</Option>
              </Select>
            </Form.Item>
            {showKids && (
              <Form.Item
                label="¿Tienes hijos que vivan contigo?"
                rules={[
                  {
                    required: true,
                    message: 'Por favor selecciona una opción',
                  },
                ]}
                name="kidsLivingWithYou"
                className={styles.profileform__item}
              >
                <Select placeholder="selecciona una opción">
                  <Option value="yes">Sí</Option>
                  <Option value="no">No</Option>
                </Select>
              </Form.Item>
            )}
            {showKids && (
              <Form.Item
                label="¿De qué edades son los hijos que viven contigo?"
                name="ageKids"
                className={styles.profileform__item}
              >
                <Select placeholder="selecciona una opción">
                  <Option value="babies">Bebés de menos de 2 años</Option>
                  <Option value="children">Niños de entre 2 y 12 años</Option>
                  <Option value="teenagers">
                    Adolescentes de más de 12 y menos de 18 años
                  </Option>
                  <Option value="young">
                    Jóvenes de más de 18 pero menos de 25 años
                  </Option>
                  <Option value="adults">Adultos de 25 o más</Option>
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label="¿Actualmente con quien vives?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="withWhomYouLive"
              className={styles.profileform__item}
            >
              <Select placeholder="selecciona una opción">
                <Option value="alone">Solo</Option>
                <Option value="roommate">Con roomie</Option>
                <Option value="parents">Con mis padres</Option>
                <Option value="couple">Con mi pareja</Option>
                <Option value="coupleKids">Con mi pareja y mis hijos</Option>
                <Option value="coupleKidsParents">
                  Con mi pareja, mis hijos y padres
                </Option>
                <Option value="coupleKidsOther">
                  Con mi pareja, mis hijos y otros familiares
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="¿El lugar donde vives es?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="livingPlace"
              className={styles.profileform__item}
            >
              <Select placeholder="selecciona una opción">
                <Option value="rented">Rentado</Option>
                <Option value="borrowed">Prestado</Option>
                <Option value="ownHouse">Casa propia</Option>
                <Option value="other">Otro</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="¿Cuál de las siguientes frases describe mejor si eres el responsable
                    de la compra de la despensa en el hogar?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="pantry"
              className={styles.profileform__item}
            >
              <Select placeholder="selecciona una opción">
                <Option value="always">
                  Yo soy quien siempre hace la compra de la despensa / alimentos
                  en mi casa
                </Option>
                <Option value="most">
                  Yo soy quien la mayoría de las veces hace la compra de la
                  despensa / alimentos
                </Option>
                <Option value="sometimes">
                  Sólo a veces hago la compra de la despensa / alimentos para el
                  hogar
                </Option>
                <Option value="notResponsible">
                  Yo no soy el responsable de la compra de la despensa /
                  alimentos en mi casa
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="¿Cuál de las siguientes frases describe mejor que tanto decide las marcas
                    que los productos de la despensa que se compran para casa?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="pantryBrands"
              className={styles.profileform__item}
            >
              <Select placeholder="selecciona una opción">
                <Option value="decide">
                  Yo soy quien decide todas o casi todas las marcas que
                  compramos para la despensa
                </Option>
                <Option value="influence">
                  Yo no decido sobre marcas que compramos para la despensa, pero
                  sí influencio
                </Option>
                <Option value="notDecide">
                  Yo no decido ni influencio sobre marcas que se compran para la
                  despensa
                </Option>
              </Select>
            </Form.Item>
          </Panel>
          <hr className="divider-line" />
          <Panel style={{ fontSize: '22px' }} header="Acerca de ti" key="2">
            <Form.Item
              label="Pensando en el jefe de hogar, ¿Cuál fue el último año de estudios que
                    aprobó en la escuela?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="lastStudy"
              className={styles.profileform__item}
            >
              <Select placeholder="selecciona una opción">
                <Option value="notStudy">No estudió</Option>
                <Option value="preschool">Preescolar</Option>
                <Option value="incompletePrimary">Primaria incompleta</Option>
                <Option value="completePrimary">Primaria completa</Option>
                <Option value="incompleteSecondary">
                  Secundaria incompleta
                </Option>
                <Option value="completeSecondary">Secundaria completa</Option>
                <Option value="incompleteHighSchool">
                  Preparatoria incompleta
                </Option>
                <Option value="completehighSchool">
                  Preparatoria completa
                </Option>
                <Option value="incompleteDegree">
                  Licenciatura incompleta
                </Option>
                <Option value="completeDegree">Licenciatura completa</Option>
                <Option value="postgraduate">Posgrado</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="¿Cuántos baños completos con regadera y W.C. (excusado)
                    hay en su vivienda?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="bathrooms"
              className={styles.profileform__item}
            >
              <Select placeholder="selecciona una opción">
                <Option value="none">Ninguno</Option>
                <Option value="justOne">Sólo 1</Option>
                <Option value="twoMore">2 o más</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="¿Cuántos automóviles o camionetas tienen en su hogar,
                    incluyendo camionetas cerradas, o con cabina o caja?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="car"
              className={styles.profileform__item}
            >
              <Select placeholder="selecciona una opción">
                <Option value="none">Ninguno</Option>
                <Option value="justOne">Sólo 1</Option>
                <Option value="twoMore">2 o más</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Sin tomar en cuenta la conexión móvil que pudiera tener
                    desde algún celular ¿Su hogar cuenta con internet?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="internet"
              className={styles.profileform__item}
            >
              <Select placeholder="selecciona una opción">
                <Option value="no">No tiene</Option>
                <Option value="yes">Si tiene</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="De todas las personas de 14 años o más que viven en su hogar, ¿Cuántas
                    trabajaron en el último mes?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="workedLastMonth"
              className={styles.profileform__item}
            >
              <Select placeholder="selecciona una opción">
                <Option value="none">Ninguna</Option>
                <Option value="onePerson">1 persona</Option>
                <Option value="twoPeople">2 personas</Option>
                <Option value="threePeople">3 personas</Option>
                <Option value="fourPeopleMore">4 o más personas</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="En su vivienda, ¿Cuántos cuartos se usan para dormir, sin contar
                    pasillos ni baños?"
              rules={[
                { required: true, message: 'Por favor selecciona una opción' },
              ]}
              name="sleepingRooms"
              className={styles.profileform__item}
            >
              <Select placeholder="selecciona una opción">
                <Option value="none">Ninguno</Option>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="fourMore">4 o más</Option>
              </Select>
            </Form.Item>
          </Panel>
          <hr className="divider-line" />
          {/* <Panel style={{fontSize:"22px"}}  header="Pasatiempos" key="3">

                    <Form.Item label="¿Tienes cuenta con alguna de las siguientes plataformas de música?"
                        rules={[{ required: true, message: "Por favor selecciona tus opciones" }]}
                        name="musicPlatform" className={styles.profileform__item}>
                        <Select mode="multiple" placeholder="selecciona tus opciones">
                            <Option value="spotify">Spotify</Option>
                            <Option value="dezeer">Dezeer</Option>
                            <Option value="soundcloud">Soundcloud</Option>
                            <Option value="youtube">YouTube Music</Option>
                            <Option value="amazonMusic">Amazon Music</Option>
                            <Option value="appleMusic">Apple Music</Option>
                            <Option value="googlePlay">Google Play Music</Option>
                            <Option value="earbits">Earbits</Option>
                            <Option value="tidal">Tidal</Option>
                            <Option value="twitch">Twitch</Option>
                            <Option value="other">Otra</Option>
                            <Option value="none">Ninguna</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="¿Juegas videojuegos?"
                        rules={[{ required: true, message: "Por favor selecciona una opción" }]}
                        name="videoGame" className={styles.profileform__item}>
                        <Select placeholder="selecciona una opción">
                            <Option value="yes">Sí</Option>
                            <Option value="no">No</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="¿Con cuáles de los siguientes dispositivos cuentas para
                    tu uso personal?"
                        rules={[{ required: true, message: "Por favor selecciona tus opciones" }]}
                        name="personalUse" className={styles.profileform__item}>
                        <Select mode="multiple" placeholder="selecciona tus opciones">
                            <Option value="smarthphone">Teléfono celular que sea smartphone</Option>
                            <Option value="tablet">Tableta</Option>
                            <Option value="laptop">Laptop</Option>
                            <Option value="desktopComputer">Computadora de escritorio</Option>
                            <Option value="playstation">PlayStation</Option>
                            <Option value="psVita">PS Vita</Option>
                            <Option value="xboxOne">Xbox One</Option>
                            <Option value="wii">Wii</Option>
                            <Option value="other">Otro</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="¿Tienes en casa alguna de las siguientes plataformas de video?"
                        rules={[{ required: true, message: "Por favor selecciona tus opciones" }]}
                        name="videoPlatform" className={styles.profileform__item}>
                        <Select mode="multiple" placeholder="selecciona tus opciones">
                            <Option value="netflix">Netflix</Option>
                            <Option value="amazonPrime">Amazon Prime Video</Option>
                            <Option value="hbo">HBO Go</Option>
                            <Option value="fox">Fox +</Option>
                            <Option value="claro">Claro Video</Option>
                            <Option value="blim">Blim</Option>
                            <Option value="disney">Disney +</Option>
                            <Option value="cinepolis">Cinepolis Klic</Option>
                            <Option value="other">Otra</Option>
                            <Option value="none">Ninguna</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="¿Tienes cuenta con alguna de las siguientes plataformas?"
                        rules={[{ required: true, message: "Por favor selecciona tus opciones" }]}
                        name="socialNetworks" className={styles.profileform__item}>
                        <Checkbox.Group>
                          <Row>
                           <Col span={10}>
                            <Checkbox value="facebook" >Facebook</Checkbox>
                            </Col>
                            <Col span={10}>
                            <Checkbox value="instagram">Instagram</Checkbox>
                            </Col>
                            <Col span={10}>
                            <Checkbox value="twitter">Twitter</Checkbox>
                            </Col>
                            <Col span={10}>
                            <Checkbox value="tiktok">Tik Tok</Checkbox>
                            </Col>
                            <Col span={10}>
                            <Checkbox value="whatsapp">Whatsapp</Checkbox>
                            </Col>
                            <Col span={10}>
                            <Checkbox value="youtube">YouTube</Checkbox>
                            </Col>
                            <Col span={10}>
                            <Checkbox value="telegram">Telegram</Checkbox>
                            </Col>
                            <Col span={10}>
                            <Checkbox value="snapchat">Snapchat</Checkbox>
                            </Col>
                              <Col span={10}>
                                  <Checkbox value="Twitch">Twitch</Checkbox>
                              </Col>
                          </Row>
                         </Checkbox.Group>
                    </Form.Item>
                    </Panel> */}
          {/* <hr className="divider-line" /> */}
        </Collapse>

        <br />
        {fetching ? (
          <Spin style={{ margin: '0 46%' }} />
        ) : (
          <Form.Item shouldUpdate={true}>
            {() => {
              return (
                <Button
                  className={styles.profileform__button}
                  type="primary"
                  htmlType="submit"
                  style={style.button}
                >
                  ENVIAR
                </Button>
              )
            }}
          </Form.Item>
        )}

        {error && error !== '' && (
          <Alert
            // message={error}
            message="Completa todos los campos."
            type="error"
            showIcon
          />
        )}
      </Form>
    </Content>
  )
}

export default ProfileForm
