import { alignment, defaultDataType } from 'export-xlsx';

export const _header = [
  {
    name: 'Nombre',
    key: 'displayName',
    width: 38,
    checkable: true,
    style: { alignment: alignment.middleCenter },
  },
  {
    name: 'Correo electrónico',
    key: 'email',
    width: 38,
    style: { alignment: alignment.middleCenter },
  },
  {
    name: 'Puntos',
    key: 'puntos',
    width: 18,
    dataType: defaultDataType.number,
  },
  {
    name: 'Esta activo',
    key: 'active',
    width: 18,
    groupKey: 'panelists',
    dataType: defaultDataType.string,
    editable: false,
  },
  {
    name: 'Encuestas completadas',
    key: 'completed',
    width: 10,
    groupKey: 'panelists',
    dataType: defaultDataType.number,
    selfSum: true,
    editable: false,
  },
  {
    name: 'Miembro desde',
    key: 'createdAt',
    width: 25,
    dataType: defaultDataType.date,
    selfSum: true,
    //rowFormula: '{a}+{b}',
  },
  {
    name: 'Genero',
    key: 'gender',
    width: 10,
    dataType: defaultDataType.string
  },
  {
    name: 'Cumpleaños',
    key: 'birthDate',
    width: 25,
    dataType: defaultDataType.date
  },
  {
    name: 'Estado',
    key: 'state',
    width: 25,
    dataType: defaultDataType.string
  },
  // {
  //   name: 'Edad',
  //   key: 'age',
  //   width: 10,
  //   dataType: defaultDataType.number
  // },
  // {
  //   name: 'Edad de los niños',
  //   key: 'ageKids',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Baños',
  //   key: 'bathrooms',
  //   width: 25,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Cumpleaños',
  //   key: 'birthday',
  //   width: 25,
  //   dataType: defaultDataType.date
  // },
  // {
  //   name: 'Coches',
  //   key: 'car',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Ciudad',
  //   key: 'city',
  //   width: 25,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Genero',
  //   key: 'gender',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Internet',
  //   key: 'internet',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Niños',
  //   key: 'kids',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Los niños viven con el/ella',
  //   key: 'kidsLivingWithYou',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Últimos estudios',
  //   key: 'lastStudy',
  //   width: 25,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Dueño o renta',
  //   key: 'livingPlace',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Estado civil',
  //   key: 'maritalStatus',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Plataformas musicales',
  //   key: 'musicPlatform',
  //   width: 25,
  //   dataType: defaultDataType.array
  // },
  // {
  //   name: 'Colonia',
  //   key: 'neighborhood',
  //   width: 25,
  //   dataType: defaultDataType.string,
  //   groupKey: "profile"
  // },
  // {
  //   name: 'Código postal',
  //   key: 'zipCode',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Ocupación',
  //   key: 'occupation',
  //   width: 25,
  //   dataType: defaultDataType.array
  // },
  // {
  //   name: 'Haces la despensa',
  //   key: 'pantry',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Influencías en las marcas de la despensa',
  //   key: 'pantryBrands',
  //   width: 10,
  //   dataType: defaultDataType.string,
  //   groupKey: "profile"
  // },
  // {
  //   name: 'Dispositivos que usa',
  //   key: 'personalUse',
  //   width: 25,
  //   dataType: defaultDataType.array
  // },
  // {
  //   name: 'Habitaciónes',
  //   key: 'sleepingRooms',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Clase social',
  //   key: 'socialClass',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Redes sociales',
  //   key: 'socialNetworks',
  //   width: 25,
  //   dataType: defaultDataType.array
  // },
  // {
  //   name: 'Redes sociales',
  //   key: 'socialNetworks',
  //   width: 25,
  //   dataType: defaultDataType.array
  // },
  // {
  //   name: 'Estado',
  //   key: 'state',
  //   width: 25,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Grado de estudio',
  //   key: 'studies',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Videojuegos',
  //   key: 'videoGame',
  //   width: 10,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Plataformas de video',
  //   key: 'videoPlatform',
  //   width: 25,
  //   dataType: defaultDataType.array
  // },
  // {
  //   name: 'Vive con',
  //   key: 'withWhomYouLive',
  //   width: 25,
  //   dataType: defaultDataType.string
  // },
  // {
  //   name: 'Con empleo',
  //   key: 'workedLastMonth',
  //   width: 25,
  //   dataType: defaultDataType.string
  // },

]

export const formatDataForDownload = (array) => {
  return [{ data: array }]
}

// Export settings
export const SETTINGS_FOR_EXPORT = {
  // Table settings
  fileName: 'Panelistas',
  workSheets: [
    {
      sheetName: 'Lista de panelistas',
      startingRowNumber: 2,
      gapBetweenTwoTables: 2,
      tableSettings: {
        data: {
          importable: true,
          tableTitle: 'Panelistas',
          notification: 'Nota: Esta exportación incluye el perfil del panelista',
          headerGroups: [
            {
              name: 'Todos los panelistas',
              key: 'panelists',
            },
            {
              name: 'Perfil',
              key: 'profile',
            },
          ],
          headerDefinition: [
            {
              name: 'Nombre',
              key: 'displayName',
              width: 38,
              checkable: true,
              style: { alignment: alignment.middleCenter },
            },
            {
              name: 'Correo electrónico',
              key: 'email',
              width: 38,
              style: { alignment: alignment.middleCenter },
            },
            {
              name: 'Esta activo',
              key: 'active',
              width: 18,
              groupKey: 'panelists',
              dataType: defaultDataType.string,
              editable: false,
            },
            {
              name: 'Encuestas completadas',
              key: 'completed',
              width: 10,
              groupKey: 'panelists',
              dataType: defaultDataType.number,
              selfSum: true,
              editable: false,
            },
            {
              name: 'Miembro desde',
              key: 'createdAt',
              width: 25,
              dataType: defaultDataType.date,
              selfSum: true,
              //rowFormula: '{a}+{b}',
            },
            {
              name: 'Edad',
              key: 'age',
              width: 10,
              dataType: defaultDataType.number
            },
            {
              name: 'Edad de los niños',
              key: 'ageKids',
              width: 10,
              dataType: defaultDataType.string
            },
            {
              name: 'Baños',
              key: 'bathrooms',
              width: 25,
              dataType: defaultDataType.string
            },
            {
              name: 'Cumpleaños',
              key: 'birthday',
              width: 25,
              dataType: defaultDataType.date
            },
            {
              name: 'Coches',
              key: 'car',
              width: 10,
              dataType: defaultDataType.string
            },
            {
              name: 'Ciudad',
              key: 'city',
              width: 25,
              dataType: defaultDataType.string
            },
            {
              name: 'Genero',
              key: 'gender',
              width: 10,
              dataType: defaultDataType.string
            },
            {
              name: 'Internet',
              key: 'internet',
              width: 10,
              dataType: defaultDataType.string
            },
            {
              name: 'Niños',
              key: 'kids',
              width: 10,
              dataType: defaultDataType.string
            },
            {
              name: 'Los niños viven con el/ella',
              key: 'kidsLivingWithYou',
              width: 10,
              dataType: defaultDataType.string
            },
            {
              name: 'Últimos estudios',
              key: 'lastStudy',
              width: 25,
              dataType: defaultDataType.string
            },
            {
              name: 'Dueño o renta',
              key: 'livingPlace',
              width: 10,
              dataType: defaultDataType.string
            },
            {
              name: 'Estado civil',
              key: 'maritalStatus',
              width: 10,
              dataType: defaultDataType.string
            },
            {
              name: 'Plataformas musicales',
              key: 'musicPlatform',
              width: 25,
              dataType: defaultDataType.array
            },
            {
              name: 'Colonia',
              key: 'neighborhood',
              width: 25,
              dataType: defaultDataType.string,
              groupKey: "profile"
            },
            {
              name: 'Código postal',
              key: 'zipCode',
              width: 10,
              dataType: defaultDataType.string
            },
            // {
            //   name: 'Ocupación',
            //   key: 'occupation',
            //   width: 25,
            //   dataType: defaultDataType.array
            // },
            // {
            //   name: 'Haces la despensa',
            //   key: 'pantry',
            //   width: 10,
            //   dataType: defaultDataType.string
            // },
            // {
            //   name: 'Influencías en las marcas de la despensa',
            //   key: 'pantryBrands',
            //   width: 10,
            //   dataType: defaultDataType.string,
            //   groupKey: "profile"
            // },
            // // {
            // //   name: 'Dispositivos que usa',
            // //   key: 'personalUse',
            // //   width: 25,
            // //   dataType: defaultDataType.array
            // // },
            // {
            //   name: 'Habitaciónes',
            //   key: 'sleepingRooms',
            //   width: 10,
            //   dataType: defaultDataType.string
            // },
            // {
            //   name: 'Clase social',
            //   key: 'socialClass',
            //   width: 10,
            //   dataType: defaultDataType.string
            // },
            // // {
            // //   name: 'Redes sociales',
            // //   key: 'socialNetworks',
            // //   width: 25,
            // //   dataType: defaultDataType.array
            // // },
            // // {
            // //   name: 'Redes sociales',
            // //   key: 'socialNetworks',
            // //   width: 25,
            // //   dataType: defaultDataType.array
            // // },
            // {
            //   name: 'Estado',
            //   key: 'state',
            //   width: 25,
            //   dataType: defaultDataType.string
            // },
            // {
            //   name: 'Grado de estudio',
            //   key: 'studies',
            //   width: 10,
            //   dataType: defaultDataType.string
            // },
            // {
            //   name: 'Videojuegos',
            //   key: 'videoGame',
            //   width: 10,
            //   dataType: defaultDataType.string
            // },
            // // {
            // //   name: 'Plataformas de video',
            // //   key: 'videoPlatform',
            // //   width: 25,
            // //   dataType: defaultDataType.array
            // // },
            // {
            //   name: 'Vive con',
            //   key: 'withWhomYouLive',
            //   width: 25,
            //   dataType: defaultDataType.string
            // },
            // {
            //   name: 'Con empleo',
            //   key: 'workedLastMonth',
            //   width: 25,
            //   dataType: defaultDataType.string
            // },

          ],
        },
      },
    },
  ],
};
