import React, { useState, useEffect } from 'react';
import axios from 'axios'
import styles from "./Surveys.module.css";
import {
  Table,
  Pagination,
  Layout,
  Input,
  Button,
  notification,
  Modal,
  Tag,
  Select,
  Spin
} from "antd";
import userImage from "../../../assets/userImage.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { addPanelistsAction } from '../../../redux/userDuck'
import { getAllUsersAction, getGroupsAction, getNotEnrolledUsersPaginatedAction } from '../../../redux/adminDuck'
import moment from 'moment'
import Title from 'antd/lib/skeleton/Title';
import StateSelect from '../../Profile/ProfileForm/StateSelect';

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;

const columnsPanelists = [
  {
    title: "Imagen",
    dataIndex: "photoURL",
    key: "photoURL",
    render: (photo) => (
      <img
        src={photo || userImage}
        onError={(e) => {
          e.target.src = userImage;
        }}
        className={styles.panelist_image}
        alt="imagen de perfil"
      />
    ),
  },
  {
    title: "Nombre",
    key: "displayName",
    dataIndex: "displayName",
    width: 200
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "email",
  },
  {
    title: "Miembro desde",
    key: "createdAt",
    dataIndex: "createdAt",
    render: value => moment(value).fromNow()
  },
  {
    title: "Nivel",
    key: "level",
    dataIndex: "level"
  },
];


function localizeValue(value) {
  switch (value) {
    case "socialNetworks":
      return "Redes Sociales"
    case "socialClass":
      return "Calse social"
    case "gender":
      return "Género"
    case "state":
      return "Estado"
    case "age":
      return "Edad"
    case "pantry":
      return "Encargado de compras"
    case "pantryBrands":
      return "Escoge las marcas"
    case "male":
      return "Hombre"
    case "female":
      return "Mujer"
    case "always":
      return "Siempre"
    case "most":
      return "Casi siempre"
    case "sometimes":
      return "A veces"
    case "notResponsible":
      return "Nunca"
    case "decide":
      return "Siempre"
    case "influence":
      return "Opina"
    case "notDecide":
      return "Nunca"
    case 'groups':
      return 'Grupos'
    default:
      return value
  }
}

const SelectPanelists = ({ survey, onFinish }) => {
  const [filter, setFilter] = useState({})
  // new filters
  const { groups, page, pages, limit, total,
    //  items: panelists, 
    fetching, error } = useSelector(
      ({ admin }) => admin,
    )


  // .filter(u=>!u.answers.find(answer=>answer._id !== survey._id)));
  const { titles, activeTitles, titleValues, items: panelists } = useSelector(state => state.admin)
  const [filtered, setFiltered] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRecords, setSelectedrecords] = useState([]);
  const [modal, handleModal] = useState(false);
  const closeModal = () => handleModal(false);
  const [filterSection, setfilterSection] = useState(false);
  const dispatch = useDispatch()
  const [isFirsttime, setFirsttime] = useState(0)
  const [show, setShow] = useState(true)
  const [loading, setLoading] = useState(false)
  const [fetchingAll, setFetching] = useState(true)
  const { token: accessToken } = useSelector(state => state.user)
  const [searchValue, setSearchValue] = React.useState('')

  // new user filter
  const [allUsers, setAllUsers] = React.useState([])
  const formatPanelists = (users = null) => !!users.length ? users?.map(usr => ({ ...usr, key: usr._id, groups: usr.groups.map(g => g.title) })) : users

  const saveAllUsers = async () => {
    const API_URL = process.env.REACT_APP_BACKEND_URL
    const { data } = await axios.get(API_URL + '/auth/users?surveyId=' + survey._id)
    console.log("Cuantos? ", data.users?.length)
    const users = formatPanelists(data?.users)
    setAllUsers(users)
    setFetching(false)
  }

  useEffect(() => {
    // dispatch(getAllUsersAction())
    // NEED to get panelists not related to the survey yet
    dispatch(getNotEnrolledUsersPaginatedAction(null, survey._id))

    dispatch(getGroupsAction())
    // getAllUsersAndFormat()
    saveAllUsers()
  }, [])

  useEffect(() => {
    setFiltered(panelists)
  }, [panelists])

  useEffect(() => {
    filterPanelists()
  }, [filter])

  //   {
  //     "active": true,
  //     "points": 10,
  //     "role": "GUEST",
  //     "level": "beginner",
  //     "_id": "616733e2e150d70018e762c6",
  //     "displayName": "Julio garcia",
  //     "email": "the_jegg@live.com",
  //     "birthDate": "1980-11-28T00:00:00.000Z",
  //     "gender": "male",
  //     "state": "Estado de México",
  //     "username": "the_jegg@live.com",
  //     "createdAt": "2021-10-13T19:30:43.052Z",
  //     "updatedAt": "2021-10-13T19:30:43.052Z",
  //     "__v": 0,
  //     "profile": [],
  //     "answers": [],
  //     "groups": [],
  //     "id": "616733e2e150d70018e762c6",
  //     "key": "616733e2e150d70018e762c6"
  // }

  const filterPanelists = async () => {
    setLoading(true)
    const API_URL = process.env.REACT_APP_BACKEND_URL
    const fields = Object.values(filter)
    console.log("FILTROS: ", filter)
    console.log("IF: ", !!fields.length)
    if (!!fields.length) {
      axios.get(API_URL + `/admin/users/not/enrolled/${survey._id}`, {
        headers: { Authorization: accessToken },
        params: { ...filter, q: 'IS_FILTER' }
      })
        .then(({ data }) => {
          setFiltered(data.items != null ? [...data.items.map(u => ({ ...u, key: u._id }))] : [])
          setShow(false)
          setLoading(false)
        })
    } else {
      setFiltered(panelists)
      setShow(true)
      setLoading(false)
    }

  }

  const openFilterSection = () => {
    if (filterSection === false) {
      setfilterSection(true);
      setSearchValue('') // remove search field
    } else {
      setfilterSection(false);
    }
  };

  const openModal = () => {
    handleModal(true);
  };

  const getAllUsersAndFormat = async () => {
    const API_URL = process.env.REACT_APP_BACKEND_URL
    const { data } = await axios.get(API_URL + '/admin/users')
    const users = formatPanelists(data)
    setFiltered(users.items)
    return users
  }

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `keys: ${selectedRowKeys}`,
      //   "rows: ",
      //   selectedRows
      // )
      setSelectedKeys(selectedRowKeys)
      setSelectedrecords(selectedRows)
    }
    // type: "radio"
  };

  const searchUser = ({ target: { value } }) => {
    setSearchValue(value)
    setShow(false)
    // setFirsttime(isFirsttime + 1)
    if (!value || value === '') {
      setFiltered([...panelists])
      setShow(true)
    }
    if (value.length < 3) { return }
    const regEx = new RegExp(value, 'i')
    setFiltered([...allUsers.filter(pan => (regEx.test(pan.displayName) || regEx.test(pan.email)))])
    if (Object.keys(filter).length > 0) {
      setFilter({}) // reset filters
      setfilterSection(false);
    }

  }

  const changePage = (nextPage) => {
    const paginated = {
      p: nextPage,
      l: limit
    }
    // dispatch(getAllUsersAction(paginated))
    dispatch(getNotEnrolledUsersPaginatedAction(paginated, survey._id))
  }

  return (
    <Content
      style={{
        background: "#f9f9f9",
        padding: "0 3%",
      }}
    >
      <div className={styles.polls__title}>
        <span>Selección de panelistas </span>
      </div>

      <div>
        <div className={styles.polls__content__dos}>
          Selecciona los panelistas a los que quieres enviar la encuesta.

          <Button
            style={{
              width: "158px",
              height: "40px",
              borderRadius: "20px",
              fontFamily: "AvenirBook",
              fontSize: "18px",
            }}
            disabled={!selectedKeys.length}
            className={styles["surveys__send-button"]}
            type="primary"
            htmlType="submit"
            onClick={() => openModal()}
          >
            <FontAwesomeIcon
              className={styles["surveys__icon-send"]}
              icon={faPaperPlane}
            />
            Enviar{" "}
          </Button>

        </div>
        <div className={styles["polls__search-filter"]}>
          <Search
            loading={fetchingAll}
            disabled={fetchingAll}
            value={searchValue}
            placeholder="Buscar"
            style={{
              cursor: fetchingAll ? 'progress' : 'auto',
              width: "94%",
              borderRadius: "20px",
              fontFamily: "AvenirBook",
              fontSize: "18px",
              margin: "20px 0",

            }}
            allowClear
            onChange={searchUser}
          />
          <div className={styles.polls__filter} onClick={openFilterSection}>
            <FontAwesomeIcon
              className={
                filterSection || Object.keys(filter).length
                  ? styles.polls__filter_img_active
                  : styles.polls__filter_img
              }
              icon={faFilter}
            />
          </div>
        </div>
        <div className={styles.table__panelists}>
          <div style={{ width: '100%' }} >
            <Table
              rowSelection={rowSelection}
              columns={columnsPanelists}
              // dataSource={isFirsttime < 2 ? panelists : filtered}
              dataSource={filtered}
              // dataSource={filtered}
              pagination={false}
              loading={fetching || loading || fetchingAll}
              style={{ width: "100%" }}
            />
            {show && <Pagination
              showSizeChanger={false}
              pageSize={limit || 50}
              current={page}
              onChange={changePage}
              total={total}
              style={{ marginTop: 32 }} />}
          </div>
          <Modal
            visible={modal}
            onCancel={closeModal}
            width="40%"
            footer={null}
            style={{ minWidth: "290px" }}
          >
            <div className={styles.modal__title}>Enviar encuesta</div>
            <div className={styles.modal__content}>
              Confirmar el envío de la encuesta. Al hacerlo, el link de la
              encuesta será enviado por email y asignada en el sistema.
            </div>
            <div className={styles.modal__footer}>
              <button
                onClick={closeModal}
                className={styles["modal__button--cancel"]}
              >
                Cancelar
              </button>
              <button
                onClick={() => {
                  closeModal()
                  dispatch(addPanelistsAction(selectedKeys, selectedRecords, survey))
                    .then(onFinish)
                    .catch(message => {
                      notification.error({
                        message: message || 'No se pudo agregar participante, intenta más tarde',
                        description: error,
                        placement: "bottomLeft",
                      });
                    })
                }}
                className={styles["modal__button--send"]}
              >
                Enviar
              </button>
            </div>
          </Modal>

          {filterSection && (
            <div className={styles["section__filter"]}>
              <div className={styles["section__filter-title"]}>
                {" "}
                Filtrar por:{" "}
              </div>

              {activeTitles.map(title =>
                <div>
                  <p>{localizeValue(title)  /** Titulo */} </p>
                  <div style={style.wrap}>
                    {titleValues[title] && titleValues[title].map(value => {
                      return (
                        <Tag
                          style={filter[title] === value ? style.selected : style.noSelected}
                          onClick={() => {
                            if (filter[title] === value) {
                              delete filter[title]
                              return setFilter({ ...filter })
                            }
                            setFilter({ ...filter, [title]: value })
                          }}

                        >
                          {" "}
                          {localizeValue(value)}
                        </Tag>
                      )

                    })}
                    {title === 'groups' && groups.map(({ title: value }) => {
                      return (
                        <Tag
                          style={filter[title] === value ? style.selected : style.noSelected}
                          onClick={() => {
                            if (filter[title] === value) {
                              delete filter[title]
                              return setFilter({ ...filter })
                            }
                            setFilter({ ...filter, [title]: value })
                          }}

                        >
                          {" "}
                          {localizeValue(value)}
                        </Tag>
                      )
                    })}
                    {title === "state" && <StateSelect onChange={(value) => {
                      if (value === "otro") {
                        delete filter[title]
                        return setFilter({ ...filter })
                      }
                      setFilter({ ...filter, [title]: value })
                    }} />}
                    {title === "age" && <Select value={filter.age} placeholder="Rango de edad" style={{ width: "100%" }} onChange={(value) => {
                      if (!value) {
                        delete filter[title]
                        return setFilter({ ...filter })
                      }
                      setFilter({ ...filter, [title]: value })
                    }} >
                      <Option value="25">18 - 24</Option>
                      <Option value="35">25 - 34</Option>
                      <Option value="45">35 - 44</Option>
                      <Option value="100">45 +</Option>
                      <Option value={null}>Ninguna</Option>
                    </Select>}
                  </div>


                </div>
              )}
              <div className={styles["section__filter-gender__options"]}>
              </div>
            </div>
          )}
        </div>
        <br />
        <br />
        <br />

        {/* <div className={styles["surveys__button-container"]}>
          <Button
            style={{
              width: "158px",
              height: "40px",
              borderRadius: "20px",
              fontFamily: "AvenirBook",
              fontSize: "18px",
            }}
            disabled={!selectedKeys.length}
            className={styles["surveys__send-button"]}
            type="primary"
            htmlType="submit"
            onClick={() => openModal()}
          >
            <FontAwesomeIcon
              className={styles["surveys__icon-send"]}
              icon={faPaperPlane}
            />
            Enviar{" "}
          </Button>
        </div> */}
      </div>

    </Content>
  );
}

const style = {
  selected: {
    background: "#58b5ef",
    color: "white",
    marginBottom: 8,
    cursor: "pointer"
  },
  noSelected: {
    marginBottom: 8,
    cursor: "pointer"
  },
  wrap: {
    display: "flex",
    flexWrap: "wrap",
    padding: 5
  }
}

export default SelectPanelists;