import React from "react";
import { Carousel } from "antd";
import { Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import {useDispatch} from 'react-redux'

import styles from "./ResetPassword.module.css";
import slide1 from "../../assets/slider1.jpg";
import slide2 from "../../assets/slider2.jpg";
import slide3 from "../../assets/slider3.jpg";
import logo from "../../assets/logo-panel.svg";
import { resetPasswordAction } from "../../redux/userDuck";

const ResetPassword = ({history}) => {
    const dispatch = useDispatch()

    const doReset = (values) => {
        // console.log(values);
        dispatch(resetPasswordAction(values.email))
        history.push('/signin')
    };

    const style = {
        button: {
            background: "#95BF48",
            color: "#FFFFFF",
            fontSize: "18px",
            borderRadius: "4px",
            border: "none",
            height: "40px",
            maxWidth: "280px",
            width: "85%",
        },
    };

    return (
        <div className={styles.resetpass}>
            <div className={styles.slider}>
                <Carousel autoplay style={{ height: "100%" }}>
                    <div className={styles.slider__element}>
                        <img src={slide1} alt="back-1" className={styles.slider__image} />
                        <div className={styles.slider__content}>
                            <p>¡Empieza ya a ganar dinero compartiendo tus experiencias!</p>
                        </div>
                    </div>
                    <div className={styles.slider__element}>
                        <img src={slide2} alt="back-1" className={styles.slider__image} />
                        <div className={styles.slider__content}>
                            <p>Entre más encuestas completes, más premios podrás canjear.</p>
                        </div>
                    </div>
                    <div className={styles.slider__element}>
                        <img src={slide3} alt="back-1" className={styles.slider__image} />
                        <div className={styles.slider__content}>
                            <p>Invita a tus amigos y diviertanse en Collectum Datos Panel.</p>
                        </div>
                    </div>
                </Carousel>
            </div>
            {/*contentRight*/}
            <div className={styles["content-right"]}>
                <Link to="/">
                    <img
                        src={logo}
                        alt="logo"
                        className={styles["content-right__logo"]}
                    />
                </Link>
                <div className={styles["content-right__resetPassword"]}>
                    <h2 className={styles.content__Title}>¿Olvidaste tu contraseña?</h2>
                    <div className={styles.content_description}>
                        Ingresa tu email, y te enviaremos un correo electrónico para cambiar tu contraseña.
          </div>
                    <Form
                        name="resetpassword"
                        onFinish={doReset}
                        className={styles.form}
                        hideRequiredMark
                        colon={false}
                        layout="vertical"
                    >
                        <Form.Item
                            rules={[
                                { required: true, message: "Por favor ingresa tu correo" },
                                { type: "email", message: "Ingresa un correo valido" },
                            ]}
                            className={styles.form__input}
                            name="email"
                            label={<label className={styles.form__label}>Email</label>}
                        >
                            <Input className={styles["form__input-container"]} />
                        </Form.Item>
                        <Form.Item className={styles.form__buttonContainer}>
                            <Button
                                className={styles.form__button}
                                htmlType="submit"
                                style={style.button}
                            >
                                ENVIAR
              </Button>
                        </Form.Item>
                    </Form>
                    <div className={styles["count"]}>
                        ¿Ya tienes una cuenta?
            <Link to="/signin" className={styles["count-link"]}>
                            Inicia sesión
            </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
