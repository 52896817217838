import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import HomeNavbar from "../Home/HomeNavbar";

import styles from "./ProductDetails.module.css";
import product from "../../assets/product.jpg";

const ImagesHardCoded = [
  product,
  "https://i.blogs.es/718311/breadwinner_02/1366_521.jpg",
  "https://i.blogs.es/4b069b/gallery-11/450_1000.jpg",
  "https://i.blogs.es/1b696d/breadwinner_06/1366_2000.jpg",
];

const ProductDetails = () => {
  const [gImage, setGimage] = useState(0);

  const changeImage = (n) => {
    if (n === gImage) return;
    if (n < 0) setGimage(ImagesHardCoded.length - 1);
    else if (n > ImagesHardCoded.length - 1) setGimage(0);
    else setGimage(n);
  };

  return (
    <div className={styles.productDetails}>
      <HomeNavbar />
      <div className={styles["productDetails--content"]}>
        <div className={styles.alligner}>
          <div className={styles.left}>
            <div className={styles.left__gallery}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={`${styles.gallery__icon} ${styles["gallery--left"]}`}
                onClick={() => changeImage(gImage - 1)}
              />
              <FontAwesomeIcon
                icon={faChevronRight}
                className={`${styles.gallery__icon} ${styles["gallery--right"]}`}
                onClick={() => changeImage(gImage + 1)}
              />
              <img
                src={ImagesHardCoded[gImage]}
                alt=""
                className={styles.gallery__image}
              />
            </div>
            <div className={styles.gallery__thumbnails}>
              {ImagesHardCoded.map((item, index) => (
                <img
                  src={item}
                  alt=""
                  className={`${styles.thumbnail} ${
                    gImage === index && styles["thumbnail--selected"]
                  } `}
                  onClick={() => changeImage(index)}
                />
              ))}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.right__title}>Tostadora</div>
            <div className={styles.right__points}>320 PUNTOS</div>
            <div className={styles.right__description}>DESCRIPCIÓN</div>
            <div className={styles.right__content}>
              Expande tus posibilidades con este horno tostador con capacidad
              para 4 rebanadas ya que no solo hornea, tuesta, asa y recalienta,
              sino que abre las posibilidades a un sin fin de platillos
              cotidianos gracias a su tecnología de calor uniforme. Además de
              que usa hasta 80% menos energía y es 28% más rápido que un horno
              de cocina típico cuenta con un programador de tiempo de 30 minutos
              con timbre indicador de listo
            </div>
            <button className={styles.right__button}> CANJEAR</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductDetails;
