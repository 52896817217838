import axios from "axios";
import { notification } from 'antd'
const API_URL = process.env.REACT_APP_BACKEND_URL

let initialData = {
    fetching: false,
    error: null,
    surveys: [],
    externalResult: { title: 'Algo salió mal', points: 0 }
};

let REEDEM_EXTERNAL_POINTS = "REEDEM_EXTERNAL_POINTS";
let REEDEM_EXTERNAL_POINTS_SUCCESS = "REEDEM_EXTERNAL_POINTS_SUCCESS";
let REEDEM_EXTERNAL_POINTS_ERROR = "REEDEM_EXTERNAL_POINTS_ERROR";

let GET_SURVEYS = "GET_SURVEYS";
let GET_SURVEYS_SUCCESS = "GET_SURVEYS_SUCCESS";
let GET_SURVEYS_ERROR = "GET_SURVEYS_ERROR";

let DELETE_SURVEY = "DELETE_SURVEY";
let DELETE_SURVEY_SUCCESS = "DELETE_SURVEY_SUCCESS";
let DELETE_SURVEY_ERROR = "DELETE_SURVEY_ERROR";

let EDIT_SURVEY = "EDIT_SURVEY";
let EDIT_SURVEY_SUCCESS = "EDIT_SURVEY_SUCCESS";
let EDIT_SURVEY_ERROR = "EDIT_SURVEY_ERROR";

let CREATE_SURVEY = "CREATE_SURVEY";
let CREATE_SURVEY_SUCCESS = "CREATE_SURVEY_SUCCESS";
let CREATE_SURVEY_ERROR = "CREATE_SURVEY_ERROR";
export default function reducer(state = initialData, action) {
    switch (action.type) {
        case REEDEM_EXTERNAL_POINTS:
        case DELETE_SURVEY:
        case GET_SURVEYS:
            return { ...state, fetching: true, error: "" };
        case REEDEM_EXTERNAL_POINTS_ERROR:
            return { ...state, fetching: false, externalResult: action.payload }
        case DELETE_SURVEY_ERROR:
        case GET_SURVEYS_ERROR:
            return { ...state, fetching: false, ...action.payload };
        case REEDEM_EXTERNAL_POINTS_SUCCESS:
            return { ...state, fetching: false, externalResult: action.payload }
        case DELETE_SURVEY_SUCCESS:
        case GET_SURVEYS_SUCCESS:
            return { ...state, fetching: false, ...action.payload };

        case EDIT_SURVEY:
            return { ...state, fetching: true, error: "" };
        case EDIT_SURVEY_ERROR:
            return { ...state, fetching: false, ...action.payload };
        case EDIT_SURVEY_SUCCESS:
            return { ...state, fetching: false, ...action.payload };

        case CREATE_SURVEY:
            return { ...state, fetching: true, error: "" };
        case CREATE_SURVEY_ERROR:
            return { ...state, fetching: false, error: action.payload };
        case CREATE_SURVEY_SUCCESS:
            return { ...state, fetching: false, ...action.payload };
        default:
            return state;
    }
}
function saveStorage(storage) {
    localStorage.storage = JSON.stringify(storage);
}
const throwError = (e) => {
    return e && typeof e === "string"
        ? e
        : "No se pudo realizar esta acción, intente más tarde";
};

export const redeemExternalPointsAction = (surveyToken) => async (dispatch, getState) => {
    dispatch({
        type: REEDEM_EXTERNAL_POINTS,
    });
    try {
        const token = getState().user.token
        const result = await axios.post(`${API_URL}/surveys/redeem/${surveyToken}`, {}, {
            headers: {
                Authorization: token
            }
        });
        dispatch({
            type: REEDEM_EXTERNAL_POINTS_SUCCESS,
            payload: result.data
        });
        // getSurveysAction()(dispatch, getState); => cambia por traer usuario?
        return true;
    } catch (e) {
        dispatch({
            type: REEDEM_EXTERNAL_POINTS_ERROR,
            payload: e.response?.data,
        });
        return false;
    }
}

export const deleteSurveyAction = (id) => async (dispatch, getState) => {
    dispatch({
        type: DELETE_SURVEY,
    });
    try {
        const token = getState().user.token
        await axios.delete(`${API_URL}/surveys/${id}`, {
            headers: {
                Authorization: token
            }
        });
        dispatch({
            type: DELETE_SURVEY_SUCCESS,
        });
        getSurveysAction()(dispatch, getState);
        return true;
    } catch (e) {
        dispatch({
            type: DELETE_SURVEY_ERROR,
            payload: {
                error: throwError(e),
            },
        });
        return false;
    }
}

export const createExternalSurveyAction = (survey) => (dispatch, getState) => {
    dispatch({
        type: CREATE_SURVEY
    });
    const token = getState().user.token
    return axios.post(API_URL + "/surveys/external", survey, {
        headers: {
            Authorization: token
        }
    })
        .then(res => {
            dispatch({
                type: CREATE_SURVEY_SUCCESS,
            });
            getSurveysAction()(dispatch, getState);
            notification.success({
                message: `¡Encuesta activada con éxito!`,
                description: "La encuesta se ha creado",
                placement: "bottomLeft",
            })
            return res.data;
        })
        .catch(e => {
            dispatch({
                type: CREATE_SURVEY_ERROR,
                payload: e.message
            })
        })
}

export const restoreSurveysAction = () => (dispatch) => {
    let storage = localStorage.getItem("storage");
    storage = JSON.parse(storage);
    if (storage && storage.surveys) {
        dispatch({
            type: GET_SURVEYS_SUCCESS,
            payload: storage.surveys,
        });
    }
};

export const getSurveysAction = () => async (dispatch, getState) => {
    dispatch({
        type: GET_SURVEYS,
    });
    try {
        let getsurveys = await axios.get(API_URL + "/surveys/all");
        let arraySurveys = Object.keys(getsurveys.data).map((item) => {
            return {
                ...getsurveys.data[item],
                title: getsurveys.data[item].title
                    ? getsurveys.data[item].title
                    : "Sin Titulo",
                key: getsurveys.data[item].sid,
            };
        }).reverse();
        dispatch({
            type: GET_SURVEYS_SUCCESS,
            payload: {
                surveys: [...arraySurveys],
            },
        });
        saveStorage(getState());
        return true;
    } catch (e) {
        // console.log(e);
        dispatch({
            type: GET_SURVEYS_ERROR,
            payload: {
                error: throwError(e),
            },
        });
        return false;
    }
};

export const editSurveyAction = (id, form) => async (dispatch, getState) => {
    dispatch({
        type: EDIT_SURVEY,
    });
    try {
        const token = getState().user.token
        await axios.patch(`${API_URL}/surveys/${id}`, form, {
            headers: {
                Authorization: token
            }
        });
        dispatch({
            type: EDIT_SURVEY_SUCCESS,
        });
        getSurveysAction()(dispatch, getState);
        return true;
    } catch (e) {
        dispatch({
            type: EDIT_SURVEY_ERROR,
            payload: {
                error: throwError(e),
            },
        });
        return false;
    }
};

export const createSurveyAction = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_SURVEY
    });
    const token = getState().user.token
    return axios.post(API_URL + "/surveys", form, {
        headers: {
            Authorization: token
        }
    })
        .then(res => {
            dispatch({
                type: CREATE_SURVEY_SUCCESS,
            });
            getSurveysAction()(dispatch, getState);
            notification.success({
                message: `¡Encuesta activada con éxito!`,
                description: "La encuesta se ha creado",
                placement: "bottomLeft",
            })
            return res.data;
        })
        .catch(e => {
            dispatch({
                type: CREATE_SURVEY_ERROR,
                payload: e.message
            })
        })
}