import axios from 'axios'
import { notification, message } from 'antd'

const API_URL = process.env.REACT_APP_BACKEND_URL

let initialData = {
  profile: {},
  loggedIn: false,
  fetching: false,
  error: '',
  user: {
    username: '',
    role: '',
    photoURL: '',
    email: '',
    displayName: '',
    country: '',
    city: '',
    answers: null,
    rewards: [],
  },
  panelists: [],
}

let LOGIN = 'LOGIN'
let LOGIN_SUCCESS = 'LOGIN_SUCCESS'
let LOGIN_ERROR = 'LOGIN_ERROR'

let LOGOUT = 'LOGOUT'

let SIGNUP = 'SIGNUP'
let SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
let SIGNUP_ERROR = 'SIGNUP_ERROR'

let UPLOAD_PIC_PROFILE = 'UPLOAD_PROFILE'
let UPLOAD_PIC_PROFILE_SUCCESS = 'UPLOAD_PROFILE_SUCCESS'
let UPLOAD_PIC_PROFILE_ERROR = 'UPLOAD_PROFILE_ERROR'

let GET_PANELISTS = 'GET_PANELISTS'
let GET_PANELISTS_SUCCESS = 'GET_PANELISTS_SUCCESS'
let GET_PANELISTS_ERROR = 'GET_PANELISTS_ERROR'

let UPDATE_USER = 'UPDATE_USER'
let UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
let UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

const SEND_PROFILE = 'SEND_PROFILE'
const SEND_PROFILE_SUCCESS = 'SEND_PROFILE_SUCCESS'
const SEND_PROFILE_ERROR = 'SEND_PROFILE_ERROR'

const GET_SELF_DATA = 'GET_SELF_DATA'
const GET_SELF_DATA_SUCCESS = 'GET_SELF_DATA_SUCCESS'
const GET_SELF_DATA_ERROR = 'GET_SELF_DATA_ERROR'

const ADD_PARTICIPANTS = 'ADD_PARTICIPANTS'
const ADD_PARTICIPANTS_ERROR = 'ADD_PARTICIPANTS_ERROR'
const ADD_PARTICIPANTS_SUCCESS = 'ADD_PARTICIPANTS_SUCCESS'

const RESET_PASSWORD = 'RESET_PASSWORD'
const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'

const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'
const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'

export default function reducer(state = initialData, action) {
  switch (action.type) {
    case 'USER_UPDATED':
      return { ...state, user: { ...action.payload } }

    case RESET_PASSWORD:
    case UPDATE_PASSWORD:
      return { ...state, fetching: true }

    case UPDATE_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case ADD_PARTICIPANTS:
      return { ...state, fetching: false }
    case ADD_PARTICIPANTS_SUCCESS:
      return { ...state, fetching: false }

    case UPDATE_PASSWORD_ERROR:
    case RESET_PASSWORD_ERROR:
    case ADD_PARTICIPANTS_ERROR:
      return { ...state, fetching: false, error: action.payload }

    case SEND_PROFILE:
      return { ...state, fetching: true }
    case SEND_PROFILE_SUCCESS:
      return { ...state, fetching: false, user: action.payload }
    case SEND_PROFILE_ERROR:
      return { ...state, fetching: false, error: action.payload }

    case GET_SELF_DATA:
      return { ...state, fetching: true }
    case GET_SELF_DATA_SUCCESS:
      return { ...state, fetching: false, user: action.payload }
    case GET_SELF_DATA_ERROR:
      return { ...state, fetching: false, error: action.payload }

    case LOGIN:
      return { ...state, fetching: true, error: '' }
    case LOGIN_ERROR:
      return { ...state, fetching: false, ...action.payload }
    case LOGIN_SUCCESS:
      return { ...state, fetching: false, ...action.payload, loggedIn: true }

    case SIGNUP:
      return { ...state, fetching: true, error: '' }
    case SIGNUP_ERROR:
      return { ...state, fetching: false, ...action.payload }
    case SIGNUP_SUCCESS:
      return { ...state, fetching: false, ...action.payload, loggedIn: true }
    case LOGOUT:
      return { ...initialData }
    case UPLOAD_PIC_PROFILE:
      return { ...state, fetching: true, error: '' }
    case UPLOAD_PIC_PROFILE_ERROR:
      return { ...state, fetching: false, ...action.payload }
    case UPLOAD_PIC_PROFILE_SUCCESS:
      return {
        ...state,
        fetching: false,
        user: { ...state.user, ...action.payload },
      }
    case GET_PANELISTS:
      return {
        ...state,
        fetching: true,
        error: '',
      }
    case GET_PANELISTS_ERROR:
      return {
        ...state,
        fetching: false,
        ...action.payload,
      }
    case GET_PANELISTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        ...action.payload,
      }
    case UPDATE_USER:
      return { ...state, fetching: true, error: '' }
    case UPDATE_USER_ERROR:
      return { ...state, fetching: false, ...action.payload }
    case UPDATE_USER_SUCCESS:
      return { ...state, fetching: false, ...action.payload }
    default:
      return state
  }
}
function saveStorage(storage) {
  localStorage.storage = JSON.stringify(storage)
}

const throwError = (e) => {
  return e && typeof e === 'string'
    ? e
    : 'No se pudo realizar esta acción, intente más tarde'
}

// ADMIN STUFF

export const addPanelistsAction = (selectedKeys, selectedRecords, survey) => (
  dispatch,
  getState,
) => {
  const body = selectedRecords.reduce(
    (acc, record) => {
      acc.users.push({ email: record.email })
      return acc
    },
    { users: [] },
  )
  dispatch({ type: ADD_PARTICIPANTS, payload: body })
  // return
  const token = getState().user.token
  return axios
    .post(API_URL + `/surveys/${survey._id}`, body, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({ type: ADD_PARTICIPANTS_SUCCESS, payload: res.data })
      saveStorage(getState())
      notification.success({
        message: `¡Encuesta eviada con éxito!`,
        description: 'La encuesta se ha enviado correctamente',
        placement: 'bottomLeft',
      })
      return true
    })
    .catch((e) => {
      dispatch({
        type: ADD_PARTICIPANTS_ERROR,
        payload: e.response?.data?.message,
      })
      return Promise.reject(e.response?.data?.message)
    })
}

// Settings STUFF

export const updatePasswordAction = (newPassword) => (dispatch, getState) => {
  const token = getState().user.token
  dispatch({ type: UPDATE_PASSWORD, payload: token })
  return axios
    .post(
      API_URL + `/auth/update`,
      { newPassword },
      {
        headers: {
          Authorization: token,
        },
      },
    )
    .then((res) => {
      dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: res.data })
      notification.success({
        message: 'Password actualizado con éxito',
        description: res.data.message,
      })
      return true
    })
    .catch((e) => {
      dispatch({ type: UPDATE_PASSWORD_ERROR, payload: e?.message })
      return false
    })
}

export const resetPasswordAction = (email) => (dispatch, getState) => {
  const token = getState().user.token
  dispatch({ type: GET_SELF_DATA, payload: token })
  return axios
    .post(
      API_URL + `/auth/recovery`,
      { email },
      {
        headers: {
          Authorization: token,
        },
      },
    )
    .then((res) => {
      dispatch({ type: GET_SELF_DATA_SUCCESS, payload: res.data })
      notification.success({
        message: 'Recuperación de password',
        description: res.data.message,
      })
      return true
    })
    .catch((e) => {
      dispatch({ type: GET_SELF_DATA_ERROR, payload: e?.message })
      return false
    })
}

export const getSelfDataAction = (profile) => (dispatch, getState) => {
  const token = getState().user.token
  dispatch({ type: GET_SELF_DATA, payload: token })
  return axios
    .get(API_URL + `/auth/self`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({ type: GET_SELF_DATA_SUCCESS, payload: res.data })
      saveStorage(getState())
      return true
    })
    .catch((e) => {
      dispatch({ type: GET_SELF_DATA_ERROR, payload: e.message })
      return false
    })
}

export const sendProfileAction = (profile) => (dispatch, getState) => {
  dispatch({ type: SEND_PROFILE })
  localStorage.profile = JSON.stringify(profile)
  const token = getState().user.token
  return axios
    .post(API_URL + `/auth/signup/profile`, profile, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({ type: SEND_PROFILE_SUCCESS, payload: res.data })
      return true
    })
    .catch((e) => {
      dispatch({ type: SEND_PROFILE_ERROR, payload: e.response.statusText })
      return false
    })
}

export const restoreSessionAction = () => (dispatch) => {
  let storage = localStorage.getItem('storage')
  storage = JSON.parse(storage)
  if (storage && storage.user && storage.user.loggedIn) {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: storage.user,
    })
  }
}

export const signUpUserAction = (form) => async (dispatch, getState) => {
  dispatch({
    type: SIGNUP,
  })
  try {
    let signup = await axios.post(API_URL + '/auth/signup', form)
    dispatch({
      type: SIGNUP_SUCCESS,
      payload: { ...signup.data },
    })
    saveStorage(getState())
    restoreSessionAction()(dispatch)
    return true
  } catch (e) {
    dispatch({
      type: SIGNUP_ERROR,
      payload: {
        error: throwError(e.response?.data?.message),
      },
    })
    return false
  }
}

export const loginUserAction = (form) => async (dispatch, getState) => {
  dispatch({
    type: LOGIN,
  })
  try {
    let login = await axios.post(API_URL + '/auth/login', form)
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { ...login.data },
    })
    saveStorage(getState())
    return login.data
  } catch (e) {
    dispatch({
      type: LOGIN_ERROR,
      payload: {
        error: throwError(e),
      },
    })
    return false
  }
}

export const logOutUserAction = () => (dispatch, getState) => {
  dispatch({
    type: LOGOUT,
  })
  saveStorage(getState())
}

export const uploadPicProfileAction = (image) => async (dispatch, getState) => {
  dispatch({
    type: UPLOAD_PIC_PROFILE,
  })
  try {
    let token = getState().user.token
    var bodyFormData = new FormData()
    bodyFormData.append('photo', image)
    let uploadPicProfile = await axios.patch(
      API_URL + '/auth/self',
      bodyFormData,
      {
        headers: {
          Authorization: token,
        },
      },
    )
    dispatch({
      type: UPLOAD_PIC_PROFILE_SUCCESS,
      payload: uploadPicProfile.data,
    })
    window.location.reload()
    message.success('Imagen de perfil actualizada')
    saveStorage(getState())
    return true
  } catch (e) {
    dispatch({
      type: UPLOAD_PIC_PROFILE_ERROR,
      payload: {
        error: throwError(e),
      },
    })
    message.error('No se pudo actualizar la imagen')
    return false
  }
}

export const getPanelistsAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_PANELISTS,
  })
  try {
    let getpanelists = await axios.get(API_URL + '/auth/users')
    dispatch({
      type: GET_PANELISTS_SUCCESS,
      payload: {
        panelists: [...getpanelists.data.users],
      },
    })
    saveStorage(getState())
    return true
  } catch (e) {
    dispatch({
      type: GET_PANELISTS_ERROR,
      payload: {
        error: throwError(e),
      },
    })
    return false
  }
}

export const updateUserAction = (form) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_USER,
  })
  let token = getState().user.token
  try {
    let updateuser = await axios.patch(API_URL + '/auth/self', form, {
      headers: { Authorization: token },
    })
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: {
        user: {
          ...updateuser.data,
        },
      },
    })
    return true
  } catch (e) {
    dispatch({
      type: UPDATE_USER_ERROR,
      payload: {
        error: throwError(e),
      },
    })
    return false
  }
}
