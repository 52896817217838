import React, { useState } from "react";
import { Layout, Tabs } from "antd";

import { useSelector } from "react-redux";

import styles from "./Rewards.module.css";
import GiftsBar from "../../Gifts/GiftsBar";
import RewardModal from "./RewardModal";
import Disclaimer from "../../Gifts/Disclaimer";

const { TabPane } = Tabs;
const { Content } = Layout;
const Rewards = () => {
  const rewards = useSelector(
    (state) => state.rewards.list
  );
  const { points = 0 } = useSelector(state => state.user.user);
  const [isVisible, setVisible] = useState(false);
  const [reward, setReward] = useState({ items: [] });

  const redeemed = []

  const doRedeemReward = async () => { };

  const rewardSelected = (reward) => {
    setVisible(true)
    setReward(reward)
  };

  return (
    <Content
      style={{
        background: "#f9f9f9",
        padding: "0 3%",
      }}
    >
      <div className={styles.rewards__title}>
        Premios
      </div>
      <div className={styles.rewards__content}>
        Escoge entre los siguientes premios y canjea los que más te gusten
        utilizando los puntos que has ganando contestando cada una de las
        encuestas.
      </div>
      <Tabs
        defaultActiveKey="1"
        style={{
          fontFamily: "'ABeeZee',sans-serif",
          fontSize: "18px",
          color: "#323232",
        }}
        size={"large"}
      >
        <TabPane tab="Catálogo" key="1">
          <GiftsBar onSelect={rewardSelected} />
        </TabPane>

      </Tabs>
      <RewardModal userPoints={points} reward={reward} onClose={() => setVisible(false)} isVisible={isVisible} />
      <Disclaimer width="80%" />
    </Content>
  );
};
export default Rewards;
